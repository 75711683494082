import {
  Button,
  HideAboveSmallScreen,
  InputGroup,
  Link,
  Loader,
  MaxWidthContainer,
  Message,
  MessageType,
} from 'components'
import {PermissionTierToDisplayName, PostSignupAsk} from 'app/enums'
import {partialPlaceholder as _, compose, isEmpty, partial} from 'util/common'
import {
  getDonationFormUrl,
  getEventImageAltText,
  getEventTypeAffinityChecks,
  hasRequiredCustomField,
  isGroupEvent,
} from 'util/event'
import {
  getMaybePrioritizedTimeslot,
  getReferrerBannerInfo,
} from './ReferrerBanner'
import {getOrganizationEventUrl, getReportEventUrl} from 'util/routing'
import {hasFecDisclaimer, orgFlagIsActive} from 'util/organization'
import styles, {mediaMinWidthSmall} from 'components/styles'

import {ChatSnippetInitializationMode} from 'vendor/intercom/chatSnippetManager'
import ContactHostModal from './ContactHostModal'
import Details from './Details'
import DetailsActions from './DetailsActions'
import DetailsHeader from './DetailsHeader'
import DetailsImage from './DetailsImage'
import DetailsRelated from './DetailsRelated'
import {F} from 'util/i18n'
import {FecDisclaimer} from 'events/components'
import FundraisingForm from './FundraisingForm'
import MembershipConfirmationModal from 'events/modals/MembershipConfirmationModal'
import SignupForm from './SignupForm'
import api from 'data/api'
import {connect} from 'react-redux'
import {createMailUrl} from 'util/url'
import {css} from '@emotion/react'
import {filterOutAtCapacityTimeslots} from 'util/timeslot'
import {maybeStringToMaybeInt} from 'util/string'
import {shouldPrecheckTwoTimeslots} from './SignupForm/util'
import styled from '@emotion/styled/macro'
import {useState} from 'react'
import withOrgAnalytics from 'analytics/withOrgAnalytics'
import withScreenData from 'app/withScreenData'

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`

const EventHeaderContainer = styled.header`
  ${mediaMinWidthSmall(`border-top: 1px solid ${styles.colors.neutral300};`)}
  padding-top: ${styles.space.m};
`

const EventHeaderInnerContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.isCollapsed &&
    css`
      flex-direction: column;
      align-items: flex-start;
      ${mediaMinWidthSmall(`
        flex-direction: row;
        align-items: center;
      `)}
    `}
`

const CollapsedWrapper = styled.div`
  white-space: nowrap;

  ${mediaMinWidthSmall(`
    padding-left: ${styles.space.m};
  `)}
`

const EventBodyWrapper = styled.div`
  display: flex;
  padding-bottom: ${styles.space.xl};
  flex-direction: column;
  ${mediaMinWidthSmall('flex-direction: row;')}

  ${(props) => props.isCollapsed && 'justify-content: flex-end;'}
`

const SignupFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMinWidthSmall(css`
    width: 40%;
    min-width: 40%;
    margin-left: ${styles.space.l};
  `)}
`

const FecWrapper = styled.div`
  margin-top: ${styles.space.l};
  ${mediaMinWidthSmall(css`
    margin-top: auto;
  `)}
`

// TODO(mime): don't use font-size directly here, use Typography when
// this diff is ready: https://github.com/mobilizeamerica/mobilize/pull/5434
const MiscLinksWrapper = styled.div`
  font-size: ${styles.typography.fontSizeS};
  margin: ${styles.space.m} 0;
  text-align: center;
`

function DetailsScreen({
  currentVolunteer,
  data: {current_organization: organization, event, user},
  error,
  initialQueryParams,
  isLoading,
  trackingParams,
  trackSignupForOrg,
  location,
}) {
  const maybePrioritizedTimeslot = getMaybePrioritizedTimeslot(
    initialQueryParams,
    event
  )
  const isGroupInvite =
    initialQueryParams.is_group_invite?.toLowerCase() === 'true'
  const groupIsFull = initialQueryParams.group_is_full?.toLowerCase() === 'true'
  if (
    isGroupInvite &&
    !groupIsFull &&
    maybePrioritizedTimeslot?.remaining_spots != null
  ) {
    maybePrioritizedTimeslot.remaining_spots += 1
  }
  const isPast = !(event.times && event.times.length)
  const allFull =
    event.times && !filterOutAtCapacityTimeslots(event.times).length
  const filterParams = location?.state?.filterParams
  const eventSuggestionContext = location?.state?.eventSuggestionContext
  const [participationShortlink, setParticipationShortlink] = useState(null)
  const [isCollapsed, setIsCollapsed] = useState(isPast || allFull)

  const [shareParams, setShareParams] = useState(null)
  const [owningGroups, setOwningGroups] = useState([])

  const {hasFundraiserForm} = getEventTypeAffinityChecks(event)
  const donationFormUrl = hasFundraiserForm ? getDonationFormUrl(event) : null

  const [isContactHostModalOpen, setIsContactHostModalOpen] = useState(false)
  const contactHostEnabled =
    event.contact_host_enabled &&
    orgFlagIsActive(event.organization, 'enable_contact_host_button')

  function onSignupCompleted(signupRequest, signupResponse) {
    if (!signupResponse.user_is_blocked) {
      trackSignupForOrg(signupRequest)
    }
    setShareParams({
      rname: signupRequest.firstName,
      referring_vol: signupResponse.user_id,
      timeslot: signupRequest.shifts[0].timeslot_id,
    })
    // Store the per-participation share shortlink so we can surface it in the page-level share modal
    if (signupResponse.share_shortlink) {
      setParticipationShortlink(signupResponse.share_shortlink)
    }
    setOwningGroups(signupResponse?.owning_groups || [])
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader large />
      </LoaderWrapper>
    )
  }
  if (error || !event) {
    return (
      <MaxWidthContainer>
        <Message type={MessageType.ERROR}>
          <strong>Error fetching event.</strong> {error && error.message}
        </Message>
      </MaxWidthContainer>
    )
  }

  const contactEmail = event.organization.reply_to_email || ''
  const isHostedEvent = event.created_by_distributed_organizing
  const reportEventUrl = isHostedEvent
    ? getReportEventUrl(event).toString()
    : undefined
  const mailtoLink =
    contactEmail &&
    createMailUrl(
      contactEmail,
      `Question about “${event.name}”`,
      `Hello ${event.organization.name},\n` +
        `I have a question about…\n\n\n` +
        `📅 ${event.name}\n` +
        getOrganizationEventUrl(event.organization, event).toString()
    )
  function handleContactClick() {
    window.open(mailtoLink, '_blank', 'noopener noreferrer')
  }

  return (
    <>
      <MembershipConfirmationModal
        organization={organization}
        trackingParams={trackingParams}
      />

      {/* Modal to contact volunteer host if the event was created using distributed organizing. */}
      {contactHostEnabled && isContactHostModalOpen && (
        <ContactHostModal
          isOpen={isContactHostModalOpen}
          onRequestClose={() => {
            setIsContactHostModalOpen(false)
          }}
          onSubmit={(data) => {
            api.sendContactHostRequestBody(event.id, data)
          }}
          eventName={event.name}
        />
      )}

      <article>
        {!isCollapsed && (
          <HideAboveSmallScreen>
            <DetailsImage
              eventType={event.event_type}
              url={event.image_url}
              imageAltText={getEventImageAltText(event)}
            />
          </HideAboveSmallScreen>
        )}
        <EventHeaderContainer>
          <MaxWidthContainer>
            <EventHeaderInnerContainer isCollapsed={isCollapsed}>
              <DetailsHeader
                event={event}
                initialQueryParams={initialQueryParams}
              />
              {isCollapsed && (
                <CollapsedWrapper>
                  <InputGroup stackOnMobile={true}>
                    {contactEmail && (
                      <Button secondary fluid onClick={handleContactClick}>
                        <F defaultMessage="Contact organization" />
                      </Button>
                    )}
                    <Button
                      secondary
                      onClick={() => setIsCollapsed(false)}
                      fluid
                    >
                      <F defaultMessage="See details" />
                    </Button>
                  </InputGroup>
                </CollapsedWrapper>
              )}
            </EventHeaderInnerContainer>
          </MaxWidthContainer>
        </EventHeaderContainer>
        <MaxWidthContainer>
          <EventBodyWrapper isCollapsed={isCollapsed}>
            {!isCollapsed && (
              <>
                <Details event={event} organization={organization} />
                <SignupFormWrapper hasImage={!!event.image_url}>
                  {donationFormUrl ? (
                    <FundraisingForm
                      event={event}
                      organization={organization}
                      trackingParams={trackingParams}
                      formUrl={donationFormUrl}
                      onSuccess={onSignupCompleted}
                      participationShortlink={participationShortlink}
                      shareParamsFromSignup={shareParams}
                      initialQueryParams={initialQueryParams}
                      filterParams={filterParams}
                      eventSuggestionContext={eventSuggestionContext}
                      maybePrioritizedTimeslot={maybePrioritizedTimeslot}
                      owningGroups={owningGroups}
                    />
                  ) : (
                    <SignupForm
                      event={event}
                      initialQueryParams={initialQueryParams}
                      maybePrioritizedTimeslot={maybePrioritizedTimeslot}
                      onSuccess={onSignupCompleted}
                      organization={organization}
                      participationShortlink={participationShortlink}
                      shareParamsFromSignup={shareParams}
                      trackingParams={trackingParams}
                      user={user}
                      filterParams={filterParams}
                      eventSuggestionContext={eventSuggestionContext}
                      owningGroups={owningGroups}
                    />
                  )}
                  <DetailsActions
                    currentVolunteer={currentVolunteer}
                    event={event}
                    initialQueryParams={initialQueryParams}
                    organization={organization}
                    shareParamsFromSignup={shareParams}
                    user={user}
                  />
                  <MiscLinksWrapper>
                    {isHostedEvent && contactHostEnabled ? (
                      <Button
                        link
                        onClick={() => setIsContactHostModalOpen(true)}
                        padding="none"
                      >
                        Contact organizer
                      </Button>
                    ) : (
                      <>
                        {contactEmail && (
                          <Link to={mailtoLink} target="_blank">
                            Contact organization
                          </Link>
                        )}
                      </>
                    )}

                    {reportEventUrl && (
                      <>
                        {contactEmail && ' · '}
                        <Link to={reportEventUrl} target="_blank">
                          {`Report this ${
                            isGroupEvent(event) ? 'group' : 'event'
                          }`}
                        </Link>
                      </>
                    )}
                  </MiscLinksWrapper>
                  {hasFecDisclaimer(event.organization) && (
                    <FecWrapper>
                      <FecDisclaimer organization={event.organization} />
                    </FecWrapper>
                  )}
                </SignupFormWrapper>
              </>
            )}
          </EventBodyWrapper>
        </MaxWidthContainer>
      </article>
      {event.post_signup_asks.includes(PostSignupAsk.EVENT_SUGGESTIONS) && (
        <DetailsRelated event={event} organization={organization} />
      )}
    </>
  )
}

// Exported to ease testing 😬
export const additionalTrackPageLoadedProps = (props) => {
  const {data, currentVolunteer, initialQueryParams} = props

  const {current_organization: currentOrganization, event} = data
  const {
    organization,
    id,
    event_type,
    created_by_distributed_organizing,
    creator_permission_tier: permissionTier,
    custom_signup_fields,
  } = event

  return {
    owning_org_slug: organization.slug,
    owning_org_name: organization.name,
    owning_org_id: organization.id,
    event_id: id,
    event_type,
    referring_vol: maybeStringToMaybeInt(initialQueryParams.referring_vol),
    referrer_banner_variant_shown: getReferrerBannerInfo(
      initialQueryParams,
      event
    ).variant,
    prechecked_two_timeslots: shouldPrecheckTwoTimeslots(
      getMaybePrioritizedTimeslot(initialQueryParams, event),
      currentOrganization,
      event
    ),
    referrer_share_context: initialQueryParams.share_context,
    referrer_share_medium: initialQueryParams.share_medium,
    created_by_distributed_organizing,
    event_host_type:
      permissionTier && PermissionTierToDisplayName[permissionTier],
    prefilled_form:
      (currentVolunteer && !isEmpty(currentVolunteer)) || !!data.user,
    custom_signup_fields,
    custom_signup_field_is_required: hasRequiredCustomField(event),
  }
}

const mapStateToProps = (state) => {
  return {
    currentVolunteer: state.currentVolunteer,
  }
}

const screenDataOpts = {
  cacheResponse: true,
  additionalTrackPageLoadedProps,
  analyticsPageNameOverride: 'signup',
  chatSnippetInitializationMode:
    ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_ONLY,
}

export default compose(
  // `additionalTrackPageLoadedProps` (in withScreenData) needs access to `currentVolunteer`, which
  // is supplied by the Redux `connect` HoC, so reorder with care
  connect(mapStateToProps),
  partial(withScreenData, _, screenDataOpts),
  withOrgAnalytics
)(DetailsScreen)

import {Input, InputGroup} from 'components'

export default function UserSignupFormFields({
  errorFields,
  identityFields,
  onChange,
  emailLessSubmission,
}) {
  const handleChange = (evt) => {
    const {name, value} = evt.target
    onChange({name, value})
  }

  return (
    <>
      <InputGroup>
        <Input
          name="firstName"
          type="text"
          label="First name"
          required
          value={identityFields.firstName}
          onChange={handleChange}
          error={errorFields.first_name}
        />
        <Input
          name="lastName"
          type="text"
          label="Last name"
          required
          value={identityFields.lastName}
          onChange={handleChange}
          error={errorFields.last_name}
        />
      </InputGroup>
      <Input
        name="email"
        type="email"
        label={emailLessSubmission ? 'Email (optional)' : 'Email'}
        fluid
        required={!emailLessSubmission}
        value={identityFields.email}
        onChange={handleChange}
        error={!!errorFields.email}
      />
      <InputGroup>
        <Input
          name="phone"
          type="tel"
          label={emailLessSubmission ? 'Phone (optional)' : 'Mobile number'}
          required={!emailLessSubmission}
          value={identityFields.phone}
          onChange={handleChange}
          error={!!errorFields.phone}
        />
        <Input
          isZIPCode
          name="zip"
          label={emailLessSubmission ? 'ZIP (optional)' : 'ZIP code'}
          required={!emailLessSubmission}
          value={identityFields.zip}
          onChange={handleChange}
          error={!!errorFields.zipcode}
        />
      </InputGroup>
    </>
  )
}

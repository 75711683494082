import {Button, Icon, InputGroup, Link, Typography} from 'components'
import {
  EventType,
  VIRTUAL_FLEXIBLE_EVENT_TYPES_TO_SHOW_ONE_TAP_SIGNUP,
} from 'app/enums'
import {F, defineMessages, useIntl} from 'util/i18n'
import {
  filterOutAtCapacityTimeslots,
  formatEventTimesOneLine,
} from 'util/timeslot'
import {getCityState, getLocationCopyForVirtualEvents} from 'util/geo'
import {getEventTypeAffinityChecks, isRegistrationOnlyEvent} from 'util/event'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import CurrentUserContext from 'app/CurrentUserContext'
import FeedItem from './FeedItem'
import {PromoteEventButton} from 'events/components'
import SignupButton from './SignupButton'
import {filterFalsy} from 'util/common'
import {getOrganizationEventUrl} from 'util/routing'
import {hasRequiredCustomField} from 'util/event'
import {isEveryIdentityFieldFilledOut} from 'util/user'
import {maybeGetEventSubheading} from 'util/event'
import {maybeGetPlaceholderImage} from 'util/event'
import {orgFlagIsActive} from 'util/organization'
import {useContext} from 'react'
import useIdentityFields from 'hooks/identity-fields'

function isEventEligibleForOneTapSignup(event) {
  // Events are eligible for one-tap signup if they:
  return (
    // Have no required custom fields, and
    !hasRequiredCustomField(event) &&
    // are either shifted, or
    (!event.is_virtual_flexible ||
      // are in a subset of virtual flexible event types where one-tap signup makes sense
      VIRTUAL_FLEXIBLE_EVENT_TYPES_TO_SHOW_ONE_TAP_SIGNUP.has(event.event_type))
  )
}

const messages = defineMessages({
  defaultButtonText: {defaultMessage: 'See details'},
  defaultButtonWithAdditionalTimes: {
    defaultMessage: 'See details & more times',
  },
  // N.B.: formattedDateTime isn't i18ned yet, need to do that or figure out some better story for
  // i18ning datetimes more generally since the way we cobble them together currently is highly
  // English-specific
  ariaLabelNoAddress: {
    defaultMessage: 'See details for "{eventName}". {formattedDateTime}',
  },
  ariaLabelWithAddress: {
    defaultMessage:
      'See details for "{eventName}". {formattedDateTime}. {address}',
  },
  donateToRsvpText: {defaultMessage: 'Donate to sign up'},
  donateToRsvpOptionalText: {defaultMessage: 'Donations encouraged'},
  promoteEventText: {defaultMessage: 'Promote'},
})

export default function EventFeedItem({
  event,
  eventSuggestionContext,
  filterParams,
  signupSource,
  isCompactFeedItem,
  hideButtons,
  isPromotableEvent,
  promotionContext,
  promotingOrg,
}) {
  const intl = useIntl()
  const signupIdentityFields = useIdentityFields()

  const showOneTapSignup =
    isEveryIdentityFieldFilledOut(signupIdentityFields) &&
    isEventEligibleForOneTapSignup(event) &&
    !hideButtons

  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const {user} = useContext(CurrentUserContext)
  const {placeholderUrl, placeholderSrcSet} = maybeGetPlaceholderImage(
    event.event_type
  )
  const imageUrl = event.image_url || placeholderUrl
  const srcSet = event.image_url || placeholderSrcSet

  // feed items showing an event for promotion shouldn't use the current org because
  // the event is not yet promoted and doesn't exist for that org yet
  const eventOrganization =
    isPromotableEvent || !currentOrganization
      ? event.organization
      : currentOrganization

  const displayTime = formatEventTimesOneLine(event, false)
  const firstAvailableISO =
    filterOutAtCapacityTimeslots(event.times)[0]?.start || ''

  const url = getOrganizationEventUrl(eventOrganization, event)

  const linkState = {filterParams, eventSuggestionContext}

  const cityState = getCityState(event).trim()

  const virtualCopy = getLocationCopyForVirtualEvents(event)

  const physicalLocation = event.is_statewide
    ? `In person · ${virtualCopy}`
    : cityState

  // if there is no event or if the event is registration only, don't display anything
  const maybeLocation =
    !event || isRegistrationOnlyEvent(event)
      ? null
      : event.is_virtual
      ? `Virtual · ${virtualCopy}`
      : physicalLocation

  const ariaLabel = intl.formatMessage(
    maybeLocation ? messages.ariaLabelWithAddress : messages.ariaLabelNoAddress,
    {
      eventName: event.name,
      address: maybeLocation,
      formattedDateTime: displayTime,
    }
  )

  const {isDonateToRsvp, isDonateToRsvpOptional} = getEventTypeAffinityChecks(
    event
  )

  const donateToRsvpCopy = isDonateToRsvp
    ? intl.formatMessage(messages.donateToRsvpText)
    : isDonateToRsvpOptional
    ? intl.formatMessage(messages.donateToRsvpOptionalText)
    : ''

  const availableTimes = filterOutAtCapacityTimeslots(event.times)

  const additionalTimesCopy =
    !isRegistrationOnlyEvent(event) &&
    !event.is_virtual_flexible &&
    availableTimes &&
    availableTimes.length > 1

  return (
    <FeedItem
      aria-label={ariaLabel}
      linkTo={url}
      linkState={linkState}
      // If the buttons are linkified, then we have to do lots of unrelated preventDefaulting from
      // all of the components rendered within it, which stinks (e.g., the ChainingModalStepper's skip
      // button ends up navigating to the event detail page). We'll explicitly render a link button
      // in the actionContent if we're in the one tap signup test next to the button that signs you
      // up for an event.
      dontLinkButtons={showOneTapSignup || isPromotableEvent}
      headline={event.name}
      subhead={maybeGetEventSubheading(
        currentOrganization?.slug,
        event,
        orgFlagIsActive(eventOrganization, 'enable_per_event_co_ownership')
      )}
      details={filterFalsy([maybeLocation])}
      time={displayTime}
      firstAvailableISO={firstAvailableISO}
      // Sending a falsy buttonText makes the default button not render
      // buttonText={`${intl.formatMessage(messages.promoteEventText)}`}
      buttonText={
        isPromotableEvent || showOneTapSignup || hideButtons
          ? undefined
          : `${intl.formatMessage(
              additionalTimesCopy
                ? messages.defaultButtonWithAdditionalTimes
                : messages.defaultButtonText
            )}`
      }
      actionContent={
        isPromotableEvent
          ? () => (
              <InputGroup stackOnMobile stackAlways={isCompactFeedItem}>
                <PromoteEventButton
                  event={event}
                  user={user}
                  icon=""
                  compact
                  secondary
                  promotingOrg={promotingOrg}
                  promotionContext={promotionContext}
                />
              </InputGroup>
            )
          : showOneTapSignup
          ? () => (
              <InputGroup stackOnMobile stackAlways={isCompactFeedItem}>
                <SignupButton
                  event={event}
                  eventSuggestionContext={eventSuggestionContext}
                  organization={eventOrganization}
                  signupIdentityFields={signupIdentityFields}
                  signupSource={signupSource}
                />
                {
                  /** Take out of the tab order because it's a redundant link */
                  <Link to={url} state={linkState} plain tabIndex="-1">
                    <Button secondary icon="arrow-right" tabIndex="-1">
                      {additionalTimesCopy ? (
                        <F defaultMessage={'See details & more times'} />
                      ) : (
                        <F defaultMessage={'See details'} />
                      )}
                    </Button>
                  </Link>
                }
              </InputGroup>
            )
          : undefined
      }
      imageUrl={imageUrl}
      imageSrcSet={srcSet}
      imageTag={
        isCompactFeedItem &&
        event?.is_virtual &&
        event?.event_type !== EventType.GROUP ? (
          <F defaultMessage="Online event" />
        ) : undefined
      }
      event={event}
      isCompactFeedItem={isCompactFeedItem}
      linkTarget={hideButtons ? '_blank' : undefined}
      secondaryDetails={
        donateToRsvpCopy ? (
          <Typography
            variant="body2"
            component="div"
            data-testid="donationMessage"
          >
            <Icon name="hand-holding-heart" aria-label="hand holding heart" />
            {donateToRsvpCopy}
          </Typography>
        ) : undefined
      }
    />
  )
}

import {Button, CardModal, Input, Typography} from 'components'

import {F} from 'util/i18n'
import {maybeStringToMaybeInt} from 'util/string'
import {useState} from 'react'

export default function EditGroupSizeModal({
  volSchedule,
  onRequestClose,
  onSubmit,
  groupSizeSignUpLimit,
  numAdditionalGroupSpots,
  numUnclaimedGroupSpots,
  groupLeaderFirstName,
  groupLeaderLastName,
}: Props) {
  const reservedGroupSpots = numAdditionalGroupSpots
    ? numAdditionalGroupSpots + 1
    : 1
  const unclaimedGroupSpots = numUnclaimedGroupSpots || 0
  const registeredGroupCount = reservedGroupSpots - unclaimedGroupSpots
  const [groupCount, setGroupCount] = useState(reservedGroupSpots)

  const groupCountLessThanError = groupCount
    ? groupCount < registeredGroupCount
    : true
  const groupCountExceedsMaxGroupSize =
    groupSizeSignUpLimit && groupCount
      ? groupSizeSignUpLimit < groupCount
      : false
  const disabled = groupCountLessThanError || groupCountExceedsMaxGroupSize

  const onGroupCountChange = (value) => {
    value ? setGroupCount(value) : setGroupCount(value)
    // if (value) {
    //   setGroupCount(value)
    // }
  }

  return (
    <CardModal isOpen onRequestClose={onRequestClose}>
      <Typography variant="h2">
        {volSchedule ? (
          <F defaultMessage="Edit group size" />
        ) : (
          <F
            defaultMessage="Edit {groupLeaderFirstName} {groupLeaderLastName}'s group"
            values={{
              groupLeaderFirstName: groupLeaderFirstName,
              groupLeaderLastName: groupLeaderLastName,
            }}
          />
        )}
      </Typography>
      <Typography variant="body1">
        {volSchedule ? (
          unclaimedGroupSpots > 0 ? (
            <F
              defaultMessage="Your group has {reservedMembers} total members. {claimedSpots} of them {claimedSpots, plural, one {has} other {have}} signed up on Mobilize, and {unclaimedGroupSpots} of them {unclaimedGroupSpots, plural, one {has} other {have}} yet to sign up."
              values={{
                reservedMembers: reservedGroupSpots,
                claimedSpots: registeredGroupCount,
                unclaimedGroupSpots: unclaimedGroupSpots,
              }}
            />
          ) : (
            <F
              defaultMessage="Your group has {reservedMembers} total members. All of them have signed up on Mobilize."
              values={{reservedMembers: reservedGroupSpots}}
            />
          )
        ) : unclaimedGroupSpots > 0 ? (
          <F
            defaultMessage="{groupLeaderFirstName}'s group has {reservedMembers} members. {claimedSpots, plural, one {# has} other {# have}} signed up on Mobilize, and {unclaimedGroupSpots} of them {unclaimedGroupSpots, plural, one {has} other {have}} yet to sign up."
            values={{
              groupLeaderFirstName: groupLeaderFirstName,
              reservedMembers: reservedGroupSpots,
              claimedSpots: registeredGroupCount,
              unclaimedGroupSpots: unclaimedGroupSpots,
            }}
          />
        ) : (
          <F
            defaultMessage="{groupLeaderFirstName}'s group has {reservedMembers} members. All of them have signed up on Mobilize."
            values={{
              groupLeaderFirstName: groupLeaderFirstName,
              reservedMembers: reservedGroupSpots,
            }}
          />
        )}
      </Typography>
      <Input
        name="groupSizeSelector"
        type="number"
        label={<F defaultMessage="Group size*" />}
        value={groupCount}
        onChange={(e) => {
          onGroupCountChange(maybeStringToMaybeInt(e.target.value))
        }}
        hint={
          groupCountLessThanError ? (
            <F defaultMessage="Group size cannot be lower than the number of members who have signed up." />
          ) : groupCountExceedsMaxGroupSize ? (
            <F
              defaultMessage="Maximum group size is {groupSizeSignupLimit}"
              values={{groupSizeSignupLimit: groupSizeSignUpLimit}}
            />
          ) : (
            <F defaultMessage="Total number of group members, including the group leader" />
          )
        }
        min={1}
        id="outlined-error-helper-text"
        error={groupCountLessThanError || groupCountExceedsMaxGroupSize}
      />

      <CardModal.ActionButtons>
        <Button
          type="button"
          secondary
          onClick={onRequestClose}
          data-testid="cancel-button"
        >
          <F defaultMessage="Cancel" />
        </Button>

        <Button
          type="button"
          disabled={disabled}
          onClick={() => onSubmit(groupCount)}
          data-testid="update-button"
        >
          {volSchedule ? (
            <F defaultMessage="Save" />
          ) : (
            <F defaultMessage="Update group" />
          )}
        </Button>
      </CardModal.ActionButtons>
    </CardModal>
  )
}

import {DatePicker, InputGroup} from 'components'
import TimePicker, {TIME_FORMAT} from 'components/TimePicker'

import {getDayForTimeslot} from 'util/timeslot'
import moment from 'vendor/moment'
import {omit} from 'util/common'

// NB: we accept nullable shifts here so that we can preserve the row.

const getTimeslotIdByDay = (timeslots) =>
  timeslots.reduce((acc, timeslot) => {
    const isoDay = getDayForTimeslot(timeslot).toISOString()
    acc[isoDay] = timeslot.id
    return acc
  }, {})

const getTimeslotsById = (timeslots) =>
  timeslots.reduce((acc, timeslot) => ({...acc, [timeslot.id]: timeslot}), {})

// the minimum time available is either the first timeslot in the chosen day, or if the day is
// today, the next upcoming half hour (i.e., can't pick times in the past)
const getMinTime = (shift, timeslotsById) => {
  if (!shift) return null

  const earliestTime = moment(timeslotsById[`${shift.timeslot_id}`].start)
  const now = moment()

  const roundedUpNow = now.clone().set(
    now.minute() >= 30 // Round to the next half hour
      ? {hour: now.hour() + 1, minute: 0, second: 0}
      : {minute: 30, second: 0}
  )

  return moment.max(earliestTime, roundedUpNow).toDate()
}

// NB: we accept nullable shifts here so that we can preserve the row.
export default function VirtualEventTimeslotPicker({
  shift,
  timeslots,
  onChange,
}) {
  // TODO(jared) consider memoizing these, probably with a useMemo hook
  const timeslotIdByDay = getTimeslotIdByDay(timeslots)
  const timeslotsById = getTimeslotsById(timeslots)

  const timeslotDate = shift
    ? getDayForTimeslot(timeslotsById[`${shift.timeslot_id}`]).toISOString()
    : null
  const startTime = shift && shift.start ? moment(shift.start) : null

  const minTime = shift ? getMinTime(shift, timeslotsById) : null
  const maxTime = shift
    ? moment(timeslotsById[`${shift.timeslot_id}`].end)
        .subtract(30, 'minutes')
        .toDate()
    : null

  const lastTimeslot = timeslots[timeslots.length - 1]
  const maxDate = moment(lastTimeslot.end, moment.ISO_8601)

  const onDateChange = (isoDate) => {
    onChange(
      isoDate && timeslotIdByDay[isoDate]
        ? {timeslot_id: timeslotIdByDay[isoDate]}
        : null
    )
  }

  const onTimeChange = (time) => {
    // Shouldn't happen b/c the time input is disabled until a day is picked; check to appease Flow
    if (!shift) return

    if (!time) {
      // User deleted the time, so send through a shift without a `start`
      onChange(omit(shift, 'start'))
      return
    }

    const parsedTime = moment(time, TIME_FORMAT)
    const thisTimeslot = timeslotsById[`${shift.timeslot_id}`]
    const firstStartTime = moment(thisTimeslot.start, moment.ISO_8601)
    const lastStartTime = moment(thisTimeslot.end, moment.ISO_8601).subtract(
      30,
      'minutes'
    )

    // This is the user's desired start time, which has to be within firstStartTime/lastStartTime
    const desiredStart = firstStartTime.clone().set({
      hour: parsedTime.hour(),
      minute: parsedTime.minute(),
    })

    // `'[]'` means this is an inclusive range: https://momentjs.com/docs/#/query/is-between/
    if (desiredStart.isBetween(firstStartTime, lastStartTime, null, '[]')) {
      onChange({...shift, start: desiredStart.toISOString()})
    } else {
      // User entered something invalid, so clear out the time. We could probably do something more
      // advanced here (highlighting the field or something)
      onChange(omit(shift, 'start'))
    }
  }

  const today = moment()
  const tomorrow = moment().add(1, 'day')
  const todayTimeslotId =
    timeslotIdByDay[today.clone().startOf('day').toISOString()]
  const dontAllowToday =
    !!todayTimeslotId &&
    // Don't allow selecting today if there is less than 30 minutes left in the timeslot,
    // because there won't be any times in the timepicker anyway
    moment(timeslotsById[`${todayTimeslotId}`].end)
      .subtract(30, 'minutes')
      .isBefore(moment())
  const minDate = dontAllowToday ? tomorrow : today

  return (
    <InputGroup>
      <DatePicker
        value={timeslotDate || ''}
        label="Date"
        minDate={minDate}
        maxDate={maxDate}
        disablePast={true}
        onChange={onDateChange}
        shouldDisableDate={(day) => !timeslotIdByDay[day.toISOString()]}
      />
      <TimePicker
        disabled={!timeslotDate}
        required={!!timeslotDate}
        label="Time"
        value={startTime || ''}
        onChange={onTimeChange}
        minTime={minTime ? minTime : undefined}
        maxTime={maxTime ? maxTime : undefined}
      />
    </InputGroup>
  )
}

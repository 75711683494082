import {Button, CardModal, Link, Loader, Typography} from 'components'
import {isFbShareUrl, isTweetUrl, trackShareInitiated} from 'util/share'

import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useState} from 'react'

const ContentWrapper = styled.div`
  text-align: center;
`
const LoaderContainer = styled.div`
  padding: ${styles.space.m};
  margin-bottom: ${styles.space.m};
`

const ContinuePromptContainer = styled.div`
  margin-top: ${styles.space.l};
`

const VirtualEventRedirectModal = ({
  isOpen,
  onClose,
  onContinue,
  event,
  url,
}) => {
  const [preparingRedirect, setPreparingRedirect] = useState(true)
  const maybeFullUrl = event.virtual_action_url
    ? new URL(event.virtual_action_url)
    : null
  const isTwitterRedirect = !!maybeFullUrl && isTweetUrl(maybeFullUrl)
  const isFbRedirect = !!maybeFullUrl && isFbShareUrl(maybeFullUrl)
  const redirectTitle = `Sending you to ${
    isTwitterRedirect ? 'Twitter' : isFbRedirect ? 'Facebook' : event.name
  }`

  const startRedirect = (url) => {
    setTimeout(() => {
      window.location.href = url
      setPreparingRedirect(false)
    }, 3000)
  }

  const onAfterOpen = () => {
    if (isTwitterRedirect || isFbRedirect) {
      // social share actions are redirected higher up the chain in `submitSignupForm`
      // so that they can open in a new tab without being blocked by the browser
      setPreparingRedirect(false)
      const medium = isFbRedirect ? 'facebook' : 'twitter'
      trackShareInitiated(medium, {share_context: 'online_action'})
    } else {
      startRedirect(url)
    }
  }

  return (
    <CardModal
      padding="extra"
      onRequestClose={onClose}
      onAfterOpen={onAfterOpen}
      isOpen={isOpen}
      closeText="Done"
    >
      <ContentWrapper>
        <Typography variant="h1">
          {preparingRedirect ? redirectTitle : 'Thank you for your support'}
        </Typography>
        {!preparingRedirect && (
          <ContinuePromptContainer>
            <Typography variant={'body1'}>
              Finished sharing your post? We've got more ways for you to boost
              your impact.
            </Typography>
          </ContinuePromptContainer>
        )}
        <LoaderContainer>
          {preparingRedirect ? (
            <Loader />
          ) : (
            <Button fluid onClick={onContinue}>
              Continue
            </Button>
          )}
        </LoaderContainer>
        <Typography variant="subtitle1" component="p">
          If you aren’t redirected within three seconds,{' '}
          <Link to={url} target="_blank">
            click&nbsp;here
          </Link>
          .
        </Typography>
      </ContentWrapper>
    </CardModal>
  )
}

export default VirtualEventRedirectModal

import {Button, Message, TextArea, Typography} from 'components'

import {useState} from 'react'

function ContentReportComponent(props) {
  const [notes, setNotes] = useState(null)
  const [showReportForm, setShowReportForm] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  const submitContentReport = async () => {
    try {
      await props.onSubmit({notes})
      setShowReportForm(false)
    } catch (error) {
      setErrorMessage(
        error.message || 'There was an error submitting your report'
      )
    }
  }

  const {current_organization: org} = props.data
  const {reportedEntityDisplayName} = props
  const orgName = org.name
  return (
    <>
      <Typography variant="h1">
        Report {reportedEntityDisplayName} as inappropriate
      </Typography>
      {showReportForm ? (
        <>
          <Typography variant="body1">
            Submit a report for {orgName} to review.
          </Typography>
          <TextArea
            name="notes"
            label="Additional details (optional)"
            maxWidth="500px"
            value={notes || ''}
            onChange={(e) => setNotes(e.target.value)}
            placeholder={`Tell us what’s wrong with this ${reportedEntityDisplayName}`}
          />
          <Button name="submit" onClick={submitContentReport}>
            Submit
          </Button>
          {errorMessage && <Message type="error">{errorMessage}</Message>}
        </>
      ) : (
        <Typography variant="body1">
          Thanks for flagging the {reportedEntityDisplayName} as inappropriate.
          We’ve sent this along to {orgName} to review.
        </Typography>
      )}
    </>
  )
}

export default ContentReportComponent

import {CheckboxField, Link} from 'components'
import {compose, filterFalsy} from 'util/common'

import {Container} from './util'
import cv from 'util/clientVars'
import {toDisplayList} from 'util/string'

export default function SMSOptIn({event, isChecked, onChange, organization}) {
  const {custom_signup_form_sms_opt_in_text} = organization.branding

  let coOwningOrgNames = []
  if (event.manually_added_co_owning_orgs) {
    const coOwningOrgs = event.manually_added_co_owning_orgs.filter(
      (o) => o.id !== organization.id
    )
    if (coOwningOrgs && coOwningOrgs.length < 3) {
      coOwningOrgNames = coOwningOrgs.map((o) => o.name)
    } else if (coOwningOrgs) {
      coOwningOrgNames = [
        coOwningOrgs[0].name,
        coOwningOrgs[1].name,
        'partnering organizations',
      ]
    }
  }

  const senders = compose(
    toDisplayList,
    filterFalsy
  )([
    // If the event owner is Mobilize, then it's redundant w/ 'Mobilize' at the end
    !event.organization.is_mobilize ? event.organization.name : null,
    // If the current org is the event owner, then it's redundant with the above line
    event.organization.id !== organization.id &&
    // If the current org is Mobilize, then it's redundant w/ 'Mobilize' at the end
    !organization.is_mobilize
      ? organization.name
      : null,
    cv.product_name,
    ...coOwningOrgNames,
  ])

  const smsOptInLabel = custom_signup_form_sms_opt_in_text ? (
    <div
      dangerouslySetInnerHTML={{__html: custom_signup_form_sms_opt_in_text}}
    />
  ) : (
    <>
      I want to receive occasional automated text messages from {senders} with
      updates about how to stay involved, such as recommended events and other
      actions. Message and data rates may apply. Message frequency varies. Text
      STOP to cancel or HELP for help. By opting in you agree to our{' '}
      <Link to={cv.tos_url} popup>
        SMS Shortcode Terms of Service
      </Link>
      .
    </>
  )

  return (
    <Container>
      <CheckboxField
        name="smsOptIn"
        onChange={onChange}
        checked={isChecked}
        small
        label={smsOptInLabel}
      />
    </Container>
  )
}

import {Button, Icon, Link, Typography} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import {createMailUrl} from 'util/url'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {trackShareInitiated} from 'util/share'
import {useSnackbar} from 'notistack'
import {useState} from 'react'

const messages = defineMessages({
  mailtoSubject: {
    defaultMessage: 'Join my group at {eventName}',
  },
  mailToBody: {
    defaultMessage:
      'Hi there,\n\nYou’re invited to join my group at {eventName}.\n\nUse this link to sign up: {groupShareLink}',
  },
})

const ContentWrapper = styled.div`
  text-align: center;
  margin-bottom: ${styles.space.xl};
`

const HeadingWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

const ButtonWrapper = styled.div`
  margin-bottom: ${styles.space.s};
`

export default function GroupInvite({
  groupShareLink,
  event,
  isPostSignup,
  modalType,
}) {
  const intl = useIntl()
  const snackbar = useSnackbar()
  const [copied, setCopied] = useState(false)

  const mailtoSubject = intl.formatMessage(messages.mailtoSubject, {
    eventName: event.name,
  })
  const mailtoBody = intl.formatMessage(messages.mailToBody, {
    eventName: event.name,
    groupShareLink: groupShareLink,
  })
  const mailtoUrl = createMailUrl(
    '' /* empty 'to' field */,
    mailtoSubject,
    mailtoBody
  )

  function handleCopy() {
    snackbar.enqueueSnackbar('Copied!', {variant: 'success'})
    setCopied(true)
    trackShareInitiated('group_share_link', {
      share_context: modalType,
    })
  }

  return (
    <ContentWrapper>
      <HeadingWrapper>
        {!isPostSignup && (
          <Icon
            primary
            name="users"
            noMargin
            fontSize={styles.typography.mediumIcon}
          />
        )}
        <Typography center variant="h1">
          {isPostSignup ? (
            <F defaultMessage="You’re signed up! Share your group signup link with everyone in your group." />
          ) : (
            <F defaultMessage="Share your group signup link with everyone in your group." />
          )}
        </Typography>
        {isPostSignup && (
          <Typography center variant="body1">
            <F defaultMessage="We’ve emailed you instructions for how to join this event." />
          </Typography>
        )}
      </HeadingWrapper>
      <ButtonWrapper>
        <Link
          target="_blank"
          to={mailtoUrl}
          onClick={partial(trackShareInitiated, 'group_share_link_mailto')}
        >
          <Button icon="envelope" fluid iconPosition="left-inline" secondary>
            <F defaultMessage="Send via email" />
          </Button>
        </Link>
      </ButtonWrapper>
      <ButtonWrapper>
        <CopyToClipboard text={groupShareLink} onCopy={handleCopy}>
          <Button icon="link" fluid iconPosition="left-inline">
            {copied ? (
              <F defaultMessage="Copied" />
            ) : (
              <F defaultMessage="Copy invite link" />
            )}
          </Button>
        </CopyToClipboard>
      </ButtonWrapper>
    </ContentWrapper>
  )
}

import {Button, Map} from 'components'
import {debounce, logError} from 'util/common'
import {defineMessages, useIntl} from 'util/i18n'
import {filterParamsToQueryParams, getFilterCount} from 'util/feed'
import {getOrganizationFeedMapUrl, getOrganizationFeedUrl} from 'util/routing'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import FeedMapClusterInfo from 'events/feed/FeedMapClusterInfo'
import FeedMapItem from 'events/feed/FeedMapItem'
import FilterPanelForm from 'events/feed/FilterPanelForm'
import api from 'data/api'
import {isValidLatLon} from 'util/geo'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useAsync} from 'hooks/async'
// $FlowFixMe: types need updating for latest react-router
import {useHistory} from 'react-router'

const messages = defineMessages({
  moreFiltersDescription: {
    defaultMessage: 'More filters ({filterCount} selected)',
  },
})

const MapWrapper = styled.div`
  background-color: ${styles.colors.neutral100};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const MapContainerWrapper = styled.div`
  height: calc(100vh - ${(props) => props.top || 0}px);
`

const FilterPanelWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: ${styles.space.m};
  padding-bottom: ${styles.space.l};

  /* Setting a min-width here so that when toggling Date/Date range it
     doesn't jump around. */
  min-width: 316px;
`

const USA_MAP_POSITION = {
  latitude: 37.0902,
  longitude: -95.7129,
  zoom: 2.5,
}

export default function FeedMap({
  organization,
  filterParams,
  zoom,
  filterTags,
  orgTagIds,
  initialEventMarkerData,
}) {
  const intl = useIntl()
  const history = useHistory()
  const [mapPosition, setMapPosition] = useState(USA_MAP_POSITION)
  const [showFilterForm, setShowFilterForm] = useState(false)
  const [eventMarkerResponse, setEventMarkerResponse] = useState(
    initialEventMarkerData
  )

  // we want the map to fill the remaining screen height after accounting for
  // anything rendered above the FeedMap component
  const topRef = useRef(null)
  const [top, setTop] = useState(null)
  useEffect(() => {
    if (!top && topRef.current) {
      setTop(topRef.current.offsetTop)
    }
  }, [top])

  const fetchMarkers = useCallback(
    async (searchCenter = null) => {
      try {
        const response = await api.getMarkersForFeedMap(organization.slug, {
          ...filterParamsToQueryParams(filterParams),
          ...(searchCenter || {}),
        })
        setEventMarkerResponse(response)
      } catch (e) {
        logError(e)
      }
    },
    [organization.slug, filterParams]
  )

  const debouncedFetchMarkers = useMemo(
    () =>
      debounce((searchCenter) => {
        fetchMarkers(searchCenter)
      }, 300),
    [fetchMarkers]
  )

  const {result: shownOrgs} = useAsync(async () => {
    return api.getOrgsForFiltering(organization.slug)
  }, [organization.slug])

  const {result: eventTypeOptions} = useAsync(async () => {
    return await api.getEventTypes(organization.slug)
  }, [organization.slug])

  const submitUpdatedFilterParams = (params) => {
    const {pathname, search} = getOrganizationFeedMapUrl(
      organization,
      {
        ...params,
        lat: mapPosition.latitude,
        lon: mapPosition.longitude,
      },
      {zoom: mapPosition.zoom}
    )
    history.push({pathname, search})
  }

  const shouldRefreshMarkers =
    eventMarkerResponse &&
    eventMarkerResponse.total >= eventMarkerResponse.results_limited_to

  const {lat, lon} = filterParams
  const positionFromParams = {
    latitude: lat,
    longitude: lon,
    zoom: zoom,
  }
  const shouldUsePositionParams =
    typeof zoom === 'number' && isValidLatLon({lat, lon})
  // zoom out to US view initially or to the given location
  const initialPosition = shouldUsePositionParams
    ? // $FlowFixMe: flow doesn't know we checked these are numbers
      positionFromParams
    : USA_MAP_POSITION

  const filterCount = getFilterCount(filterParams)
  const moreFiltersDescription = intl.formatMessage(
    messages.moreFiltersDescription,
    {filterCount}
  )
  // useMemo to avoid re-rendering the button with every map render
  const filterPanelButton = useMemo(
    () => (
      <Button
        onClick={() => setShowFilterForm(true)}
        secondary
        icon="sliders-h"
        iconPosition="left"
        selected={filterCount > 0}
        aria-label={moreFiltersDescription}
      >
        {filterCount > 0 && filterCount}
      </Button>
    ),
    [filterCount, moreFiltersDescription]
  )

  return (
    <MapWrapper ref={topRef}>
      {showFilterForm && (
        <FilterPanelWrapper>
          <FilterPanelForm
            filterParams={filterParams}
            shownOrgs={shownOrgs}
            eventTypeOptions={eventTypeOptions}
            filterTags={filterTags}
            orgTagIds={orgTagIds}
            shouldShowTagsFilter
            onSubmit={submitUpdatedFilterParams}
            onCancel={() => setShowFilterForm(false)}
          />
        </FilterPanelWrapper>
      )}
      <MapContainerWrapper top={top}>
        <Map
          {...initialPosition}
          markers={eventMarkerResponse?.event_markers}
          height="100%"
          width="100%"
          renderEventPopup={({event_id}) =>
            event_id && (
              <FeedMapItem organization={organization} eventId={event_id} />
            )
          }
          renderClusterPopup={(clusterMarker) => (
            <FeedMapClusterInfo
              clusterMarker={clusterMarker}
              organization={organization}
              filterParams={filterParams}
            />
          )}
          showCloseButton
          onRequestClose={(position) => {
            const locationFilterParams = position
              ? {lat: position.latitude, lon: position.longitude}
              : {}
            const {pathname, search} = getOrganizationFeedUrl(
              organization,
              {
                ...filterParams,
                ...locationFilterParams,
              },
              {from_map: true, zoom: position.zoom}
            )
            history.push({pathname, search})
          }}
          onPositionChange={(position) => {
            setMapPosition(position)
            if (shouldRefreshMarkers) {
              debouncedFetchMarkers({
                lat: position.latitude,
                lon: position.longitude,
              })
            }
          }}
          shouldInitiallyFitBounds={!shouldUsePositionParams}
          renderExtraControls={() => filterPanelButton}
        />
      </MapContainerWrapper>
    </MapWrapper>
  )
}

import {formatQueryString, getQueryParams} from 'util/url'
// $FlowFixMe (mime): types need updating for latest react-router
import {useHistory, useLocation} from 'react-router'

import PageAnalytics from './PageAnalytics'
import {USER_ID_DIFFERS_PARAM} from './constants'
import {configureChatSnippetForPage} from 'vendor/intercom/chatSnippetManager'
import {omit} from 'util/common'
import {useEffect} from 'react'

const GLOBAL_QUERY_PARAMS_TO_REMOVE = [USER_ID_DIFFERS_PARAM]

export default function Page({
  analyticsPageName,
  children,
  screenOptions,
  wrappedProps,
}) {
  useQueryCleanup({screenOptions})
  useConfigureChat({screenOptions, wrappedProps})

  return (
    <>
      <PageAnalytics
        analyticsPageName={analyticsPageName}
        screenOptions={screenOptions}
        wrappedProps={wrappedProps}
      />
      {children}
    </>
  )
}

function useQueryCleanup({screenOptions}) {
  const history = useHistory()
  const {hash, search} = useLocation()

  useEffect(() => {
    const queryParamsToRemove = [
      ...(screenOptions.queryParamsToRemove || []),
      ...GLOBAL_QUERY_PARAMS_TO_REMOVE,
    ]
    const queryParams = getQueryParams(search)
    if (queryParamsToRemove.some((param) => queryParams[param])) {
      const newQueryString = formatQueryString(
        omit(queryParams, queryParamsToRemove)
      )
      history.replace({
        search: newQueryString,
        // don't clobber a hash if it exists
        hash: hash,
      })
    }
  }, [hash, history, screenOptions, search])
}

function useConfigureChat({screenOptions, wrappedProps}) {
  useEffect(() => {
    configureChatSnippetForPage(
      wrappedProps.data,
      screenOptions.chatSnippetInitializationMode
    )
  }, [screenOptions, wrappedProps])
}

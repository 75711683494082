import {
  getShareLink,
  getShareLinks,
  maybeGetUserFirstName,
  trackShareInitiated,
} from 'util/share'

import {Button} from 'components'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {PromoteEventButton} from 'events/components'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useSnackbar} from 'notistack'

const ButtonsWrapper = styled.div({
  paddingTop: styles.space.m,
  // $FlowFixMe(mime): this component selector is magic to Flow
  [Button]: {
    marginBottom: styles.space.s,
  },
})

const COMMON_BUTTON_PROPS = {
  fluid: true,
  secondary: true,
  iconPosition: 'left',
  iconWidth: '16px',
}

export default function DetailsActions({
  currentVolunteer,
  event,
  initialQueryParams,
  organization,
  shareParamsFromSignup: shareParamsPartial,
  user,
}) {
  const snackbar = useSnackbar()

  const maybeUserFirstName = maybeGetUserFirstName(currentVolunteer, user)

  function handleCopy() {
    snackbar.enqueueSnackbar('Copied!', {variant: 'success'})
    trackShareInitiated('copy_link', {share_context: 'event_details'})
  }

  function handleOpenLink(url, medium) {
    // We *don't* do 'noopener noreferrer' for FB because it needs to close
    // the window after it's done with the share.
    window.open(
      url,
      '_blank',
      medium !== 'facebook' ? 'noopener noreferrer' : undefined
    )
    trackShareInitiated(medium, {share_context: 'event_details'})
  }

  const shareLink = getShareLink({
    currentVolunteer,
    event,
    initialQueryParams,
    organization,
    shareParamsPartial,
    user,
  })
  const {facebookUrl, twitterUrl, mailtoUrl, copyUrl} = getShareLinks({
    event,
    shareContext: 'event_details',
    shareLink,
    userFirstName: maybeUserFirstName,
    hashtag: organization.hashtag,
  })

  return (
    <ButtonsWrapper>
      <PromoteEventButton {...COMMON_BUTTON_PROPS} event={event} user={user} />
      <Button
        {...COMMON_BUTTON_PROPS}
        icon="facebook-f"
        onClick={() => handleOpenLink(facebookUrl, 'facebook')}
      >
        Share on Facebook
      </Button>
      <Button
        {...COMMON_BUTTON_PROPS}
        icon="twitter"
        onClick={() => handleOpenLink(twitterUrl, 'twitter')}
      >
        Share on Twitter
      </Button>
      <Button
        {...COMMON_BUTTON_PROPS}
        icon="envelope"
        onClick={() => handleOpenLink(mailtoUrl, 'mailto')}
      >
        Share via email
      </Button>
      <CopyToClipboard text={copyUrl} onCopy={handleCopy}>
        <Button {...COMMON_BUTTON_PROPS} icon="link">
          Copy invite link
        </Button>
      </CopyToClipboard>
    </ButtonsWrapper>
  )
}

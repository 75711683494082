import {F, defineMessages, useIntl} from 'util/i18n'
import {Link, Typography} from 'components'

import cv from 'util/clientVars'
import {logError} from 'util/common'
import {remapImageUrlToProxy} from 'util/image'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useEffect} from 'react'

const messages = defineMessages({
  imageAlt: {
    defaultMessage: '{product_name} logo',
  },
})

const ErrorWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: ${styles.limits.maxWidth};
  margin: 0 auto;
  padding: ${styles.space.l};
`

const ErrorImg = styled.img`
  width: 200px;
  height: auto;
`

const ErrorDetails = styled.pre`
  max-width: 50vw;
  white-space: normal;
  font-size: 12px;
  padding: ${styles.space.xs};
  margin: ${styles.space.xl};
  border: 1px inset ${styles.colors.neutral300};
  background-color: ${styles.colors.neutral100};
`

export default function PageError({error}) {
  useEffect(() => {
    logError(error instanceof Error ? error : `Page load error ${error}`)
  }, [error])

  const errorDetailsTxt =
    error instanceof Error ? error.toString() : `Error details: ${error}`
  const logoUrl = remapImageUrlToProxy(cv.logo_url)
  const intl = useIntl()
  const imageAlt = intl.formatMessage(messages.imageAlt, {
    product_name: cv.product_name,
  })

  return (
    <ErrorWrapper>
      <ErrorImg src={logoUrl} title="logo" alt={imageAlt} />
      <Typography variant="h1" style={{margin: styles.space.l}}>
        <F defaultMessage="Something went wrong" />
      </Typography>
      <br />
      <Typography variant="body1">
        <F defaultMessage="We’re working on a fix, so refresh your browser and try again." />
        <br />
        <F
          defaultMessage="Contact <Link>support@mobilize.us</Link> if this issue persists."
          values={{
            Link: (msg) => (
              <Link to="mailto:support@mobilize.us?subject=Help%20with%20site%20error">
                {msg}
              </Link>
            ),
          }}
        />
      </Typography>
      <br />
      <Typography variant="body1">
        <F defaultMessage="If you’re trying to join an event or view event details, check your email for your signup confirmation message." />
      </Typography>
      <br />
      <Typography variant="body1">
        <Link to="https://mobilize.statuspage.io/">
          <F defaultMessage="Mobilize Status" />
        </Link>{' '}
        &bull;{' '}
        <Link to="https://help.mobilize.us/">
          <F defaultMessage="Help Center" />
        </Link>
      </Typography>
      <br />
      <ErrorDetails>{errorDetailsTxt}</ErrorDetails>
    </ErrorWrapper>
  )
}

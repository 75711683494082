import {Autocomplete, Button, Icon, Link, Typography} from 'components'
import {
  getShareLinks,
  nativeShareEvent,
  trackShareInitiated as trackShareInitiatedUtil,
} from 'util/share'
import styles, {mediaMinWidthSmall} from 'components/styles'
import {useContext, useEffect, useRef, useState} from 'react'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {EventChainingAction} from 'app/enums'
import {F} from 'util/i18n'
import analytics from 'analytics'
import androidShareImage from 'images/android-share.svg'
import {getEventTypeAffinityChecks} from 'util/event'
import iosShareImage from 'images/ios-share.svg'
import {isIOS} from 'util/platform'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import {useExperimentAllocation} from 'hooks/experiments'

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-top: 1px solid ${styles.colors.neutral300};
    margin-top: ${styles.space.l};
    margin-bottom: ${styles.space.l};
  }

  &:not(:empty)::before {
    margin-right: ${styles.space.m};
  }

  &:not(:empty)::after {
    margin-left: ${styles.space.m};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  // Kind of unfortunate, but since the parent is a column flex container, we need this to take up
  // the available vertical space
  flex-grow: 1;
  ${mediaMinWidthSmall('min-height: 450px;')}
`

const ShareOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > {
    ${Button},
    ${Link} {
      margin-bottom: ${styles.space.m};
    }
    ${Link} {
      display: block;
    }
  }
`

// Used when we display text below the header on this modal
const SmallTopContentWrapper = styled.div`
  margin-bottom: ${styles.space.s};
`

const BigTopContentWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

const PlatformShareIcon = styled((props) => (
  <img
    {...props}
    alt="Share"
    src={isIOS() ? iosShareImage : androidShareImage}
  />
))`
  width: 16px;
  vertical-align: text-top;
  margin-right: ${styles.space.s};
`

export default function Share(props) {
  const {
    isOpen,
    headerPreamble,
    modalType,
    entryPointWasChainedInvite,
    linkForNativeShare,
    event,
    shareLink,
    userFirstName,
    mutedHeader,
    headerText,
    chainingAction,
    additionalNotification,
  } = props
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const [shareToEmails, setShareToEmails] = useState([])
  const canNativeShare = typeof navigator.share === 'function'
  const shareModalExperimentAllocated =
    useExperimentAllocation('share_with_mailto_recipients')?.variant.name ===
    'share_with_mailto_recipients'
  const shouldShowShareEmailInput =
    chainingAction === EventChainingAction.SIGNED_UP &&
    !canNativeShare &&
    shareModalExperimentAllocated

  const shareLinks = getShareLinks({
    entryPointWasChainedInvite,
    event,
    modalType,
    shareLink,
    userFirstName,
    hashtag: currentOrganization?.hashtag,
    recipientEmails: shareToEmails,
    shareToEmailsAllocated: shouldShowShareEmailInput,
  })

  // TODO(jared) move stuff referencing the modal up a level (to ShareModal and ChainingModalStepper)
  useEffect(() => {
    if (isOpen) {
      analytics.track('registration.shareModalViewed', {
        modal_type: modalType,
        entry_point_was_chained_invite: !!entryPointWasChainedInvite,
        can_native_share: typeof navigator.share === 'function',
        chaining_action: chainingAction,
      })
    }
  }, [isOpen, modalType, entryPointWasChainedInvite, chainingAction])

  const [copied, setCopied] = useState(false)
  const copyTimeoutHandler = useRef(null)
  function clearCopyTimeout() {
    if (copyTimeoutHandler.current) {
      clearTimeout(copyTimeoutHandler.current)
    }
  }
  // Clean up the timeout that resets the copy button so as to not set state on an unmounted
  // component (a memory leak)
  useEffect(() => clearCopyTimeout, [])
  function onCopy() {
    setCopied(true)
    trackShareInitiated('copy_link')
    // Make sure there's only one timeout handler registered so that we can clear it if the
    // component unmounts (if there were multiple, we'd only have a reference to the most recent
    // one, and the previous ones that we didn't tear down could still set state after unmount)
    clearCopyTimeout()
    copyTimeoutHandler.current = setTimeout(() => setCopied(false), 5000)
  }

  function trackShareInitiated(medium) {
    trackShareInitiatedUtil(medium, {
      modal_type: modalType,
      entry_point_was_chained_invite: !!entryPointWasChainedInvite,
    })
  }

  async function onInitiateNativeShare() {
    await nativeShareEvent(
      linkForNativeShare || shareLinks.nativeShareUrl,
      event,
      `share-modal-${modalType}`
    )
  }

  const linkForCopy = props.linkForCopy || shareLinks.copyUrl
  const linkProps = {plain: true, target: '_blank', style: {display: 'block'}}
  const {isDonationCampaign, isPromptForDonation} = getEventTypeAffinityChecks(
    event
  )

  const useSimpleHeaderText =
    event.is_virtual_flexible || isDonationCampaign || isPromptForDonation
      ? 'Can you share this with friends?'
      : ''

  function renderTopContent() {
    const typographyVariant = mutedHeader ? 'h2' : 'h1'

    if (chainingAction) {
      return (
        <Typography center variant={typographyVariant}>
          {headerPreamble ? headerPreamble : ''}
          {headerText ||
            useSimpleHeaderText ||
            //  TODO(jared) hook this up to live data
            'Over 560,457 signups have come from people sharing with friends'}
        </Typography>
      )
    }

    // Non-chaining context
    return (
      <>
        <Icon
          primary
          name="users"
          noMargin
          fontSize={styles.typography.mediumIcon}
        />
        <Typography center variant={typographyVariant}>
          {headerPreamble ? headerPreamble : ''}
          {headerText ||
            useSimpleHeaderText ||
            'Who are you bringing with you?'}
        </Typography>
      </>
    )
  }

  const TopContentWrapper = additionalNotification
    ? SmallTopContentWrapper
    : BigTopContentWrapper

  const handleShareToEmailsChange = (event, value) => {
    const latestNewValue = value?.pop()
    if (!latestNewValue) {
      setShareToEmails([])
      return
    }
    // split user input by newlines and commas
    const delimiter = /[\n,]+/
    const newValues = latestNewValue.split(delimiter)
    const alreadyIncluded = new Set(value)
    const filteredNewValues = new Set(
      newValues.map((v) => v.trim()).filter((v) => v && !alreadyIncluded.has(v))
    )
    setShareToEmails([...value, ...filteredNewValues])
  }

  return (
    <Container>
      <TopContentWrapper>{renderTopContent()}</TopContentWrapper>
      {additionalNotification && (
        <Typography variant="body1" center>
          {additionalNotification}
        </Typography>
      )}
      <div>
        <ShareOptionsWrapper>
          {shouldShowShareEmailInput && (
            <>
              <Autocomplete
                label={
                  <F defaultMessage="Send invites to these email addresses" />
                }
                hint={
                  <F defaultMessage="Press Enter after each email address to add another. Mobilize only uses this information to send your invitations." />
                }
                onChange={(e, {name, value}) => {
                  handleShareToEmailsChange(e, value)
                }}
                value={shareToEmails || []}
                options={[]}
                creatable
                multiple
                multiline
                disableClearable
                disableCreateLabels
                limitTags={50}
              />
              <div>
                <Link
                  {...linkProps}
                  to={shareLinks.mailtoUrl}
                  onClick={() => {
                    trackShareInitiatedUtil('mailto_recipients', {
                      modal_type: modalType,
                      has_recipients: shareToEmails.length > 0,
                    })
                  }}
                  data-testid="share-mailto-experiment"
                >
                  <Button
                    fluid
                    secondary
                    icon="envelope"
                    iconPosition="left-inline"
                  >
                    <F defaultMessage="Send invitations" />
                  </Button>
                </Link>
              </div>
              <Separator>or</Separator>
            </>
          )}
          <CopyToClipboard text={linkForCopy} onCopy={onCopy}>
            <Button
              fluid
              // make this the primary button (positioned at the bottom) if there's no native
              // share button
              secondary={canNativeShare}
              style={canNativeShare ? {} : {order: '1'}}
              success={copied}
              icon={copied ? 'check' : 'link'}
              iconPosition="left-inline"
            >
              {copied ? 'Copied' : 'Copy link'}
            </Button>
          </CopyToClipboard>
          <Link
            {...linkProps}
            to={shareLinks.facebookUrl}
            onClick={partial(trackShareInitiated, 'facebook')}
            data-testid="share-facebook"
          >
            <Button
              fluid
              secondary
              icon="facebook-f"
              iconPosition="left-inline"
            >
              Post to Facebook
            </Button>
          </Link>
          <Link
            {...linkProps}
            to={shareLinks.twitterUrl}
            onClick={partial(trackShareInitiated, 'twitter')}
            data-testid="share-twitter"
          >
            <Button fluid secondary icon="twitter" iconPosition="left-inline">
              Post to Twitter
            </Button>
          </Link>
          {!shouldShowShareEmailInput && (
            <Link
              {...linkProps}
              to={shareLinks.mailtoUrl}
              onClick={partial(trackShareInitiated, 'mailto')}
              data-testid="share-mailto"
            >
              <Button
                fluid
                secondary
                icon="envelope"
                iconPosition="left-inline"
              >
                Send via email
              </Button>
            </Link>
          )}
        </ShareOptionsWrapper>
        {canNativeShare && (
          <Button fluid onClick={onInitiateNativeShare}>
            <PlatformShareIcon /> Share to…
          </Button>
        )}
      </div>
    </Container>
  )
}

import {
  Button,
  Card,
  Form,
  Input,
  Link,
  Message,
  MessageType,
  Typography,
} from 'components'
import {
  Container,
  shouldPrecheckTwoTimeslots,
  shouldShowActLaterButton,
  shouldShowActNowButton,
} from './util'
import CustomFields, {customFieldsValueMapper} from './CustomFields'
import {animated, useTransition} from 'react-spring'
import {getAvailableTimeslots, getShifts} from 'events/details/util'
import {isDonationCampaignEvent, isPromptForDonationEvent} from 'util/event'
import {omit, pick} from 'util/common'
import {setCurrentVolunteer, setEventSignups} from 'redux/actions'
import styles, {fontSizeSmall} from 'components/styles'
import submitSignupForm, {validateForm} from './submitSignupForm'
import {useDispatch, useSelector} from 'react-redux'

import ActionButtons from './ActionButtons'
import DonationProgress from 'events/details/FundraisingForm/DonationProgress'
import EventClosedBox from 'events/components/EventClosedBox'
import PostModals from './PostModals'
import PoweredByMobilize from 'events/components/PoweredByMobilize'
import SMSOptIn from './SMSOptIn'
import {STREET_ADDRESS_FIELDS} from 'app/constants'
import ShiftPicker from './ShiftPicker'
import Sticky from './Sticky'
import SupporterProgress from './SupporterProgress'
import TermsOfService from 'events/components/TermsOfService'
import Timeslots from './Timeslots'
import UserSignupFormFields from 'events/components/UserSignupFormFields'
import {filterOutAtCapacityTimeslots} from 'util/timeslot'
import {getEventTypeAffinityChecks} from 'util/event'
import {getIdentityFieldsFromFormValues} from 'util/user'
import {getOrganizationFeedUrl} from 'util/routing'
import {maybeStringToMaybeInt} from 'util/string'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import useIdentityFields from 'hooks/identity-fields'
import {useIsAllocatedToExperimentVariant} from 'hooks/experiments'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router'
import {useState} from 'react'

const CustomSignupSubtext = styled.div`
  color: ${styles.colors.neutral400};
  ${fontSizeSmall};
  padding-left: ${styles.space.xs};
  padding-right: ${styles.space.xs};
`

const SignupWrapper = styled.div`
  display: flex;
  box-shadow: inset 0px -1px 0px 0px ${styles.colors.white},
    inset 0px -1px 0px 1px ${styles.colors.neutral300};
  border-top: 5px solid ${styles.colors.primary200};
  padding: ${styles.space.m} ${styles.space.m};
  overflow: hidden;
  background-color: ${styles.colors.white};

  & > div {
    width: 100%;
  }

  & > div:nth-of-type(2) {
    margin-left: -100%; /* for the transition */
  }
`

export const SignupStage = Object.freeze({
  SHIFT_PICKER: 1,
  IDENTITY_FIELDS: 2,
})

export default function SignupForm({
  event,
  initialQueryParams,
  maybePrioritizedTimeslot,
  onSuccess,
  organization,
  participationShortlink,
  shareParamsFromSignup,
  trackingParams,
  filterParams,
  eventSuggestionContext,
  owningGroups,
}) {
  const isNewShiftPickerOn = useIsAllocatedToExperimentVariant(
    'shift_picker_v3',
    'redesign'
  )

  const location = useLocation()
  const [customSignupFieldValues, setCustomSignupFieldValue] = useState(
    customFieldsValueMapper(event)
  )
  const [numAdditionalReservations, setNumAdditionalReservations] = useState(
    null
  )
  const [errorFields, setErrorFields] = useState({})
  const [expandActLater, setExpandActLater] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [shiftErrors, setShiftErrors] = useState({})
  const dispatch = useDispatch()
  const currentVolunteer = useSelector((state) => state.currentVolunteer)
  const storedSignedUpTimeslots = useSelector(
    (state) => state.eventSignups[event.id]
  )
  const availableTimeslots = getAvailableTimeslots(event)
  const [shifts, setShifts] = useState(
    getShifts(maybePrioritizedTimeslot, organization, event)
  )

  const [
    signupCreatedWithinLastFiveSeconds,
    setSignupCreatedWithinLastFiveSeconds,
  ] = useState(false)
  const [signupInFlightOrCreated, setSignupInFlightOrCreated] = useState(false)
  const [signupResponse, setSignupResponse] = useState({
    recommendedAdditionalTimeslots: [],
    shareShortlink: null,
    groupShortlink: null,
    timeslotIdForShare: null,
    userId: null,
    virtualActionRedirectURL: null,
  })
  const initialIdentityFields = useIdentityFields()
  const [values, setValues] = useState({
    // Exclude the street address fields because we do not support them in the
    // regular signup form and should not overwrite existing identity values
    // with blank values by default.
    ...omit(initialIdentityFields, STREET_ADDRESS_FIELDS),
    smsOptIn: false,
  })
  const identityFields = getIdentityFieldsFromFormValues(values)
  const {
    isAdvocacy,
    isPetition,
    isGroup,
    isRegistrationOnly,
  } = getEventTypeAffinityChecks(event)
  const hasOnlyOneAvailableTimeslot = availableTimeslots.length === 1
  const shouldShowNewShiftPickerFlow =
    isNewShiftPickerOn &&
    !event.is_virtual_flexible &&
    !hasOnlyOneAvailableTimeslot &&
    !isRegistrationOnly
  const signupStateForNonPreSignupExperiment = shouldShowNewShiftPickerFlow
    ? SignupStage.SHIFT_PICKER
    : SignupStage.IDENTITY_FIELDS
  const [signupStage, setSignupStage] = useState(
    signupStateForNonPreSignupExperiment
  )
  const [signupButtonsInView, setSignupButtonsInView] = useState(true)

  const [selectAllOn, setSelectAllOn] = useState(false)
  const showSelectAllButton =
    orgFlagIsActive(event.organization, 'enable_select_all_timeslots') &&
    event.select_all_timeslots_enabled

  const isGroupInvite =
    initialQueryParams.is_group_invite?.toLowerCase() === 'true'

  // groups should only have one timeslot
  const maybeTimeslotForGroup =
    availableTimeslots.length === 1 ? availableTimeslots[0] : null
  const isGroupAndAlreadyJoined = !!(
    isGroup &&
    maybeTimeslotForGroup &&
    storedSignedUpTimeslots?.includes(maybeTimeslotForGroup.id)
  )

  // These transitions flip depending on which stage you're in to give the
  // back and forth feel. If one day we added a 3rd stage you'd need to rework this.
  const fromRight =
    signupStage === SignupStage.IDENTITY_FIELDS ? 'from' : 'leave'
  const toLeft = signupStage === SignupStage.IDENTITY_FIELDS ? 'leave' : 'from'
  const transition = useTransition(signupStage, {
    key: signupStage,
    initial: {opacity: 1, transform: 'translate3d(0%,0,0)'},
    [fromRight]: {
      opacity: 0,
      transform: 'translate3d(100%,0,0)',
    },
    enter: {opacity: 1, transform: 'translate3d(0%,0,0)'},
    [toLeft]: {
      opacity: 0,
      transform: 'translate3d(-50%,0,0)',
    },
  })

  const handleSignupButtonsInViewChange = (inView) =>
    setSignupButtonsInView(inView)

  const handleChange = ({name, value}) => {
    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleActLaterClick = (e) => {
    e && e.preventDefault()
    setExpandActLater(true)
    setShifts([null])
  }

  const handleShiftsChange = (shifts) => setShifts(shifts)

  const handleCustomChange = (value) => setCustomSignupFieldValue(value)

  const handleGroupCountChange = (value) => {
    value
      ? setNumAdditionalReservations(value - 1)
      : setNumAdditionalReservations(value)
    if (shiftErrors) {
      setShiftErrors({})
    }
  }

  const handleSubmitSuccess = (
    signupRequest,
    signupResponse,
    updatedCurrentVolunteer,
    signupToShareResponse,
    shouldOpenModal
  ) => {
    onSuccess(signupRequest, signupResponse)
    // SignupForm only includes a subset of fields, so exclude street
    // address fields from the update.
    const currentVolunteerToSet = pick(
      updatedCurrentVolunteer,
      'firstName',
      'lastName',
      'email',
      'phone',
      'zip'
    )
    dispatch(setCurrentVolunteer(currentVolunteerToSet))
    dispatch(
      setEventSignups(
        event.id,
        signupRequest.shifts.map((s) => s.timeslot_id)
      )
    )
    setSignupResponse(signupToShareResponse)
    setErrorFields({})
    setShiftErrors({})
    setModalOpen(shouldOpenModal)
  }

  const handleSubmitError = (errorFields, shifts, shiftErrors) => {
    setErrorFields(errorFields)
    // If there is only one timeslot, we won't display shiftErrors, so record them in errorFields to display
    if (!!Object.keys(errorFields) && hasOnlyOneAvailableTimeslot) {
      setErrorFields(shiftErrors)
    } else {
      setShiftErrors(shiftErrors)
    }
    setShifts(shifts)
    setSignupInFlightOrCreated(false)
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    // If a tracking param is an empty string or a string composed of spaces it will fail validation.
    // In this step we trim spaces from a tracking param and then set any falsy params (like an empty
    // string) to null.
    for (var param in trackingParams) {
      trackingParams[param] = trackingParams[param]
        ? trackingParams[param].trim()
        : trackingParams[param]
      trackingParams[param] = trackingParams[param]
        ? trackingParams[param]
        : null
    }

    if (
      !validateForm({
        errorFields,
        event,
        setErrorFields,
        shifts,
        customSignupFieldValues,
      })
    ) {
      return
    }
    setSignupInFlightOrCreated(true)
    setSignupCreatedWithinLastFiveSeconds(true)
    setTimeout(() => {
      setSignupCreatedWithinLastFiveSeconds(false)
    }, 5000)
    submitSignupForm({
      customSignupFieldValues,
      event,
      expandActLater,
      initialQueryParams,
      location,
      onError: handleSubmitError,
      onSuccess: handleSubmitSuccess,
      organization,
      precheckedTwoTimeslots,
      selectAllTimeslotsSelected: selectAllOn,
      shifts,
      trackingParams,
      values,
      numAdditionalReservations,
      filterParams,
      eventSuggestionContext,
    })
  }

  const handleSignupProceed = (evt) => {
    evt.preventDefault()
    setSignupStage(SignupStage.IDENTITY_FIELDS)
  }
  const handleSignupRevisit = (evt) => {
    evt.preventDefault()
    setSignupStage(SignupStage.SHIFT_PICKER)
  }

  const precheckedTwoTimeslots = shouldPrecheckTwoTimeslots(
    maybePrioritizedTimeslot,
    organization,
    event
  )
  const notViewingAsEventOwningOrCoOwningOrg = !event.current_org_is_owner_or_co_owner
  let signupLine = `Sign up ${
    notViewingAsEventOwningOrCoOwningOrg
      ? `with ${event.organization.name}`
      : 'now'
  }`
  if (isAdvocacy) {
    signupLine = `${signupLine} and we'll call to connect you to your representatives`
  } else if (isPetition) {
    signupLine = `Sign this ${
      notViewingAsEventOwningOrCoOwningOrg ? `${event.organization.name} ` : ''
    }petition`
  } else if (isGroup) {
    signupLine = `Join this ${
      notViewingAsEventOwningOrCoOwningOrg ? `${event.organization.name} ` : ''
    }group`
  }

  const getErrorFieldsList = () => {
    // custom_fields errors are handled in the CustomFields component
    return Object.keys(omit(errorFields, 'custom_fields')).map(
      (fieldname) => errorFields[fieldname]
    )
  }

  // Empty state - event past or full
  const isPast = !event.times?.length
  const allFull =
    event.times && !filterOutAtCapacityTimeslots(event.times).length

  const totalSupporters = event.total_participant_count || 0
  const showPastEventSupporterCount =
    isPast && !!totalSupporters && isRegistrationOnly

  if (isPast || allFull) {
    if (showPastEventSupporterCount) {
      return (
        <Card>
          <Typography variant="body1">
            <span role="img" aria-label="green check">
              ✅
            </span>{' '}
            <strong>We did it!</strong>
            <div>
              {`${event.total_participant_count.toLocaleString()} supporters signed up for this action.`}
            </div>
          </Typography>
          {'You can find more opportunities to volunteer on our '}
          <Link
            to={
              organization.branding.url || getOrganizationFeedUrl(organization)
            }
          >
            event feed
          </Link>
          !
        </Card>
      )
    }

    return <EventClosedBox event={event} />
  }

  // TODO(mime): this is a bit convoluted now because I'm supporting two UIs at the same time.
  // Once the old UI goes away I'll refactor this to be easier to read.
  function renderForm(isShiftPickerStage) {
    // begin TODO(mime): temp variables until the variants go away.
    const shouldShowIdentityFieldsStageHeader =
      shouldShowNewShiftPickerFlow && !isShiftPickerStage
    const shouldShowIdentityFields =
      !shouldShowNewShiftPickerFlow || !isShiftPickerStage
    const shouldShowShiftPicker =
      isShiftPickerStage && !hasOnlyOneAvailableTimeslot
    const shouldShowTimeslots = !hasOnlyOneAvailableTimeslot
    const shouldShowTOSAndSMS = !isNewShiftPickerOn || !isShiftPickerStage
    // end TODO(mime)

    // TODO(julia): update this to not show group signup field if user is
    // signing up to join a group (ie if using group signup link)
    const shouldShowGroupSignupField =
      orgFlagIsActive(event.organization, 'enable_group_signup') &&
      event.group_signup_enabled &&
      !isGroupInvite

    return (
      <>
        {shouldShowIdentityFieldsStageHeader && (
          <>
            {!hasOnlyOneAvailableTimeslot && (
              <Button
                link
                icon="chevron-left"
                padding="none"
                iconPosition="left"
                onClick={handleSignupRevisit}
                noUnderline
              >
                Back to timeslots
              </Button>
            )}
            <Typography variant="h2">Finish signing up</Typography>
          </>
        )}
        {shouldShowIdentityFields && (
          <>
            <UserSignupFormFields
              errorFields={errorFields}
              identityFields={identityFields}
              onChange={handleChange}
            />
            <CustomFields
              customSignupFieldValues={customSignupFieldValues}
              event={event}
              onChange={handleCustomChange}
              errors={errorFields.custom_fields}
            />
            {shouldShowGroupSignupField && (
              <>
                <Typography variant="h3">
                  Are you signing up a group?
                </Typography>
                <Input
                  name="group_signup_count"
                  type="number"
                  label="Group size (optional)"
                  fluid
                  value={undefined}
                  onChange={(e) =>
                    handleGroupCountChange(
                      maybeStringToMaybeInt(e.target.value)
                    )
                  }
                  hint={
                    event.group_signup_size_limit
                      ? `Number of group members, including yourself. Maximum group size is ${event.group_signup_size_limit}`
                      : `Number of group members, including yourself`
                  }
                  min={1}
                  max={event.group_signup_size_limit || undefined}
                />
              </>
            )}
          </>
        )}
        {shouldShowNewShiftPickerFlow
          ? shouldShowShiftPicker && (
              <ShiftPicker
                event={event}
                onChange={handleShiftsChange}
                shifts={shifts}
                shiftErrors={shiftErrors}
                showSelectAll={showSelectAllButton}
                selectAllOn={selectAllOn}
                setSelectAllOn={setSelectAllOn}
              />
            )
          : shouldShowTimeslots && (
              <Timeslots
                event={event}
                maybePrioritizedTimeslot={maybePrioritizedTimeslot}
                onChange={handleShiftsChange}
                precheckedTwoTimeslots={precheckedTwoTimeslots}
                shifts={shifts}
                shiftErrors={shiftErrors}
                showSelectAll={showSelectAllButton}
                selectAllOn={selectAllOn}
                setSelectAllOn={setSelectAllOn}
              />
            )}
        {organization.branding.custom_signup_form_subtext && (
          <Container>
            <CustomSignupSubtext>
              {organization.branding.custom_signup_form_subtext}
            </CustomSignupSubtext>
          </Container>
        )}
        {Object.keys(errorFields).length > 0 && (
          <Container>
            <Message
              type={MessageType.ERROR}
              header="Signup failed."
              list={getErrorFieldsList()}
            />
          </Container>
        )}
        <ActionButtons
          event={event}
          expandActLater={expandActLater}
          identityFields={identityFields}
          isShiftPickerStage={isShiftPickerStage}
          onActLaterClick={handleActLaterClick}
          onSignupButtonsInViewChange={handleSignupButtonsInViewChange}
          onSignupProceed={handleSignupProceed}
          onShiftsChange={handleShiftsChange}
          shiftErrors={shiftErrors}
          shifts={shifts}
          signupCreatedWithinLastFiveSeconds={
            signupCreatedWithinLastFiveSeconds
          }
          signupInFlightOrCreated={signupInFlightOrCreated}
          isGroupAndAlreadyJoined={isGroupAndAlreadyJoined}
        />

        <PoweredByMobilize />

        {shouldShowTOSAndSMS && (
          <>
            <TermsOfService
              hasMultipleButtons={
                shouldShowActNowButton(event, expandActLater) &&
                shouldShowActLaterButton(event, expandActLater)
              }
            />
            <SMSOptIn
              event={event}
              isChecked={values.smsOptIn}
              onChange={handleChange}
              organization={organization}
            />
          </>
        )}
      </>
    )
  }

  const Wrapper =
    isNewShiftPickerOn && !isRegistrationOnly ? SignupWrapper : Card
  const showHeader = !isNewShiftPickerOn || isRegistrationOnly
  const enableSupporterCount =
    event.is_virtual_flexible && !event.disable_participant_count
  const enableDonationCount =
    (isPromptForDonationEvent(event) || isDonationCampaignEvent(event)) &&
    !event.disable_participant_count

  return (
    <>
      <Form className="signup-form" onSubmit={handleSubmit}>
        <Wrapper header={showHeader ? signupLine : undefined}>
          {enableSupporterCount && <SupporterProgress expanded event={event} />}
          {!enableSupporterCount && enableDonationCount && (
            <DonationProgress expanded event={event} />
          )}
          {transition((style, signupStage) => {
            const isShiftPickerStage = signupStage === SignupStage.SHIFT_PICKER

            return (
              <animated.div style={style}>
                {renderForm(isShiftPickerStage)}
              </animated.div>
            )
          })}
        </Wrapper>

        <Sticky
          currentVolunteer={currentVolunteer}
          event={event}
          expandActLater={expandActLater}
          identityFields={identityFields}
          initialQueryParams={initialQueryParams}
          onActLaterClick={handleActLaterClick}
          onShiftsChange={handleShiftsChange}
          organization={organization}
          participationShortlink={participationShortlink}
          shareParamsFromSignup={shareParamsFromSignup}
          shifts={shifts}
          shouldDisplay={!signupButtonsInView}
          signupCreatedWithinLastFiveSeconds={
            signupCreatedWithinLastFiveSeconds
          }
          signupInFlightOrCreated={signupInFlightOrCreated}
          isGroupAndAlreadyJoined={isGroupAndAlreadyJoined}
        />
      </Form>
      <PostModals
        currentVolunteer={currentVolunteer}
        event={event}
        expandActLater={expandActLater}
        identityFields={identityFields}
        modalOpen={modalOpen}
        organization={organization}
        setModalOpen={setModalOpen}
        signupResponse={signupResponse}
        trackingParams={trackingParams}
        owningGroups={owningGroups}
      />
    </>
  )
}

import {
  CardModal,
  Link,
  ListExpand,
  MaxWidthContainer,
  Message,
  MessageType,
  Typography,
} from 'components'
import {CarouselCategory, ParticipationStatus} from 'app/enums'
import {getOrganizationEventUrl, getUserSettingsUrl} from 'util/routing'

import AffiliationItem from 'events/schedule/AffiliationItem'
import {Component} from 'react'
import {EventsFetcherCarousel} from 'events/components'
import {F} from 'util/i18n'
import ImpactStats from 'events/schedule/ImpactStats'
import ParticipationItem from './ParticipationItem'
import UpcomingEventsEmptyState from 'events/schedule/UpcomingEventsEmptyState'
import analytics from 'analytics'
import api from 'data/api'
import {createMailUrl} from 'util/url'
import {formatSingleStartTime} from 'util/time'
import {maybeStringToMaybeInt} from 'util/string'
import moment from 'vendor/moment'
import {partition} from 'underscore'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import withScreenData from 'app/withScreenData'

const HeaderText = styled.div`
  padding-top: ${styles.space.m};
  padding-bottom: ${styles.space.xl};
`
const DetailModalContent = styled.div`
  padding-bottom: ${styles.space.m};
`
const BodyWrapper = styled.div`
  border-top: 1px solid ${styles.colors.neutral300};
  background-color: ${styles.colors.neutral100};
  flex-grow: 1;
  padding-top: ${styles.space.l};
`

const RecommendationWrapper = styled.div`
  border-top: 1px solid ${styles.colors.neutral300};
  border-bottom: 1px solid ${styles.colors.neutral300};
  background-color: ${styles.colors.neutral200};
  margin: ${styles.space.xl} 0;
  padding: ${styles.space.l} 0;
`

const Section = styled.div`
  margin-top: ${styles.space.l};
`

class VolunteerScheduleScreen extends Component {
  state = {
    participations: this.props.data.participations,
    detailModalParticipation: null,
    error: null,
  }

  updateParticipation = async (participationId, body) => {
    this.setState({error: null})
    let res
    try {
      res = await api.updateVolunteerScheduleParticipation(
        participationId,
        this.props.data.person.schedule_url,
        body
      )
    } catch (error) {
      this.setState({
        error: {participationId, message: error.message},
      })
      return
    }
    this.setState({participations: [...res.participations]})
    analytics.track('registration.update', {
      method: 'volunteer schedule',
      ...body,
    })
    return res
  }

  updateGroupMemberStatus = async (
    leaderParticipationId,
    memberParticipationId,
    status
  ) => {
    this.setState({error: null})
    let res
    try {
      res = await api.updateGroupMember(
        leaderParticipationId,
        memberParticipationId,
        {status}
      )
    } catch (error) {
      // TODO: Where does the error belong
      return
    }
    this.setState({participations: [...res.schedule_participations]})
    analytics.track('registration.update', {
      method: 'group leader',
      status,
    })
    return res
  }

  addGroupMemberSignup = async (leaderParticipationId, body) => {
    this.setState({error: null})
    let res
    try {
      res = await api.addGroupMemberSignup(leaderParticipationId, body)
    } catch (error) {
      // TODO: Where does the error belong
      return
    }
    this.setState({participations: [...res.schedule_participations]})
    return res
  }

  showParticipationDetails = (participation) => {
    this.setState({detailModalParticipation: participation})
  }

  formatTimeForSectionHeader = (time) => {
    // this is in browser local time
    const m = moment(time)

    let dayDescriptor = ''
    if (m.isSame(moment(), 'day')) {
      dayDescriptor = 'Today: '
    } else if (m.isSame(moment().add(1, 'day'), 'day')) {
      dayDescriptor = 'Tomorrow: '
    }

    let timeString
    if (m.year() === moment().year()) {
      timeString = m.format('dddd, MMM D')
    } else {
      // Include the year if the date is not this year
      timeString = m.format('dddd, MMM D, YYYY')
    }
    return `${dayDescriptor}${timeString}`
  }

  renderParticipation = (p, person) => {
    const {error} = this.state
    const {initialQueryParams, data} = this.props
    const {recommended_timeslots} = data
    const participationWasModifiedOnPageload =
      maybeStringToMaybeInt(initialQueryParams.participation_id) === p.id
    const showFeedbackModal = !!(
      participationWasModifiedOnPageload &&
      initialQueryParams.feedback_type &&
      p.event.volunteer_check_in_is_enabled
    )
    const participationWasConfirmedOnPageload =
      participationWasModifiedOnPageload &&
      maybeStringToMaybeInt(initialQueryParams.status) ===
        ParticipationStatus.CONFIRMED
    const participationWasCancelledOnPageload =
      participationWasModifiedOnPageload &&
      maybeStringToMaybeInt(initialQueryParams.status) ===
        ParticipationStatus.CANCELLED
    const initialRecommendedTimeslots = participationWasModifiedOnPageload
      ? recommended_timeslots
      : []

    return (
      <div key={p.id}>
        <ParticipationItem
          participation={p}
          updateParticipation={this.updateParticipation}
          updateGroupMemberStatus={this.updateGroupMemberStatus}
          addGroupMemberSignup={this.addGroupMemberSignup}
          showDetails={() => this.showParticipationDetails(p)}
          feedbackModalOpenOnLoad={showFeedbackModal}
          person={person}
          participationWasConfirmedOnPageload={
            participationWasConfirmedOnPageload
          }
          participationWasCancelledOnPageload={
            participationWasCancelledOnPageload
          }
          initialRecommendedTimeslots={initialRecommendedTimeslots}
        />
        {error && error.participationId === p.id && (
          <Message type={MessageType.ERROR} role={'alert'}>
            {Object(error.message).includes(
              'num_additional_reserved_participations'
            )
              ? 'This time does not have enough available spots for a group of this size'
              : error.message}
          </Message>
        )}
      </div>
    )
  }

  render() {
    const {data} = this.props
    const {affiliations, current_organization, person, user} = data
    const [pastParticipations, upcomingParticipations] = partition(
      this.state.participations,
      (participation) => moment(participation.starts_at_utc).isBefore(moment())
    )

    const {detailModalParticipation} = this.state
    const detailModalEvent = detailModalParticipation
      ? detailModalParticipation.event
      : null

    // NB(avi): we could probably optimize this a bit more (e.g. "Mr. Arfin" if we only have
    // last name, use email if we have neither, but for now just do something that won't
    // look borked
    const scheduleTitle =
      person.first_name || person.last_name
        ? `Schedule for ${person.first_name} ${person.last_name}`
        : 'Your schedule'
    const mailtoLink =
      detailModalEvent &&
      detailModalParticipation &&
      createMailUrl(
        detailModalEvent.reply_to_email || '',
        `Question about “${detailModalEvent.name}”`,
        `Hello ${detailModalEvent.organization.name},\n` +
          `I have a question about…\n\n\n` +
          `📅 ${detailModalEvent.name}\n` +
          `${formatSingleStartTime(
            detailModalParticipation.starts_at_tz,
            detailModalEvent.timezone
          )}\n` +
          getOrganizationEventUrl(
            detailModalParticipation.affiliation,
            detailModalEvent
          ).toString()
      )

    const sortedAffiliations = affiliations
      ? affiliations.sort((a, b) =>
          a.user_unfollowed_at > b.user_unfollowed_at ? 1 : -1
        )
      : []

    return (
      <BodyWrapper>
        <MaxWidthContainer>
          <HeaderText>
            <Typography variant="h1">
              <F defaultMessage={scheduleTitle} />
            </Typography>
            <Typography variant="body1">
              <F defaultMessage="Thank you for volunteering! Please confirm attendance at any upcoming events." />
            </Typography>
            <Link target="_blank" to={getUserSettingsUrl()}>
              <F defaultMessage="Manage notifications and preferences" />
            </Link>
          </HeaderText>
          <div data-testid="volschedule-upcoming">
            <Typography variant="h2">
              <F defaultMessage="Your next event" />
            </Typography>
            {upcomingParticipations.length > 0 ? (
              this.renderParticipation(
                upcomingParticipations[upcomingParticipations.length - 1],
                person
              )
            ) : (
              <UpcomingEventsEmptyState
                neverSignedUpBefore={!(this.state.participations?.length > 0)}
                organization={current_organization}
              />
            )}
          </div>
          <div>
            {upcomingParticipations.length > 0 && (
              <Typography variant="h2">
                <F defaultMessage="More upcoming events" />
              </Typography>
            )}
            {upcomingParticipations.length === 1 && (
              <UpcomingEventsEmptyState
                neverSignedUpBefore={!(this.state.participations?.length > 0)}
                organization={current_organization}
              />
            )}
            {upcomingParticipations
              .slice(0, -1) // We already rendered the Next Event above
              .reverse() // Order the events from nearest in the future to furthest
              .map((p) => this.renderParticipation(p, person))}
          </div>

          <Section>
            <Typography variant="h2">
              <F defaultMessage="Your impact" />
            </Typography>
            <ImpactStats
              participations={this.state.participations}
              referredUserCount={data.referred_user_count}
              organization={data.current_organization}
            />
          </Section>

          {/* TODO: 'more of your upcoming events' collapsible section here */}

          {sortedAffiliations.length > 0 && (
            <Section>
              <Typography variant="h2">
                <F defaultMessage="Your organizations" />
              </Typography>
              <Typography variant="body1">
                <F defaultMessage="Organizations you’ve supported on Mobilize. When you follow an organization, they can send you recruitment emails. " />
                <Link to="https://mblz.io/mbzfolloworunfollow" target="_blank">
                  Learn more
                </Link>
              </Typography>
              {/* $FlowFixMe */}
              <ListExpand
                childElement={AffiliationItem}
                expandText="See more organizations"
                initialNumber={3}
                list={sortedAffiliations}
                listPropName={'affiliation'}
                user={user}
              />
            </Section>
          )}
        </MaxWidthContainer>

        {this.state.participations?.length > 0 && (
          <RecommendationWrapper data-testid="carousel-wrapper">
            <EventsFetcherCarousel
              carouselCategory={CarouselCategory.MORE_OF_TYPES}
              organization={current_organization}
              relatedEvents={this.state.participations.map(
                (participation) => participation.event
              )}
              key={CarouselCategory.MORE_OF_TYPES}
              shouldDelayLoad
              eventSuggestionContext={`vol-shedule-carousel-${CarouselCategory.MORE_OF_TYPES}`}
              signupSource={`one_tap_carousel_vol_schedule_${CarouselCategory.MORE_OF_TYPES}`}
            />

            {/* TODO: add org recommendation carousel here */}
          </RecommendationWrapper>
        )}

        <MaxWidthContainer>
          {pastParticipations.length > 0 && (
            <div data-testid="volschedule-past">
              <Typography variant="h2">
                <F defaultMessage="Past" />
              </Typography>
              {pastParticipations.map((p) =>
                this.renderParticipation(p, person)
              )}
            </div>
          )}

          {detailModalParticipation && detailModalEvent && (
            <CardModal
              isOpen
              header="Details"
              onRequestClose={() => {
                this.setState({detailModalParticipation: null})
              }}
            >
              <DetailModalContent>
                <Typography variant="h1">{detailModalEvent.name}</Typography>
                <Typography variant="body2" component="div">
                  <Link
                    to={getOrganizationEventUrl(
                      detailModalParticipation.affiliation,
                      detailModalEvent
                    )}
                  >
                    <F defaultMessage="See full event details" />
                  </Link>
                  {(detailModalEvent.contact_name ||
                    detailModalEvent.reply_to_email) && (
                    <>
                      <Typography variant="h4">
                        <F defaultMessage="Contact" />
                      </Typography>
                      {detailModalEvent.contact_name && (
                        <p>{detailModalEvent.contact_name}</p>
                      )}
                      {detailModalEvent.reply_to_email && (
                        <p>
                          <a
                            href={mailtoLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {detailModalEvent.reply_to_email}
                          </a>
                        </p>
                      )}
                    </>
                  )}
                  {detailModalEvent.pro_tips_html && (
                    <>
                      <Typography variant="h4">
                        <F defaultMessage="Additional Info" />
                      </Typography>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: detailModalEvent.pro_tips_html,
                        }}
                      />
                    </>
                  )}
                </Typography>
              </DetailModalContent>
            </CardModal>
          )}
        </MaxWidthContainer>
      </BodyWrapper>
    )
  }
}

export default withScreenData(VolunteerScheduleScreen, {
  // Remove these so that reloading the page doesn't modify the participation again
  queryParamsToRemove: ['status', 'feedback_type'],
})

import styles, {fontSizeSmall} from 'components/styles'

import Card from 'components/Card'
import MaxWidthContainer from 'components/MaxWidthContainer'
import styled from '@emotion/styled/macro'

const DisclaimerContent = styled.div`
  ${fontSizeSmall};
  color: ${styles.colors.neutral400};
  text-align: center;
`

const Container = styled.aside`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
`

const FecDisclaimer = ({organization}) => (
  <MaxWidthContainer noGutters>
    <Container>
      <Card inline>
        <DisclaimerContent
          dangerouslySetInnerHTML={{
            __html: organization.branding.fec_disclaimer_html,
          }}
        />
      </Card>
    </Container>
  </MaxWidthContainer>
)

export default FecDisclaimer

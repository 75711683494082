import {
  CustomSignupFieldType,
  PermissionTierToDisplayName,
  SignupStep,
  eventTypeToDisplayName,
} from 'app/enums'
import {filterFalsy, logError, omit, pick, pickBy} from 'util/common'
import {
  formatIdentityFieldsForSignupRequest,
  getIdentityFieldsFromFormValues,
} from 'util/user'
import {isFbShareUrl, isTweetUrl} from 'util/share'

import analytics from 'analytics'
import api from 'data/api'
import {convertSubmitJsResponseToPaymentDataForAnalytics} from 'events/details/FundraisingForm/util'
import {getQueryParams} from 'util/url'
import {maybeStringToMaybeInt} from 'util/string'
import {orgFlagIsActive} from 'util/organization'
import {signUpWithPayment} from 'events/details/FundraisingForm/api'

const ALLOWED_CURRENT_VOLUNTEER_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'zip',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
]

export default async function submitSignupForm({
  customSignupFieldValues,
  event,
  expandActLater,
  initialQueryParams,
  location,
  onError,
  onSuccess,
  organization,
  precheckedTwoTimeslots,
  selectAllTimeslotsSelected,
  shifts,
  trackingParams,
  values,
  numAdditionalReservations,
  filterParams,
  paymentData,
  ...otherProps
}) {
  const maybeGetIdFromParams = (param) => {
    const parsedId = maybeStringToMaybeInt(param)
    return typeof parsedId === 'number' && parsedId > 0 ? parsedId : null
  }
  const maybeReferringUserId = maybeGetIdFromParams(
    initialQueryParams.referring_vol
  )
  const maybeReferringParticipationId = maybeGetIdFromParams(
    initialQueryParams.referring_participation
  )

  // If passed directly, use eventSuggestionContext from props. Otherwise,
  // copy the value of followup_modal_context ingested at app load time into
  // a variable with a more descriptive name to store on the Participation.
  const eventSuggestionContext =
    otherProps.eventSuggestionContext || trackingParams.followup_modal_context

  function getSubmissionPayload() {
    const qs = getQueryParams(location.search)
    // Remap to the snake_cased field names expected by the serializer.
    const identityFields = formatIdentityFieldsForSignupRequest(
      getIdentityFieldsFromFormValues(values)
    )

    return {
      shifts: filterFalsy(shifts).filter(
        (shift) => !!(shift && shift.timeslot_id)
      ),
      affiliation_id: organization.id,
      smsOptIn: values.smsOptIn,
      createdFromDashboard: false,
      referring_user_id: maybeReferringUserId,
      referring_participation_id: maybeReferringParticipationId,
      referring_data_signature:
        initialQueryParams.referring_data_signature || null,
      referrer_share_context: qs.share_context,
      referrer_share_medium: qs.share_medium,
      sharer_role: qs.sharerRole,
      custom_signup_fields: customSignupFieldValues,
      is_promoted: !event.current_org_is_owner_or_co_owner,
      event_suggestion_context: eventSuggestionContext,
      num_additional_reserved_participations: numAdditionalReservations || null,
      ...identityFields,
      ...trackingParams,
    }
  }

  function handleSignupOrPaymentError(error, signupStep = SignupStep.SIGNUP) {
    const errors = error.json
      ? omit(error.json.error, 'message')
      : error.rawError
      ? error.rawError
      : {}
    const shiftErrors = errors.shifts || {}
    const updatedShifts = shifts.filter(
      (shift) =>
        !shift || !shiftErrors.hasOwnProperty(String(shift.timeslot_id))
    )
    const errorFields = omit(errors, 'shifts')
    let errorMessages = []
    // Errors from submit.js return in the 'text' fields of each array element,
    // and errors from our own error handling return as errorFields[error]
    for (let field in errorFields) {
      if (Object.keys(errorFields[field]).includes('text')) {
        errorMessages.push(errorFields[field]['text'])
      } else if (Object.keys(errorFields[field]).includes('errorMessages')) {
        for (let innerItem in errorFields[field]['errorMessages']) {
          errorMessages.push(
            errorFields[field]['name'] +
              ' ' +
              errorFields[field]['errorMessages'][innerItem]
          )
        }
      } else {
        errorMessages.push(errorFields[field])
      }
    }
    onError(errorFields, updatedShifts, shiftErrors, signupStep, errorMessages)
  }

  if (event.virtual_action_url) {
    // for social share actions, redirect in a new tab immediately upon signup
    // NB: this is before the api call below to ensure that the browser associates the user click with the window open
    const redirectUrl = new URL(event.virtual_action_url)
    const isTweetRedirect = isTweetUrl(redirectUrl)
    const isFbShareRedirect = isFbShareUrl(redirectUrl)
    if (isTweetRedirect || isFbShareRedirect) {
      const popup = window.open(
        redirectUrl.toString(),
        '_blank',
        !isFbShareRedirect ? 'noopener noreferrer' : undefined
      )
      if (!popup) {
        analytics.track('socialShareAction.blocked', {
          eventId: event.id,
          url: event.virtual_action_url,
        })
      }
    }
  }

  const {
    created_by_distributed_organizing,
    creator_permission_tier: permissionTier,
  } = event
  const signupData = getSubmissionPayload()
  let signupResponse
  let signupWithPaymentData

  if (paymentData) {
    try {
      analytics.trackSignupWithPaymentAttempt(signupData, paymentData)
      const res = await signUpWithPayment(event, paymentData, signupData)
      signupWithPaymentData = res.json
    } catch (e) {
      // Track error along with attempt info
      analytics.trackSignupWithPaymentError(signupData, e)

      // Check for Failed Dependency status code that we set in the view.
      if (e.statusCode === 424) {
        handleSignupOrPaymentError(e, SignupStep.PAYMENT)
      }
      // check for miscellaneous submitJs errors
      else if (e.rawError) {
        handleSignupOrPaymentError(e, SignupStep.PAYMENT)
      } else {
        handleSignupOrPaymentError(e)
      }
      // N.B. Log everything for now to gather more data around payment
      // failures.
      logError(e)
      return
    }
    signupResponse = signupWithPaymentData.signup_response
  } else {
    try {
      signupResponse = await api.signUpByEventId(event.id, signupData)
    } catch (error) {
      handleSignupOrPaymentError(error)
      return
    }
  }

  const updatedCurrentVolunteer = pickBy(
    values,
    // N.B. lodash pickBy orders this as (value, key)
    (value, key) =>
      ALLOWED_CURRENT_VOLUNTEER_FIELDS.includes(key) &&
      typeof value === 'string'
  )

  const virtualActionRedirectURL = signupResponse.virtual_action_redirect_url
    ? signupResponse.virtual_action_redirect_url
    : event.virtual_action_url

  const firstShift = shifts[0]
  const firstTimeslotId = firstShift && firstShift.timeslot_id

  const signupToShareResponse = {
    recommendedAdditionalTimeslots: signupResponse.recommended_timeslots || [],
    shareShortlink: signupResponse.share_shortlink || null,
    groupShortlink: signupResponse.group_invite_shortlink || null,
    timeslotIdForShare: firstTimeslotId,
    userId: signupResponse.user_id,
    groupSignupInfo: signupResponse.group_signup_info,
    virtualActionRedirectURL,
  }
  const shouldOpenModal = !signupResponse.user_is_blocked

  onSuccess(
    signupData,
    signupResponse,
    updatedCurrentVolunteer,
    signupToShareResponse,
    shouldOpenModal
  )

  if (!signupResponse.user_is_blocked) {
    const analyticsData = {
      ...signupData,
      ...analytics.maybeGetOrgProperties(organization),
      created_by_distributed_organizing,
      event_host_type:
        permissionTier && PermissionTierToDisplayName[permissionTier],
      eventType: eventTypeToDisplayName(event.event_type, event.is_virtual),
      // TODO(jared): this is a known misnomer and will be removed when we render the followup
      // modal on the feed from the SMS signup-chaining work. for now, though, it renders the feed
      // and reuses this property (plumbed through from there) to attribute it to SMS chaining
      followup_modal_context: eventSuggestionContext,
      // This is a dupe of referring_user_id in the submission payload, which is also sent as part
      // of this event, but keep this in the analytics payload for backwards compatibility
      referringVol: maybeReferringUserId,
      numShifts: signupData.shifts.length,
      prechecked_two_timeslots: precheckedTwoTimeslots,
      signup_source: 'event_page',
      virtual: event.is_virtual,
      virtualWhen: event.is_virtual_flexible
        ? expandActLater
          ? 'later'
          : 'now'
        : null,
    }

    if (paymentData && signupWithPaymentData) {
      const paymentAnalyticsData = convertSubmitJsResponseToPaymentDataForAnalytics(
        signupWithPaymentData,
        paymentData.paymentValues
      )
      // Add payment details if a payment is present.
      analyticsData['payment'] = paymentAnalyticsData
    }

    // If the org flag is on and the event has the "Select all" option turned on,
    // track whether the user used select all when signing up.
    if (
      orgFlagIsActive(event.organization, 'enable_select_all_timeslots') &&
      event.select_all_timeslots_enabled
    ) {
      analyticsData[
        'select_all_timeslots_selected'
      ] = !!selectAllTimeslotsSelected
    }

    analytics.trackRegistrationCreated(analyticsData, filterParams)
  }

  signupResponse.user_id &&
    analytics.maybeIdentifyUser({
      id: signupResponse.user_id,
      ...pick(updatedCurrentVolunteer, 'firstName', 'lastName', 'email'),
    })
}

export function validateForm({
  errorFields,
  event,
  setErrorFields,
  shifts,
  customSignupFieldValues,
}) {
  const truthyShifts = filterFalsy(shifts)
  if (truthyShifts.length === 0) {
    setErrorFields({
      ...errorFields,
      timeslots: 'You must select at least one time.',
    })
    return false
  }

  const filteredShifts = truthyShifts.filter(
    (shift) => !!(shift && shift.timeslot_id)
  )
  if (
    event.is_virtual_flexible &&
    (!filteredShifts.length || filteredShifts.some((shift) => !shift.start))
  ) {
    setErrorFields({
      ...errorFields,
      timeslots: 'Please choose a time.',
    })
    return false
  }

  // Since we have browser-level required field validation, this validation will
  // only be reached by user agents that don't support HTML5. We may remove this
  // code in the future or expand it to include user-specified custom field
  // validations.
  const requiredCustomFieldsEnabled = orgFlagIsActive(
    event.organization,
    'enable_required_custom_fields'
  )
  if (requiredCustomFieldsEnabled && event.custom_signup_fields) {
    for (const customField of event.custom_signup_fields) {
      if (!customField.is_required) {
        continue
      }
      const fieldValue = customSignupFieldValues.find(
        (v) => v.custom_signup_field_id === customField.id
      )
      if (
        !fieldValue ||
        (customField.field_type === CustomSignupFieldType.BOOLEAN &&
          !fieldValue.boolean_value) ||
        ([
          CustomSignupFieldType.SHORT_TEXT,
          CustomSignupFieldType.LONG_TEXT,
          CustomSignupFieldType.DROPDOWN,
        ].includes(customField.field_type) &&
          !fieldValue.text_value)
      ) {
        setErrorFields({
          ...errorFields,
          custom_fields: {
            [customField.id.toString()]: {message: 'This field is required'},
          },
        })
        return false
      }
    }
  }

  return true
}

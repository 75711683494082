//

import {
  Button,
  Icon,
  Input,
  Loader,
  Message,
  MessageType,
  Tooltip,
  Typography,
} from '../../components'
import {F, strongElement} from '../../util/i18n'
import React, {useEffect, useState} from 'react'

import {AttendanceStatus} from 'app/enums'
import {LinearProgress} from '@material-ui/core'
import {getGroupSignupCounts} from '../../util/groupSignup'
import {isNumber} from '../../util/common'
import {keyframes} from '@emotion/react'
import {makeStyles} from '@material-ui/core/styles'
import styled from '@emotion/styled/macro'
import styles from '../../components/styles'
import {useSnackbar} from 'notistack'

const scaleUpVerTop = keyframes`
  0% {
      transform: scaleY(0.2);
      transform-origin: 100% 0%;
  }
  100% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
  }
`

const ScaleUpAnimation = styled.div`
  animation: ${scaleUpVerTop} 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${styles.space.s};
`

const CounterContainer = styled.div`
  display: flex;
  padding: ${styles.space.s};
`

const CounterWithCircle = styled.div`
  height: 30px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
`

const Circle = styled.span`
  height: 15px;
  width: 15px;
  background-color: ${(props) =>
    props.active ? styles.colors.primary200 : styles.colors.primary100};
  border-radius: 50%;
  display: inline-block;
  align-self: center;
`

const GreetingsDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 1rem 0;
`

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    backgroundColor: styles.colors.primary100,
  },
  bar: {
    borderRadius: '0',
  },
  root: {
    height: styles.space.l,
  },
}))

export default function GroupReservationSize({
  participation,
  groupLeaderName,
  updateParticipation,
}) {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const {id, max_group_capacity, status_attendance_combined} = participation
  const {
    numUnclaimedGroupSpots,
    numAdditionalGroupSpots,
    numAdditionalGroupSpotsPlusLeaderIfNotCancelled,
    numClaimedGroupSpotsPlusLeaderIfNotCancelled,
  } = getGroupSignupCounts(participation)

  const [loading, setLoading] = useState(false)
  const [desiredGroupSize, setDesiredGroupSize] = useState(
    numAdditionalGroupSpotsPlusLeaderIfNotCancelled
  )

  // This catches things like one of the group members cancelling (which
  // reduces num_additional_reserved_participations) which happens
  // outside this modal but changes the state
  useEffect(() => {
    setDesiredGroupSize(numAdditionalGroupSpotsPlusLeaderIfNotCancelled)
  }, [numAdditionalGroupSpotsPlusLeaderIfNotCancelled])

  const overMaxCapacity =
    isNumber(max_group_capacity) && desiredGroupSize > max_group_capacity

  const underRegisteredCount =
    desiredGroupSize < numClaimedGroupSpotsPlusLeaderIfNotCancelled

  const changeIsInvalid = overMaxCapacity || underRegisteredCount

  async function updateGroupSize(newGroupSize) {
    setLoading(true)
    const response = await updateParticipation(id, {
      num_additional_reserved_participations:
        newGroupSize -
        (status_attendance_combined === AttendanceStatus.CANCELLED ? 0 : 1),
    })
    setLoading(false)
    if (response) {
      snackbar.enqueueSnackbar('Reservation size updated.', {
        variant: 'success',
      })
    }
  }

  function greetingsString() {
    const values = {
      groupLeaderName,
      numAdditionalGroupSpots,
    }
    if (status_attendance_combined !== AttendanceStatus.CANCELLED) {
      return (
        <F
          defaultMessage="Hi, {groupLeaderName}! You and {numAdditionalGroupSpots} {numAdditionalGroupSpots, plural, one {other person have reserved a spot} other {others have reserved spots}} for this upcoming event"
          values={values}
        />
      )
    }
    return (
      <F
        defaultMessage="Hi, {groupLeaderName}! {numAdditionalGroupSpots} {numAdditionalGroupSpots, plural, one {person has reserved a spot} other {people have reserved spots}} for this upcoming event"
        values={values}
      />
    )
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <Typography variant="h2">
        <F defaultMessage="Group details" />
      </Typography>

      <GreetingsDiv>
        <p>{greetingsString()}</p>
      </GreetingsDiv>

      <Message type={MessageType.INFO}>
        <SpaceBetween>
          <div>
            <F
              defaultMessage="<strong>Reservation size</strong>"
              values={strongElement()}
            />
            &nbsp;
            <Tooltip
              placement="top"
              title="This is the number of group members you reserved spots for."
            >
              <Icon primary name="circle-question" />
            </Tooltip>
          </div>
          {max_group_capacity && (
            <F
              defaultMessage="(Max capacity: {maxGroupCapacity})"
              values={{
                maxGroupCapacity: max_group_capacity,
              }}
            />
          )}
        </SpaceBetween>
        <Input
          name="Reservations"
          type="number"
          value={desiredGroupSize}
          min={1}
          hint={
            underRegisteredCount ? (
              <ScaleUpAnimation>
                <F
                  defaultMessage="Can not be lower than {numClaimedGroupSpotsPlusLeaderIfNotCancelled}, the number of contacts in your group. Cancel contacts below to reduce the number of contacts."
                  values={{numClaimedGroupSpotsPlusLeaderIfNotCancelled}}
                />
              </ScaleUpAnimation>
            ) : overMaxCapacity ? (
              <ScaleUpAnimation>
                <F defaultMessage="This number exceeds the maximum capacity for this reservation." />
              </ScaleUpAnimation>
            ) : null
          }
          onChange={(e) => setDesiredGroupSize(parseInt(e.target.value, 10))}
          error={changeIsInvalid}
        />
        <LinearProgress
          variant="determinate"
          classes={{
            colorPrimary: classes.colorPrimary,
            bar: classes.bar,
            root: classes.root,
          }}
          value={
            (100 * numClaimedGroupSpotsPlusLeaderIfNotCancelled) /
            numAdditionalGroupSpotsPlusLeaderIfNotCancelled
          }
        />
        <CounterContainer>
          <CounterWithCircle>
            <Circle active />
            &nbsp;
            <F
              defaultMessage="<strong>{numClaimedGroupSpotsPlusLeaderIfNotCancelled}</strong> {numClaimedGroupSpotsPlusLeaderIfNotCancelled, plural, one {contact} other {contacts}}"
              values={{
                numClaimedGroupSpotsPlusLeaderIfNotCancelled,
                ...strongElement(),
              }}
            />
          </CounterWithCircle>
          <CounterWithCircle>
            <Circle />
            &nbsp;
            <F
              defaultMessage="<strong>{numUnclaimedGroupSpots}</strong> {numUnclaimedGroupSpots, plural, one {unknown} other {unknowns}}"
              values={{
                numUnclaimedGroupSpots,
                ...strongElement(),
              }}
            />
          </CounterWithCircle>
        </CounterContainer>
        {numUnclaimedGroupSpots === 0 && (
          <Typography variant="body2">
            All reservation spots have contacts. Great! To add more contacts,
            increase your reservation size.
          </Typography>
        )}
        {!(
          changeIsInvalid ||
          numAdditionalGroupSpotsPlusLeaderIfNotCancelled === desiredGroupSize
        ) && (
          <ScaleUpAnimation>
            <Button
              fluid
              onClick={async () => {
                setLoading(true)
                await updateGroupSize(desiredGroupSize)
                setLoading(false)
              }}
            >
              Update reservation size
            </Button>
          </ScaleUpAnimation>
        )}
      </Message>
    </>
  )
}

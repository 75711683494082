import {Carousel, FeedItem} from 'events/components'
import {defineMessages, useIntl} from 'util/i18n'

import api from 'data/api'
import flag from 'experiments/flag'
import {getOrganizationLandingPageUrl} from 'util/routing'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useAsync} from 'hooks/async'
import {useInView} from 'react-intersection-observer'

const maxLandingPages = 8

const messages = defineMessages({
  landingPageCarouselHeader: {
    defaultMessage: '{landingPageLabel}',
  },
})

const fallbackIcons = [
  'person-sign',
  'hands-heart',
  'hands-helping',
  'hand-heart',
]
// todo(kayvon): remove uses of these classnames and add more granular props to FeedItem for controlling these styles
const LandingPageOuterWrapper = styled.div`
  & .mbz-feed-item {
    width: 210px;
    height: 210px;
    vertical-align: middle;
    margin: ${styles.space.m};
    scroll-snap-align: center;
    white-space: normal;
  }

  & .mbz-feed-item-container {
    height: 100%;
  }
`

function LandingPageFeedItem({organization, landingPage}) {
  const intl = useIntl()

  const headline = intl.formatMessage(messages.landingPageCarouselHeader, {
    landingPageLabel: landingPage.short_display_name,
  })
  const landingPageUrl = getOrganizationLandingPageUrl(
    organization,
    landingPage,
    {utm_source: 'landing_page_carousel'}
  )
  //hashes the name of the title of a landing page to an index within the
  // fallback icons, for use if no icon_name is provided
  const fallBackIndex =
    landingPage.title
      .split('')
      .reduce((hash, current) => current.charCodeAt(0) + hash, 0) %
    fallbackIcons.length

  const iconName = landingPage.icon_name || fallbackIcons[fallBackIndex]

  return (
    <FeedItem
      aria-label="test"
      headline={headline}
      linkTo={landingPageUrl}
      isCompactFeedItem
      showCompactDivider
      centerHeadline
      iconName={iconName}
    />
  )
}

export default function LandingPageCarousel({organization, shouldDelayLoad}) {
  const [ref, inView] = useInView({
    rootMargin: '100px 0px',
    triggerOnce: true,
  })

  const shouldFetchAndRender =
    (!shouldDelayLoad || inView) && !flag.isActive('kill_org_carousels')

  const landingPageSuggestionsState = useAsync(async () => {
    if (!shouldFetchAndRender) return {landing_pages: []}
    return api.getLandingPageSuggestions(organization.slug)
  }, [organization.slug])
  const isLoading = landingPageSuggestionsState.loading
  const hasError = landingPageSuggestionsState.error

  const landingPages = (
    landingPageSuggestionsState.result?.landing_pages || []
  ).slice(0, maxLandingPages)

  if (!isLoading && (hasError || !landingPages.length)) {
    return <div ref={shouldDelayLoad ? ref : null} />
  }

  return (
    <div ref={shouldDelayLoad ? ref : null}>
      <LandingPageOuterWrapper>
        <Carousel
          carouselId={'Landing Page Carousel'}
          header={'Discover causes'}
          isLoading={isLoading}
          items={landingPages}
          itemRenderFn={(item) => (
            <LandingPageFeedItem
              organization={organization}
              landingPage={item}
            />
          )}
          itemKeyFn={(item) => item.id}
          dontLinkHeader
          alwaysShowActionItem
        />
      </LandingPageOuterWrapper>
    </div>
  )
}

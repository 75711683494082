import {
  AccessibilityFeature,
  getAccessibilityFeatureDisplayName,
} from 'app/enums'
import {
  Autocomplete,
  Button,
  CheckboxField,
  DatePicker,
  Form,
  FormItem,
  Icon,
  Input,
  InputGroup,
  LocationSearchInput,
  RadioGroup,
  TagSearchDropdown,
  Typography,
} from 'components'
import {F, createIntl, defineMessages} from 'util/i18n'
import {
  isValidLatLon,
  locationToFilterParamsPartial,
  zipToMaybeLatLon,
} from 'util/geo'
import {maybeEndOfDay, maybeMomentFromMaybeISOString} from 'util/time'

import {Component} from 'react'
import {EMPTY_FILTER_PARAMS} from 'app/constants'
import EventTypeMultiselect from './EventTypeMultiselect'
import OrganizationMultiselect from './OrganizationMultiselect'
import analytics from 'analytics'
import {isFiltered} from 'util/feed'
import {logError} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const messages = defineMessages({
  accessibilityFeaturesPlaceholder: {defaultMessage: 'Any'},
  tagsPlaceholder: {defaultMessage: 'Any'},
  locationPlaceholder: {defaultMessage: 'Location'},
  searchPlaceholder: {defaultMessage: 'Search'},
  searchInputLabel: {defaultMessage: 'Near'},
  date: {defaultMessage: 'Date'},
  dateRange: {defaultMessage: 'Date range'},
})

const ButtonContainer = styled.div`
  margin-top: ${styles.space.m};
`
const InlineButtonContainer = styled.div`
  margin-right: ${styles.space.m};
  display: inline-block;
`
const FormWrapper = styled.div`
  max-width: 26rem;
  white-space: normal; // Reset problematic outer FilterPanel setting
`
const TitleWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

class FilterPanelForm extends Component {
  state = {
    filterParams: this.props.filterParams,
    showSingleDatePicker: !this.props.filterParams.startDate,
    hasInvalidZipcode: false,
    lastBadZipcode: null,
    selectedTags: this.props.filterTags || [],
    accessibleOnlyChecked: this.props.filterParams.accessibleOnly || false,
  }

  setFilterParams = (update) => {
    this.setState({filterParams: {...this.state.filterParams, ...update}})
  }

  clearAllFilterParams = (e) => {
    e.preventDefault()
    // reset all filters except eventCampaign

    const resetFilterParams = {
      ...EMPTY_FILTER_PARAMS,
      eventCampaign: this.props.filterParams.eventCampaign,
      showAllEvents: true,
    }
    // submit reset filters
    this.props.onSubmit(resetFilterParams)
    analytics.track('filterPanel.clearAllFiltersClick', {})
  }

  clearLocationFilterParams = () => {
    const filterParams = {
      ...this.props.filterParams,
      zip: '',
      address: null,
      lat: null,
      lon: null,
      maxDist: Infinity,
    }
    this.setState({filterParams})
  }

  validateZipcode = () => {
    const {zip} = this.state.filterParams
    if (!zip) return
    const hasInvalidZipcode = zip.length !== 5
    this.setState({hasInvalidZipcode})
  }

  hideZipcodeValidation = () => this.setState({hasInvalidZipcode: false})

  maybeGeocodeZip = (zip) => {
    if (zip.length === 5) {
      zipToMaybeLatLon(zip).then(
        (maybeLatLon) => {
          this.setFilterParams({
            lat: maybeLatLon ? maybeLatLon.lat : null,
            lon: maybeLatLon ? maybeLatLon.lon : null,
          })
        },
        (geocodingError) => {
          logError(geocodingError)
          this.setState({lastBadZipcode: zip})
        }
      )
    }
  }

  onSelectedTagsChanged = (selectedTags) => {
    this.setState({selectedTags})
    // $FlowFixMe we know these tags have ids; TODO(jared) consider breaking out a separate type
    this.setFilterParams({
      tagIds: selectedTags.filter((tag) => !!tag.id).map((tag) => tag.id),
    })
  }

  onLocationSelect = ({addressOneLiner, location}) =>
    this.setFilterParams(
      locationToFilterParamsPartial({addressOneLiner, location})
    )

  onAccessibilityLevelChanged = (value) => {
    if (value !== this.state.accessibleOnlyChecked) {
      this.setState({accessibleOnlyChecked: value})
      this.setFilterParams({accessibleOnly: value})
    }
  }

  onVirtualChanged = (value) => {
    // The api handles null and false differently for isVirtual
    // false means don't include virtual events
    // null means include virtual and in person events
    const isVirtualQuery = value || null
    this.setFilterParams({isVirtual: isVirtualQuery})
  }

  render() {
    const {
      eventTypeOptions,
      shownOrgs,
      orgTagIds,
      shouldShowTagsFilter,
      onCancel,
    } = this.props
    const {
      filterParams,
      showSingleDatePicker,
      hasInvalidZipcode,
      lastBadZipcode,
      selectedTags,
    } = this.state
    const {
      eventTypes,
      zip,
      address,
      date,
      startDate,
      endDate,
      selectedOrgs,
      lat,
      lon,
    } = filterParams
    const momentDate = maybeMomentFromMaybeISOString(date)
    const momentStartDate = maybeMomentFromMaybeISOString(startDate)
    const momentEndDate = maybeMomentFromMaybeISOString(endDate)

    const hasFiltersSelected = isFiltered(filterParams)
    const intl = createIntl()

    const isGeoFilteredWithoutAddress =
      !address && !zip && isValidLatLon({lat, lon})

    return (
      <FormWrapper>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            this.props.onSubmit(filterParams)
          }}
        >
          <TitleWrapper>
            <InlineButtonContainer>
              <Typography variant="h2">
                <F defaultMessage="Filter events" />
              </Typography>
            </InlineButtonContainer>
            <Button padding="none" link onClick={onCancel} type="button">
              <F defaultMessage="Cancel" />
            </Button>
          </TitleWrapper>
          <Input
            autoComplete="off"
            icon="search"
            name="search"
            onChange={(e) => {
              this.setFilterParams({q: e.currentTarget.value})
            }}
            placeholder={intl.formatMessage(messages.searchPlaceholder)}
            type="search"
            value={filterParams.q}
          />
          <EventTypeMultiselect
            eventTypeOptions={eventTypeOptions}
            onChange={(e) => {
              this.setFilterParams({
                eventTypes: e,
              })
            }}
            sectionLabel={<F defaultMessage="Type" />}
            selectedTypes={eventTypes}
          />
          <CheckboxField
            type="checkbox"
            name="is_virtual"
            label={<F defaultMessage="Show virtual events only" />}
            sectionLabel={<F defaultMessage="Virtual" />}
            onChange={({value}) => this.onVirtualChanged(value)}
            checked={filterParams.isVirtual || false}
          />
          {isGeoFilteredWithoutAddress ? (
            <FormItem label={<F defaultMessage="Near" />}>
              <Typography variant="body2">
                <Icon name="map-marker-alt" />
                <F defaultMessage="Map Location" />
                <Button link onClick={this.clearLocationFilterParams}>
                  <F defaultMessage="Clear" />
                </Button>
              </Typography>
            </FormItem>
          ) : (
            <LocationSearchInput
              showGetLocation
              defaultValue={address || zip}
              onSelect={this.onLocationSelect}
              onSubmit={() => this.props.onSubmit(filterParams)}
              placeholder={intl.formatMessage(messages.locationPlaceholder)}
              searchInputLabel={intl.formatMessage(messages.searchInputLabel)}
            />
          )}
          {(hasInvalidZipcode || (zip && zip === lastBadZipcode)) && (
            <Typography
              variant="subtitle1"
              style={{color: styles.colors.error200}}
            >
              <F defaultMessage="Enter a valid 5-digit U.S. zipcode." />
            </Typography>
          )}
          <FormItem label={<F defaultMessage="Date" />}>
            {showSingleDatePicker ? (
              <DatePicker
                value={momentDate}
                disablePast
                label=""
                onChange={(date) => {
                  this.setFilterParams({
                    date,
                    startDate: null,
                    endDate: null,
                  })
                }}
              />
            ) : (
              <InputGroup>
                <DatePicker
                  disablePast
                  label={<F defaultMessage="Start date" />}
                  required
                  value={momentStartDate}
                  onChange={(startDate) =>
                    this.setFilterParams({
                      date: null,
                      startDate,
                    })
                  }
                />
                <DatePicker
                  disablePast
                  label={<F defaultMessage="End date" />}
                  required
                  value={momentEndDate}
                  onChange={(endDate) =>
                    this.setFilterParams({
                      date: null,
                      endDate: maybeEndOfDay(endDate),
                    })
                  }
                />
              </InputGroup>
            )}
            <RadioGroup
              row
              name="date_or_date_range"
              options={[
                {text: intl.formatMessage(messages.date), value: true},
                {text: intl.formatMessage(messages.dateRange), value: false},
              ]}
              value={showSingleDatePicker}
              onChange={(name, value) =>
                this.setState({showSingleDatePicker: value})
              }
            />
          </FormItem>
          {shownOrgs && shownOrgs.size > 1 && (
            <OrganizationMultiselect
              shownOrgs={shownOrgs}
              selectedOrgs={selectedOrgs || []}
              onChange={(selection) =>
                this.setFilterParams({selectedOrgs: selection})
              }
              sectionLabel={<F defaultMessage="Campaign or organization" />}
            />
          )}
          {shouldShowTagsFilter && (
            <TagSearchDropdown
              selectedTags={selectedTags}
              orgTagIds={orgTagIds}
              onSelectedTagsChanged={this.onSelectedTagsChanged}
              placeholder={
                selectedTags.length
                  ? undefined
                  : intl.formatMessage(messages.tagsPlaceholder)
              }
              sectionLabel={<F defaultMessage="Tags" />}
            />
          )}
          <CheckboxField
            type="checkbox"
            name="accessibility_level"
            label={<F defaultMessage="Show ADA accessible events only" />}
            sectionLabel={<F defaultMessage="Accessibility level" />}
            onChange={({value}) => this.onAccessibilityLevelChanged(value)}
            checked={this.state.accessibleOnlyChecked}
          />
          <Autocomplete
            multiple
            name="accessibility_features"
            value={filterParams.accessibilityFeatures}
            options={Object.values(AccessibilityFeature).map(
              // $FlowFixMe - Flow doesn't understand Object.values
              (af) => ({
                text: getAccessibilityFeatureDisplayName(af),
                value: af,
              })
            )}
            onChange={(_, {value}) =>
              this.setFilterParams({accessibilityFeatures: value})
            }
            placeholder={
              filterParams.accessibilityFeatures.length
                ? undefined
                : intl.formatMessage(messages.accessibilityFeaturesPlaceholder)
            }
            sectionLabel={<F defaultMessage="Accessibility features" />}
            hint={
              <F defaultMessage="Events that have all of your selected features appear in search results" />
            }
          />
          <ButtonContainer>
            <InlineButtonContainer>
              <Button type="submit">
                <F defaultMessage="Apply filters" />
              </Button>
            </InlineButtonContainer>
            {hasFiltersSelected ? (
              <Button
                padding="none"
                link
                onClick={this.clearAllFilterParams}
                type="button"
              >
                <F defaultMessage="Clear filters" />
              </Button>
            ) : (
              <Button padding="none" link onClick={onCancel} type="button">
                <F defaultMessage="Cancel" />
              </Button>
            )}
          </ButtonContainer>
        </Form>
      </FormWrapper>
    )
  }
}

export default FilterPanelForm

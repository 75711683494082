import {formatQueryString, getQueryParams} from 'util/url'

import {EVENT_CHAINING_ACTION_TO_ANALYTICS_NAME} from 'app/enums'
import {USER_ID_DIFFERS_PARAM} from 'app/constants'
import cv from 'util/clientVars'
import {maybeFilterParamsPartialToQueryString} from 'util/feed'

const SERVER_URL = new URL(window.__MLZ_SERVER_URL__)

const DEV_HOST_REGEX = /^(localhost|dev[.]mobilizeforcongress[.]com)/

// Simplified URL builder from a structured representation
export function buildUrl({origin, pathname, search, hash}) {
  const locationUrlPart = `${pathname}${search || ''}${hash || ''}`
  return new URL(`${origin}${locationUrlPart}`)
}

// NB(avi): We may want to revisit this soon when
// we do FE link generation more broadly, but this works for now.
export function toRelativeUrl(absoluteUrl) {
  const url = new URL(absoluteUrl)
  return url.pathname + url.search + url.hash
}

function getProtocol(host) {
  if (DEV_HOST_REGEX.test(host)) {
    return 'http:'
  }
  return 'https:'
}

function getOriginForHost(host) {
  return `${getProtocol(host)}//${host}`
}

function getOrgScopedUrl(
  org,
  {
    customDomain: customDomainLocationDescriptor,
    mainDomain: mainDomainLocationDescriptor,
  }
) {
  if (org.custom_domain) {
    return buildUrl({
      ...customDomainLocationDescriptor,
      origin: getOriginForHost(org.custom_domain),
    })
  }
  return buildUrl({
    ...mainDomainLocationDescriptor,
    origin: SERVER_URL.origin,
  })
}

// Links to join.mobilize.com/get-started in production,
// and staging.mobilize.us/organization/create in staging.
export function getOrgCreationUrl(utmParams) {
  if (cv.world === 'staging') {
    const paramString = utmParams ? formatQueryString(utmParams) : ''
    return buildUrl({
      origin: cv.main_feed_url,
      pathname: '/organization/create',
      search: paramString || '',
    })
  }
  return getMarketingSiteUrl('/get-started', utmParams)
}

export function getMarketingSiteUrl(pathname, utmParams) {
  const paramString = utmParams ? formatQueryString(utmParams) : ''
  return buildUrl({
    origin: cv.marketing_site_url,
    pathname: pathname || '',
    search: paramString || '',
  })
}

export function getCurrentDomainLogoutUrl({next} = {}) {
  return buildUrl({
    origin: window.location.origin,
    pathname: '/logout/',
    search: next ? formatQueryString({next}) : '',
  })
}

// Utility function to possibly append the USER_ID_DIFFERS_PARAM to a url if this is a cross-domain
// link and there is a User supplied. See api.middleware.logout_user_if_user_id_differs_middleware.
//
// If this needs to be used more broadly than just a few links in VolunteerNavMenu, then `user` will
// likely have to be made optional, and it's probably worth building it into link-generation in a
// more generalized way (possibly in the `Link` component itself, reading from a User set in the
// Redux store, or something along those lines)
export function appendUserIdDiffersParamIfCrossDomain(url, user) {
  if (url.origin !== window.location.origin) {
    const search = formatQueryString({
      ...getQueryParams(url.search),
      [USER_ID_DIFFERS_PARAM]: user.id,
    })
    return buildUrl({
      origin: url.origin,
      pathname: url.pathname,
      search,
    })
  }
  return url
}

// Utility function to get a url, prefixing the org slug for non-custom domains, and omitting for
// custom domains
function getPossiblyOrgSlugPrefixedUrl(org, locationDescriptor) {
  return getOrgScopedUrl(org, {
    customDomain: {
      ...locationDescriptor,
      pathname: locationDescriptor.pathname,
    },
    mainDomain: {
      ...locationDescriptor,
      pathname: `/${org.slug}${locationDescriptor.pathname}`,
    },
  })
}

// Utility function to get a url, prefixing `/dashboard/org-slug` to the provided pathname for
// non-custom domains, and `/dashboard` for custom domains
function getPossiblyDashboardOrgSlugPrefixedUrl(org, locationDescriptor) {
  return getOrgScopedUrl(org, {
    customDomain: {
      ...locationDescriptor,
      pathname: `/dashboard${locationDescriptor.pathname}`,
    },
    mainDomain: {
      ...locationDescriptor,
      pathname: `/dashboard/${org.slug}${locationDescriptor.pathname}`,
    },
  })
}

// Common-case utility where all we care about is the pathname suffix
function buildGetterForOrgDashboardByPathnameSuffix(pathnameSuffix) {
  return (org) =>
    getPossiblyDashboardOrgSlugPrefixedUrl(org, {
      pathname: `/${pathnameSuffix}`,
    })
}

export function getFeedUrl(
  organization,
  filterParamsPartial,
  initialQueryParams = {}
) {
  return organization
    ? getOrganizationFeedUrl(
        organization,
        filterParamsPartial,
        initialQueryParams
      )
    : getMobilizeFeedUrl(filterParamsPartial, initialQueryParams)
}

/** Volunteer-facing org-scoped urls */

// Event feed map
export function getOrganizationFeedMapUrl(
  org,
  filterParamsPartial,
  initialQueryParams = {}
) {
  if (org.is_mobilize) {
    return getMobilizeFeedMapUrl(filterParamsPartial, initialQueryParams)
  }
  const search = maybeFilterParamsPartialToQueryString(
    filterParamsPartial,
    initialQueryParams
  )
  return getPossiblyOrgSlugPrefixedUrl(org, {pathname: '/map/', search})
}

// Event feed
export function getOrganizationFeedUrl(
  org,
  filterParamsPartial,
  initialQueryParams = {}
) {
  if (org.is_mobilize) {
    return getMobilizeFeedUrl(filterParamsPartial, initialQueryParams)
  }
  const search = maybeFilterParamsPartialToQueryString(
    filterParamsPartial,
    initialQueryParams
  )
  return getPossiblyOrgSlugPrefixedUrl(org, {pathname: '/', search})
}

// Landing page
export function getOrganizationLandingPageUrl(org, landingPage, utmParams) {
  const paramString = utmParams ? formatQueryString(utmParams) : ''
  if (org.is_mobilize) {
    return buildUrl({
      origin: SERVER_URL.origin,
      pathname: `/events/${landingPage.slug}/`,
      search: paramString || '',
    })
  }
  return getPossiblyOrgSlugPrefixedUrl(org, {
    pathname: `/events/${landingPage.slug}/`,
    search: paramString || '',
  })
}

// Event detail
export function getOrganizationEventUrl(org, event, queryParams) {
  return getPossiblyOrgSlugPrefixedUrl(org, {
    pathname: `/event/${event.id}/`,
    search: formatQueryString(queryParams),
  })
}
export function getOrganizationEventByEventIdUrl(org, eventId, queryParams) {
  return getPossiblyOrgSlugPrefixedUrl(org, {
    pathname: `/event/${eventId}/`,
    search: formatQueryString(queryParams),
  })
}
// Host an event form
export function getOrganizationDefaultEventCampaignCreateEventUrl(org) {
  return getPossiblyOrgSlugPrefixedUrl(org, {pathname: '/event/create/'})
}

// Host interest form and campaign list
export function getOrganizationHostInterestUrl(org) {
  return getPossiblyOrgSlugPrefixedUrl(org, {pathname: '/host/'})
}

// Event campaign slug-scoped host form (for already-extant event campaigns)
export function getHostFormUrlFromEventCampaign(
  org,
  eventCampaign,
  queryParams
) {
  return getOrganizationEventCampaignHostFormUrl(
    org,
    eventCampaign,
    queryParams
  )
}
// Event campaign slug-scoped host form (for event campaigns in the process of being created).
// Only broken out from getHostFormUrlFromEventCampaign for type reasons
export function getHostFormUrlFromEventCampaignEditableFields(
  org,
  eventCampaign
) {
  return getOrganizationEventCampaignHostFormUrl(org, eventCampaign)
}
// Helper function for the above; not exported. Use with care.
function getOrganizationEventCampaignHostFormUrl(
  org,
  // Careful with the below type; e.g., an Organization would pass this type check but would produce
  // an incorrect url
  eventCampaign,
  queryParams
) {
  return getPossiblyOrgSlugPrefixedUrl(org, {
    pathname: `/c/${eventCampaign.slug}/event/create/`,
    search: formatQueryString(queryParams),
  })
}

/** Org-scoped dashboard urls */

// Org settings
export function getOrganizationDashboardSettingsUrl(org, hash) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: '/settings/',
    hash,
  })
}

export const getOrganizationDashboardGetStartedUrl = (org, queryParams) =>
  getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: '/get-started/',
    search: queryParams ? formatQueryString(queryParams) : '',
  })

// Event list
function buildOrgDashboardEventsUrlGetter(pathnameSuffix = '') {
  return (org, queryParams, prefix = 'events') =>
    getPossiblyDashboardOrgSlugPrefixedUrl(org, {
      pathname: `/${prefix}/${pathnameSuffix}`,
      search: queryParams ? formatQueryString(queryParams) : '',
    })
}

export const getOrganizationDashboardEventsUrl = buildOrgDashboardEventsUrlGetter()
export const getOrganizationDashboardPastEventsUrl = buildOrgDashboardEventsUrlGetter(
  'past/'
)
export const getOrganizationDashboardPromotedEventsUrl = buildOrgDashboardEventsUrlGetter(
  'promoted/'
)
export const getOrganizationDashboardAwaitingApprovalEventsUrl = buildOrgDashboardEventsUrlGetter(
  'awaiting-approval/'
)
export const getOrganizationDashboardAwaitingVerificationEventsUrl = buildOrgDashboardEventsUrlGetter(
  'awaiting-verification/'
)
export const getOrganizationDashboardDeletedEventsUrl = buildOrgDashboardEventsUrlGetter(
  'deleted/'
)
export const getOrganizationDashboardAllEventsUrl = buildOrgDashboardEventsUrlGetter(
  'all/'
)

// Event detail, create, edit
function buildOrgDashboardEventDetailUrlGetter(pathnameSuffix = '') {
  return (org, eventId) => {
    return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
      pathname: `/event/${eventId}/${pathnameSuffix}`,
    })
  }
}

export const getOrganizationDashboardEventDetailUrl = buildOrgDashboardEventDetailUrlGetter()
export const getOrganizationDashboardEventDetailAllUrl = buildOrgDashboardEventDetailUrlGetter(
  'all/'
)
export const getOrganizationDashboardEventDetailPastUrl = buildOrgDashboardEventDetailUrlGetter(
  'past/'
)

export function getOrganizationDashboardEventCreateUrl(org, queryParams) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: '/event/create/',
    search: queryParams ? formatQueryString(queryParams) : '',
  })
}
export function getOrganizationDashboardEventDuplicateUrl(event, org) {
  let params = {template_event: event.id}
  if (event.virtual_action_url) {
    params = {...params, flexible: null}
  }
  return getOrganizationDashboardEventCreateUrl(org, params)
}
export const getOrganizationDashboardEventHostCreateUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'event/host/'
)
export function getOrganizationDashboardEventEditUrl(org, event) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: `/event/${event.id}/edit/`,
  })
}
export function getOrganizationDashboardEventHostEditUrl(org, event) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: `/event/${event.id}/host/`,
  })
}
export function getOrganizationDashboardCampaignCreateUrl(org, queryParams) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: '/event-campaign/create/',
    search: queryParams ? formatQueryString(queryParams) : '',
  })
}

// Timeslot detail
// TODO(jared) reconcile TimeslotResponse vs InlineTimeslot. it looks like in the serializers that
// they are the same?
function buildOrgDashboardTimeslotDetailGetter(pathnameSuffix = '') {
  return (org, timeslot) =>
    getPossiblyDashboardOrgSlugPrefixedUrl(org, {
      pathname: `/timeslot/${timeslot.id}/${pathnameSuffix}`,
    })
}
export const getOrganizationDashboardTimeslotDetailUrl = buildOrgDashboardTimeslotDetailGetter()
export const getOrganizationDashboardTimeslotDetailRegisteredUrl = buildOrgDashboardTimeslotDetailGetter(
  'registered/'
)
export const getOrganizationDashboardTimeslotDetailConfirmedUrl = buildOrgDashboardTimeslotDetailGetter(
  'confirmed/'
)
export const getOrganizationDashboardTimeslotDetailUnknownUrl = buildOrgDashboardTimeslotDetailGetter(
  'unknown/'
)
export const getOrganizationDashboardTimeslotDetailCompletedUrl = buildOrgDashboardTimeslotDetailGetter(
  'completed/'
)
export const getOrganizationDashboardTimeslotDetailNoShowUrl = buildOrgDashboardTimeslotDetailGetter(
  'no-show/'
)
export const getOrganizationDashboardTimeslotDetailCancelledUrl = buildOrgDashboardTimeslotDetailGetter(
  'cancelled/'
)

// D.O. settings, event campaigns, etc.
export const getOrganizationDashboardEventCampaignListUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'event-campaigns/'
)
export const getOrganizationDashboardEventCampaignSettingsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'event-campaigns/settings/'
)
export function getOrganizationDashboardEventCampaignEditUrl(
  org,
  eventCampaign
) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: `/event-campaign/${eventCampaign.id}/`,
  })
}
export function getOrganizationDashboardEventCampaignCreateUrl(org) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: `/event-campaign/create/`,
  })
}

// VAN
export const getOrganizationDashboardVanSettingsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'settings/van/'
)

// Salesforce
export const getOrganizationDashboardSalesforceUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'salesforce/'
)

// Zoom
export const getOrganizationDashboardZoomUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'zoom/'
)

// Integrations
export const getOrganizationDashboardIntegrationsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'integrations/'
)

// Exports
export const getOrganizationDashboardExportsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'exports/'
)

// Volunteers
export const getOrganizationDashboardVolunteersUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'volunteers/'
)
export const getOrganizationDashboardBlockedVolunteersUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'volunteers/blocked/'
)
export function getOrganizationDashboardVolunteerDetailUrl(org, person) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: `/volunteer/${person.id}/`,
  })
}

// Promotions
export const getOrganizationDashboardPromotionsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'promotions/'
)
export const getOrganizationDashboardPromotionFiltersUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'promotions/filters/'
)
export const getOrganizationDashboardPromotersUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'promotions/my_promoters/'
)

// Users
export const getOrganizationDashboardUsersUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'users/'
)
export const getOrganizationDashboardUsersAdminUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'users/admin/'
)
export const getOrganizationDashboardUsersOrganizerUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'users/organizer/'
)
export const getOrganizationDashboardUsersTrustedHostUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'users/trusted_host/'
)
export const getOrganizationDashboardUsersHostUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'users/host/'
)
export const getOrganizationDashboardStatsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/'
)
export const getOrganizationDashboardStatsVolunteersUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/volunteers/'
)
export const getOrganizationDashboardStatsSourcesUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/sources/'
)
export const getOrganizationDashboardStatsProgramUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/program/'
)
export const getOrganizationDashboardStatsConversionUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/conversion/'
)
export const getOrganizationDashboardStatsPromotionsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/promotions/'
)
export const getOrganizationDashboardStatsTeamUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/team/'
)
export const getOrganizationDashboardStatsSubaccountsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/subaccounts/'
)

export const getOrganizationDashboardStatsIntegrationErrorsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'stats/integration-errors/'
)

// Custom signup fields (create, list, edit)
export const getOrganizationDashboardListCustomSignupFieldsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'custom-signup-fields/'
)
export const getOrganizationDashboardCreateCustomSignupFieldUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'custom-signup-fields/create/'
)
export function getOrganizationDashboardEditCustomSignupFieldUrl(
  org,
  customSignupField
) {
  return getPossiblyDashboardOrgSlugPrefixedUrl(org, {
    pathname: `/custom-signup-fields/${customSignupField.id}/edit/`,
  })
}

// Donation settings
export const getOrganizationDashboardDonationSettingsUrl = buildGetterForOrgDashboardByPathnameSuffix(
  'donation_settings/'
)

export function getOrganizationSelfServiceChildOrgCreateUrl(org) {
  return buildUrl({
    origin: SERVER_URL.origin,
    pathname: `/organization/${org.id}/create/`,
  })
}

/** Non-org scoped urls (only on the main domain) */

export function getMobilizeFeedUrl(
  filterParamsPartial,
  initialQueryParams = {}
) {
  const search = maybeFilterParamsPartialToQueryString(
    filterParamsPartial,
    initialQueryParams
  )
  return buildUrl({
    origin: SERVER_URL.origin,
    pathname: '/',
    search,
  })
}

export function getMobilizeFeedMapUrl(
  filterParamsPartial,
  initialQueryParams = {}
) {
  const search = maybeFilterParamsPartialToQueryString(
    filterParamsPartial,
    initialQueryParams
  )
  return buildUrl({
    origin: SERVER_URL.origin,
    pathname: '/map/',
    search,
  })
}

// User settings page
export function getUserSettingsUrl() {
  return buildUrl({origin: SERVER_URL.origin, pathname: '/me/settings/'})
}

export function getInternalDonateURL({
  orgId,
  action,
  eventId,
  userId = null,
  amount = null,
}) {
  const searchParams = {
    action: EVENT_CHAINING_ACTION_TO_ANALYTICS_NAME[action],
  }
  if (amount) {
    searchParams['amount'] = amount
  }

  if (userId) {
    searchParams['user_id'] = userId
  }
  const pathname = `/donate/${orgId}/${eventId}/`

  return buildUrl({
    origin: SERVER_URL.origin,
    pathname,
    search: formatQueryString(searchParams),
  })
}

// Facebook login page on the main domain for embedding
export function getEmbeddedFacebookLoginUrl(id) {
  return buildUrl({
    origin: SERVER_URL.origin,
    pathname: '/embed/login/facebook/',
    // `from` is the HTTP origin from which the request is coming
    // `id` gets included in the postMessage so that messages are only handled once
    search: formatQueryString({from: window.location.origin, id}),
  })
}

export function getSubtypedShortlink(shortlink, subtype) {
  const shortlinkUrl = new URL(shortlink)
  shortlinkUrl.pathname = `${shortlinkUrl.pathname}/${subtype}`
  return shortlinkUrl
}

/** Pathnames that exist on both custom and main domain */

export function getLoginPathname() {
  return '/login/'
}

export function getAboutPasswordlessPathname() {
  return '/about_passwordless/'
}

export function getEmailRegistrationPreviewPathname() {
  return '/_/email/event-registration/'
}

export function getReportEventUrl(event) {
  const pathname = `/report_event/${event.id}/`
  return getOrgScopedUrl(event.organization, {
    customDomain: {pathname},
    mainDomain: {pathname},
  })
}

/** Salesforce specific. **/

export function getSalesforceAuthCallbackURL() {
  return `${window.__MLZ_SERVER_URL__}/oauth/salesforce_auth_callback`
}

export function getSalesforceAuthURL(slug) {
  return `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
    cv.salesforce_client_id
  }&redirect_uri=${getSalesforceAuthCallbackURL()}&state=${slug}`
}

/** Zoom integration */

function getZoomAuthCallbackURL() {
  return buildUrl({
    origin: SERVER_URL.origin,
    pathname: '/oauth/zoom_auth_callback/',
  }).toString()
}

export function getZoomAuthURL(slug) {
  const queryParams = {
    response_type: 'code',
    client_id: cv.zoom_client_id,
    redirect_uri: getZoomAuthCallbackURL(),
    state: slug,
  }
  return `https://zoom.us/oauth/authorize${formatQueryString(queryParams)}`
}

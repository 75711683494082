import {Component} from 'react'
import CurrentUserContext from 'app/CurrentUserContext'
import FilterPanelButtons from './FilterPanelButtons'
import FilterPanelForm from './FilterPanelForm'
import {MaxWidthContainer} from 'components'
import analytics from 'analytics'
import api from 'data/api'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const FilterPanelOuter = styled.div`
  padding-top: ${styles.space.m};
  display: block;
  white-space: nowrap;
`

const FilterPanelInner = styled.div`
  cursor: pointer;
  display: inline-block;
  padding-bottom: ${styles.space.l};

  /* Setting a min-width here so that when toggling Date/Date range it
     doesn't jump around. */
  min-width: 316px;
`

class FilterPanel extends Component {
  static contextType = CurrentUserContext

  static defaultProps = {
    filterTags: [],
    orgTagIds: [],
  }
  state = {isFormOpen: false, eventTypeOptions: null}

  openForm = () => {
    // Since the trigger for opening the form is the element wrapping everything, make sure that
    // it's closed before taking any action, because this will receive every click on anything in
    // the filter panel
    if (this.state.isFormOpen) {
      return
    }
    this.setState({isFormOpen: true})
    analytics.track('filterPanel.opened', {
      org_name: this.props.organization && this.props.organization.name,
      org_slug: this.props.organization && this.props.organization.slug,
    })
  }

  closeForm = () => this.setState({isFormOpen: false})

  // use _isMounted to prevent a memory leak in the case that the component has already
  // unmounted by the time the fetch completes.
  _isMounted = false

  componentDidMount() {
    this.maybeLoadEventTypes()
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  maybeLoadEventTypes = async () => {
    if (!this.props.organization) return
    try {
      const eventTypeOptions = await api.getEventTypes(
        this.props.organization.slug
      )
      if (this._isMounted) {
        this.setState({eventTypeOptions})
      }
    } catch (e) {
      // No-op, error is already sent to Sentry from api.js
    }
  }

  onSubmit = (filterParams) => {
    analytics.trackFeedFiltered(
      'filterPanel',
      filterParams,
      this.props.organization.name,
      this.props.organization.slug
    )
    this.closeForm()
    this.props.onFilterChange(filterParams)
  }

  render() {
    const {
      filterParams,
      shownOrgs,
      filterTags,
      orgTagIds,
      organization,
    } = this.props
    const {isFormOpen, eventTypeOptions} = this.state

    // Only show the filter facet if there are tags to
    // show (either relevant tags or tag filters applied)
    const shouldShowTagsFilter = !!(
      filterTags && filterTags.length + orgTagIds.length > 0
    )

    return (
      <FilterPanelOuter>
        <MaxWidthContainer>
          {!isFormOpen && (
            <FilterPanelButtons
              organization={organization}
              orgTagIds={orgTagIds}
              onSubmit={this.onSubmit}
              filterParams={filterParams}
              onRequestExpandFilters={this.openForm}
            />
          )}
          <FilterPanelInner>
            {isFormOpen && (
              <FilterPanelForm
                filterParams={filterParams}
                shownOrgs={shownOrgs}
                eventTypeOptions={eventTypeOptions}
                filterTags={filterTags}
                orgTagIds={orgTagIds}
                shouldShowTagsFilter={shouldShowTagsFilter}
                onSubmit={this.onSubmit}
                onCancel={this.closeForm}
              />
            )}
          </FilterPanelInner>
        </MaxWidthContainer>
      </FilterPanelOuter>
    )
  }
}

export default FilterPanel

import {Button, Link, Typography} from 'components'

import {ACT_BLUE_URL_PREFIX} from 'app/enums'
import analytics from 'analytics'
import {formatDollarAmount} from 'util/string'
import {getInternalDonateURL} from 'util/routing'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useEffect} from 'react'

const ContentWrapper = styled.div`
  text-align: center;
  margin-bottom: ${styles.space.xl};
`

const FlexWrapAndJustifyCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const HeadingWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

const LargeBottomMargin = styled.div`
  margin-bottom: ${styles.space.l};
`

const LeftMargin = styled.div`
  padding-left: ${styles.space.m};
`

const DonateItemWrapper = styled.div`
  padding-right: ${styles.space.m};
  padding-bottom: ${styles.space.m};
  flex-basis: 40%;
`

export default function Donate({
  event,
  isOpen,
  action,
  userId,
  headerPreamble,
  additionalNotification,
}) {
  const {organization} = event

  // TODO(jared) move modal-related stuff to DonateModal + ChainingModalStepper
  useEffect(() => {
    if (isOpen) {
      analytics.trackDonateModalOpened({
        organization,
        event,
        action,
      })
    }
  }, [isOpen, action, event, organization])

  const trackDonateButtonClick = (amount) => {
    analytics.trackDonationModalDonateButtonClick({
      organization,
      event,
      action,
      amount,
    })
  }

  const renderDonationAmountButtons = () => {
    return (
      <>
        {organization.donation_url &&
          organization.donation_url.startsWith(ACT_BLUE_URL_PREFIX) && (
            <LargeBottomMargin data-testid="act_blue_disclaimer">
              If you’ve saved your payment information with ActBlue Express,
              your donation will go through immediately.
            </LargeBottomMargin>
          )}
        {organization.donation_amounts.map((amount, i) => (
          <DonateItemWrapper key={i}>
            <Link
              plain
              forceAnchorTag
              to={getInternalDonateURL({
                orgId: organization.id,
                eventId: event.id,
                userId: userId,
                action: action,
                amount: amount,
              })}
              target="_blank"
            >
              <Button
                type="button"
                onClick={() => trackDonateButtonClick(amount)}
                fluid
              >{`${formatDollarAmount(amount)}`}</Button>
            </Link>
          </DonateItemWrapper>
        ))}
        {renderSingleDonationButton('Other')}
      </>
    )
  }

  const renderSingleDonationButton = (buttonText) => {
    return (
      <DonateItemWrapper key="other">
        <Link
          to={getInternalDonateURL({
            orgId: organization.id,
            eventId: event.id,
            userId: userId,
            action: action,
          })}
          forceAnchorTag
          target="_blank"
        >
          <Button
            type="button"
            onClick={() => trackDonateButtonClick(null)}
            fluid
          >
            {buttonText}
          </Button>
        </Link>
      </DonateItemWrapper>
    )
  }

  const {
    name: owningOrgName,
    donation_custom_org_name: customDonationOrgName,
  } = organization
  const orgNameForDisplay = customDonationOrgName
    ? customDonationOrgName
    : owningOrgName

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Typography variant="h1">
          {headerPreamble ? headerPreamble : ''}
          Would you like to donate to {orgNameForDisplay}?
        </Typography>
      </HeadingWrapper>
      {additionalNotification && (
        <Typography variant="body1" center>
          {additionalNotification}
        </Typography>
      )}
      <LargeBottomMargin>
        <LeftMargin>
          <FlexWrapAndJustifyCenter>
            {organization.donation_amounts.length > 0
              ? renderDonationAmountButtons()
              : renderSingleDonationButton('Donate')}
          </FlexWrapAndJustifyCenter>
        </LeftMargin>
      </LargeBottomMargin>
    </ContentWrapper>
  )
}

import {EventSuggestionType} from 'app/enums'
import {isEqual} from 'util/common'

const EVENT_SUGGESTION_FEED_DESTINATION_TYPES = new Set([
  EventSuggestionType.EVENT_TO_FEED_FILTER_PARAMS,
  EventSuggestionType.ORGANIZATION_TO_FEED_FILTER_PARAMS,
])

export default function eventSuggestionsReducer(state, action) {
  switch (action.type) {
    case 'added': {
      const eventSuggestions = [...state.eventSuggestions]
      const errors = [...state.errors]
      if (
        EVENT_SUGGESTION_FEED_DESTINATION_TYPES.has(
          action.data.eventSuggestion.suggestion_type
        ) &&
        eventSuggestions.some(
          (es, index) =>
            index !== action.data.index &&
            es?.suggestion_type === action.data.eventSuggestion.suggestion_type
        )
      ) {
        errors[action.data.index] = 'You can only suggest one feed at a time'
        return {...state, errors}
      }

      if (
        eventSuggestions.some((es) => isEqual(es, action.data.eventSuggestion))
      ) {
        errors[action.data.index] = 'You can’t suggest the same event twice'
        return {...state, errors}
      }

      errors[action.data.index] = null
      eventSuggestions[action.data.index] = action.data.eventSuggestion
      return {
        errors,
        eventSuggestions,
        pendingCount: state.pendingCount + 1,
      }
    }
    case 'loaded':
      return {
        ...state,
        pendingCount: state.pendingCount - 1,
      }
    case 'errored': {
      const eventSuggestions = [...state.eventSuggestions]
      const errors = [...state.errors]
      eventSuggestions[action.data.index] = null
      errors[action.data.index] = action.data.error
      return {
        errors,
        eventSuggestions,
        pendingCount: state.pendingCount - 1,
      }
    }
    case 'cleared':
      return {
        ...state,
        errors: state.errors.map((e, index) =>
          index === action.data.index ? null : e
        ),
      }
    case 'removed': {
      const eventSuggestions = [...state.eventSuggestions]
      eventSuggestions[action.data.index] = null
      return {
        ...state,
        eventSuggestions,
      }
    }
    default:
      console.warn('unrecognized action type', action.type)
      return state
  }
}

import * as React from 'react'

import {
  Button,
  Icon,
  Link,
  Loader,
  Table,
  Tooltip,
  Typography,
} from 'components'

import {AttendanceStatus} from 'app/enums'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {F} from 'util/i18n'
import {createMailUrl} from 'util/url'
import {getGroupSignupCounts} from '../../util/groupSignup'
import {makeStyles} from '@material-ui/core/styles'
import styled from '@emotion/styled/macro'
import styles from '../../components/styles'
import {useSnackbar} from 'notistack'

const ComponentWrapper = styled.div`
  display: inline-block;
  height: 18rem;
  max-height: 18rem;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const IconDimOnHover = styled.p`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`
const TableWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding-bottom: 2rem;
`

const useStyles = makeStyles({
  table: {
    border: '0.1rem solid #dddddd',
    borderRadius: '0.25rem',
  },
})

export default function GroupSignupMembersList({
  participation,
  groupLeaderEmail,
  shareLink,
  onGroupLeaderCancel,
  onGroupMemberCancel,
  loading,
}) {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const {
    numUnclaimedGroupSpots,
    numAdditionalGroupSpotsPlusLeaderIfNotCancelled,
    numClaimedGroupSpotsPlusLeaderIfNotCancelled,
  } = getGroupSignupCounts(participation)

  const sortAlphabetically = (a, b) => {
    if (a.first_name < b.first_name) {
      return -1
    }
    if (a.first_name > b.first_name) {
      return 1
    }
    if (a.last_name < b.last_name) {
      return -1
    }
    if (a.last_name > b.last_name) {
      return 1
    }
    return 0
  }

  const cancelThisMember = async (memberParticipationId) => {
    await onGroupMemberCancel(memberParticipationId)
    // Snackbar happens too fast to catch the updated state after the API call,
    // so we've got to do the subtraction here
    const groupSizeWithoutCancelledMember =
      numAdditionalGroupSpotsPlusLeaderIfNotCancelled - 1
    snackbar.enqueueSnackbar(
      `Contact RSVP cancelled. Your reservation size is now ${groupSizeWithoutCancelledMember}. Cancelled members can be found at the bottom of your list.`,
      {
        variant: 'success',
      }
    )
  }

  return loading ? (
    <Loader />
  ) : (
    <ComponentWrapper>
      <HeaderWrapper>
        <Typography variant="h2">
          <F defaultMessage="Members" />
        </Typography>

        {numUnclaimedGroupSpots > 0 && (
          <Tooltip title="Click to copy link!">
            <CopyToClipboard text={shareLink.toString()}>
              <IconDimOnHover>
                <Icon
                  name="link"
                  secondary
                  style={{color: styles.colors.primary200}}
                />
                <Link to="">copy invite link</Link>
              </IconDimOnHover>
            </CopyToClipboard>
          </Tooltip>
        )}
      </HeaderWrapper>
      <TableWrapper className={classes.table}>
        <Table.Table stickyHeader overflowVisible>
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                Contacts ({numClaimedGroupSpotsPlusLeaderIfNotCancelled})
              </Table.Cell>
              <Table.Cell>RSVP</Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {participation.status_attendance_combined !==
            AttendanceStatus.CANCELLED ? (
              <Table.Row>
                <Table.Cell>
                  You (Group Leader) <br />
                  <Link
                    data-testid="group leader email"
                    to={createMailUrl(groupLeaderEmail)}
                    target="_blank"
                  >
                    {groupLeaderEmail}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    onClick={() =>
                      onGroupLeaderCancel(
                        participation.affiliation.slug,
                        participation.id
                      )
                    }
                    secondary
                    type="button"
                  >
                    Cancel
                  </Button>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {participation.group_members
              .filter(
                (group_member) =>
                  group_member.participation_status_attendance_combined !==
                  AttendanceStatus.CANCELLED
              )
              .sort(sortAlphabetically)
              .map((group_member) => {
                const isEmaillessMember = !group_member.email
                return (
                  <Table.Row key={group_member.id}>
                    <Table.Cell>
                      {group_member.first_name} {group_member.last_name} <br />
                      {!isEmaillessMember ? (
                        <Link
                          data-testid="group member email"
                          to={createMailUrl(group_member.email)}
                          target="_blank"
                        >
                          {group_member.email}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        secondary
                        type="button"
                        onClick={() =>
                          cancelThisMember(group_member.participation_id)
                        }
                      >
                        {' '}
                        Cancel
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            {participation.status_attendance_combined ===
            AttendanceStatus.CANCELLED ? (
              <Table.Row>
                <Table.Cell>
                  <i>You (Group Leader)</i>
                </Table.Cell>
                <Table.Cell>
                  <Typography variant="body2">
                    <i>
                      <F defaultMessage="Cancelled" />
                    </i>
                  </Typography>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {participation.group_members
              .filter(
                (group_member) =>
                  group_member.participation_status_attendance_combined ===
                  AttendanceStatus.CANCELLED
              )
              .sort(sortAlphabetically)
              .map((group_member) => {
                return (
                  <Table.Row key={group_member.id}>
                    <Table.Cell>
                      <i>
                        {group_member.first_name} {group_member.last_name}
                      </i>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="body2">
                        <i>
                          <F defaultMessage="Cancelled" />
                        </i>
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table.Table>
      </TableWrapper>
    </ComponentWrapper>
  )
}

import {RadioGroup, Typography} from 'components'

import {LocaleName} from 'app/enums'

/** Allows the selection of a single language. */
export default function PrimaryLocaleSelector({onChange, selectedLocale}) {
  return (
    <>
      <Typography variant="h3">Primary language</Typography>
      <RadioGroup
        label="Mobilize sends automated communications in this language."
        name="primary_locale"
        options={[
          {
            text: 'English',
            value: LocaleName.EN,
          },
          {
            text: 'Spanish',
            value: LocaleName.ES,
          },
        ]}
        value={selectedLocale}
        onChange={onChange}
      />
    </>
  )
}

import * as React from 'react'

import {Button, CardModal, Icon, Message} from 'components'

import AddGroupMembers from 'events/modals/content/AddGroupMembers'
import EditGroupSizeModal from 'dashboard/events/details/timeslot/EditGroupSizeModal'
import {F} from 'util/i18n'
import GroupInvite from 'events/modals/content/GroupInvite'
import GroupManagementModal from './GroupManagementModal'
import {ParticipationStatus} from 'app/enums'
import ViewGroupMembersModal from './ViewGroupMembersModal'
import {VolunteerScheduleDeepLinkType} from 'app/enums'
import flag from 'experiments/flag'
import {getGroupSignupCounts} from '../../util/groupSignup'
import {getQueryParams} from 'util/url'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
// $FlowFixMe: types need updating for latest react-router
import {useLocation} from 'react-router'
import {useSnackbar} from 'notistack'
import {useState} from 'react'

const MessageWrapper = styled.div`
  margin-top: 0rem;
  width: 100%;
  display: flex;
`

const GroupSignupInfoWrapper = styled.div`
  display: inline-block;
  margin-right: ${styles.space.s};
`
const VolManagementButtonsWrapper = styled.div`
  display: inline-block;
`
const InlineButtonWrapper = styled.div`
  display: inline-block;
`

function maybeGetDeepLinkType(search, participationId) {
  const queryParams = getQueryParams(search)
  if (Number(queryParams.manage_group) === participationId) {
    return VolunteerScheduleDeepLinkType.MANAGE_GROUP
  } else if (Number(queryParams.view_group_members) === participationId) {
    return VolunteerScheduleDeepLinkType.VIEW_GROUP_MEMBERS
  } else if (Number(queryParams.edit_group_size) === participationId) {
    return VolunteerScheduleDeepLinkType.EDIT_GROUP_SIZE
  } else if (Number(queryParams.add_group_members) === participationId) {
    return VolunteerScheduleDeepLinkType.ADD_GROUP_MEMBERS
  } else {
    return null
  }
}

export default function GroupSignupManagement({
  groupLeaderParticipation,
  groupLeaderUser,
  shareLink,
  updateParticipation,
  updateGroupMemberStatus,
  addGroupMemberSignup,
}) {
  const snackbar = useSnackbar()
  const {search} = useLocation()
  const maybeDeepLinkType = maybeGetDeepLinkType(
    search,
    groupLeaderParticipation.id
  )
  const [groupMemberModalOpen, setGroupMemberModalOpen] = useState(
    maybeDeepLinkType === VolunteerScheduleDeepLinkType.VIEW_GROUP_MEMBERS
  )
  const [editGroupSizeModalOpen, setEditGroupSizeModalOpen] = useState(
    maybeDeepLinkType === VolunteerScheduleDeepLinkType.EDIT_GROUP_SIZE
  )
  const [groupInviteModalOpen, setGroupInviteModalOpen] = useState(
    maybeDeepLinkType === VolunteerScheduleDeepLinkType.ADD_GROUP_MEMBERS
  )
  const [groupManagementModalOpen, setGroupManagementModalOpen] = useState(
    maybeDeepLinkType === VolunteerScheduleDeepLinkType.MANAGE_GROUP
  )

  const onGroupSizeChange = async (value) => {
    setEditGroupSizeModalOpen(false)

    const response = await updateParticipation(groupLeaderParticipation.id, {
      num_additional_reserved_participations: value.newCount - 1,
    })
    if (response && value.newCount) {
      snackbar.enqueueSnackbar('Changes saved', {variant: 'success'})
    }
  }

  const onGroupMemberCancel = async (memberParticipationId) => {
    try {
      updateGroupMemberStatus(
        groupLeaderParticipation.id,
        memberParticipationId,
        ParticipationStatus.CANCELLED
      )
    } catch (e) {
      snackbar.enqueueSnackbar(
        'We failed to update this group member signup status. Please try again.',
        {variant: 'error'}
      )
    }
  }

  const event = groupLeaderParticipation.event
  const {
    numAdditionalGroupSpots,
    numUnclaimedGroupSpots,
  } = getGroupSignupCounts(groupLeaderParticipation)

  function InlineButton({onClick, dataTestId, message, lastButton}) {
    return (
      <>
        <InlineButtonWrapper>
          <Button
            type="button"
            padding="none"
            onClick={onClick}
            link
            data-testid={dataTestId}
          >
            {message}
          </Button>
        </InlineButtonWrapper>
        {!lastButton && <> &middot; </>}
      </>
    )
  }

  const timeslotHasEnded = moment().isAfter(
    moment(groupLeaderParticipation.ends_at_utc)
  )
  const buttonsForBeforeTimeslotEnds = [
    {
      onClick: () => setEditGroupSizeModalOpen(true),
      dataTestId: 'edit group size',
      message: <F defaultMessage="Edit" />,
    },
    {
      onClick: () => setGroupInviteModalOpen(true),
      dataTestId: 'add group members',
      message: (
        <>
          {numUnclaimedGroupSpots > 0 ? (
            <F defaultMessage="Add members" />
          ) : (
            <F defaultMessage="Share link" />
          )}
        </>
      ),
    },
  ]
  const buttons = flag.isActive('enable_group_signup_modal')
    ? [
        {
          onClick: () => setGroupManagementModalOpen(true),
          dataTestId: 'group signup management',
          message: <F defaultMessage="Manage group" />,
        },
      ]
    : [
        {
          onClick: () => setGroupMemberModalOpen(true),
          dataTestId: 'view group size',
          message: <F defaultMessage="View group" />,
        },
        ...(timeslotHasEnded ? [] : buttonsForBeforeTimeslotEnds),
      ]

  return (
    <>
      <MessageWrapper>
        <Message
          data-testid="vol management container"
          children={[
            <div key={groupLeaderParticipation.id}>
              <GroupSignupInfoWrapper>
                <Icon name="users" />
                <F
                  defaultMessage="Signed up with {numAdditionalGroupSpots} others"
                  values={{
                    numAdditionalGroupSpots,
                  }}
                />
              </GroupSignupInfoWrapper>
              <VolManagementButtonsWrapper>
                {buttons.map(({onClick, dataTestId, message}, i) => (
                  <InlineButton
                    key={i}
                    onClick={onClick}
                    dataTestId={dataTestId}
                    message={message}
                    lastButton={i === buttons.length - 1}
                  />
                ))}
              </VolManagementButtonsWrapper>
            </div>,
          ]}
          noBottomMargin
        />
      </MessageWrapper>

      <GroupManagementModal
        participation={groupLeaderParticipation}
        isOpen={groupManagementModalOpen}
        onRequestClose={() => setGroupManagementModalOpen(false)}
        groupLeaderUser={groupLeaderUser}
        updateParticipation={updateParticipation}
        addGroupMemberSignup={addGroupMemberSignup}
        shareLink={shareLink}
        onGroupMemberCancel={onGroupMemberCancel}
      />

      <CardModal
        isOpen={groupInviteModalOpen}
        onRequestClose={() => setGroupInviteModalOpen(false)}
        data-testid="add members"
      >
        {numUnclaimedGroupSpots > 0 &&
        groupLeaderParticipation.group_invite_signature ? (
          <AddGroupMembers
            groupShareLink={shareLink}
            event={event}
            organization={event.organization}
            modalType="schedule"
            timeslotId={groupLeaderParticipation.timeslot_id}
            referringUserId={groupLeaderUser.user_id}
            referringParticipationId={groupLeaderParticipation.id}
            referringDataSignature={
              groupLeaderParticipation.group_invite_signature
            }
            numUnclaimedGroupSpots={numUnclaimedGroupSpots}
            onRequestClose={() => setGroupInviteModalOpen(false)}
          />
        ) : (
          <GroupInvite
            groupShareLink={shareLink}
            event={event}
            modalType="schedule"
          />
        )}
      </CardModal>

      {editGroupSizeModalOpen && (
        <EditGroupSizeModal
          volSchedule
          onRequestClose={() => setEditGroupSizeModalOpen(false)}
          onSubmit={(newCount) => {
            onGroupSizeChange({
              newCount,
            })
          }}
          groupSizeSignUpLimit={event.group_signup_size_limit}
          numAdditionalGroupSpots={numAdditionalGroupSpots}
          numUnclaimedGroupSpots={numUnclaimedGroupSpots}
          groupLeaderFirstName={groupLeaderUser.first_name}
          groupLeaderLastName={groupLeaderUser.last_name}
        />
      )}

      {groupMemberModalOpen && (
        <ViewGroupMembersModal
          groupLeaderParticipation={groupLeaderParticipation}
          groupLeaderUser={groupLeaderUser}
          onRequestClose={() => setGroupMemberModalOpen(false)}
          onGroupMemberCancel={onGroupMemberCancel}
        />
      )}
    </>
  )
}

import {Button} from 'components'
import api from 'data/api'
import callAhaEndpoint from 'dashboard/components/util'
import styles from 'components/styles'
import {useState} from 'react'

/**
 * Button used for Google login
 */
function GoogleLoginButton(props) {
  const [loading, setLoading] = useState(false)

  const propTypeCheck = (propType) => {
    if (propType === 'login' || propType === 'aha') {
      return true
    }
    return false
  }

  const _onClick = async (evt) => {
    evt.preventDefault()
    setLoading(true)
    const {onClick, onComplete, onError, type} = props

    if (typeof onClick === 'function') {
      onClick()
    }

    const submitRequestCode = async (authResult) => {
      // Define what happens after calling requestCode as a callback
      try {
        const apiMethod = propTypeCheck(type)
          ? api.loginWithGoogle
          : api.connectUserGoogle
        const mobilizeData = await apiMethod(authResult.code)
        if (type === 'aha') {
          await callAhaEndpoint(mobilizeData.user.id)
        }
        onComplete(mobilizeData)
      } catch (mobilizeError) {
        if (typeof onError === 'function') {
          let detailedError
          if (mobilizeError.json) {
            detailedError =
              typeof mobilizeError.json.error === 'string'
                ? mobilizeError.json.error
                : mobilizeError.json.error.message
          }
          onError(mobilizeError, detailedError)
        }
      }
      setLoading(false)
    }

    try {
      window.googleAuthClient.callback = (authResult) => {
        submitRequestCode(authResult)
      }
      await window.googleAuthClient.requestCode()
    } catch (googleError) {
      // TODO(jo): individually handle the ways the Google library can reject this promise, ie:
      // https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
      setLoading(false)
      if (typeof onError === 'function') {
        onError(
          window.googleAuthClient
            ? googleError
            : new Error(
                'The page is still loading. Try clicking again in a bit.'
              )
        )
      }
      return
    }
  }

  return (
    <Button
      loading={loading}
      onClick={_onClick}
      color={styles.colors.googleBlue}
      fluid={props.fluid}
      icon="google"
      iconPosition="left"
      iconFontSize="1.5rem"
      children={
        propTypeCheck(props.type)
          ? 'Continue with Google'
          : 'Link Google account'
      }
    />
  )
}

export default GoogleLoginButton

import {Link, Section} from 'components'

import {EMPTY_FILTER_PARAMS} from 'app/constants'
import {getOrganizationFeedUrl} from 'util/routing'

const feedFilteredByTag = (tagId, organization) => {
  const filterParams = {...EMPTY_FILTER_PARAMS, tagIds: tagId ? [tagId] : []}
  return getOrganizationFeedUrl(organization, filterParams)
}

export default function TagDetails({tags, organization}) {
  if (!tags || tags.length === 0) {
    return null
  }

  const links = tags.map((tag, index) => (
    <span key={index}>
      <Link to={feedFilteredByTag(tag.id, organization)}>{tag.name}</Link>
      {index < tags.length - 1 && ', '}
    </span>
  ))

  return <Section title="Tags">{links}</Section>
}

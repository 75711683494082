import styles, {fontSizeSmall} from 'components/styles'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {Link} from 'components'
import cv from 'util/clientVars'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import {useContext} from 'react'

const TosMessage = styled.div`
  ${fontSizeSmall}
  margin: ${styles.space.m} 0 ${styles.space.l} 0;
  text-align: center;
`

export default function TermsOfService({hasMultipleButtons}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)

  const shouldShowExtendedToS = orgFlagIsActive(
    currentOrganization,
    'enable_extended_mobilize_terms'
  )

  const smsTermsShortlink = 'https://mobilize.us/s/smsterms'

  if (shouldShowExtendedToS) {
    return (
      <TosMessage>
        <span>
          {' '}
          By clicking the {hasMultipleButtons ? 'buttons' : 'button'} above, you
          agree to the{' '}
        </span>
        <br />
        <Link to={cv.tos_url} popup>
          Terms of Service
        </Link>
        <span>, </span>
        <Link to={cv.privacy_policy_url} popup>
          Privacy Policy
        </Link>
        <span>, and </span>
        <Link to={smsTermsShortlink} popup>
          SMS Terms
        </Link>
        <span>
          . Message and data rates may apply. Message frequency varies. Text
          STOP to end, HELP for help.
        </span>
      </TosMessage>
    )
  }

  return (
    <TosMessage>
      <span>
        {' '}
        By clicking the {hasMultipleButtons ? 'buttons' : 'button'} above, you
        agree to the{' '}
      </span>
      <br />
      <Link to={cv.tos_url} popup>
        Terms of Service
      </Link>
      <span> and </span>
      <Link to={cv.privacy_policy_url} popup>
        Privacy Policy
      </Link>
      <span>.</span>
    </TosMessage>
  )
}

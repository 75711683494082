import {
  Button,
  FacebookLoginButton,
  Form,
  GoogleLoginButton,
  Input,
  MailboxImage,
  Message,
  Typography,
} from 'components'

import {Component} from 'react'
import DefaultThemeProvider from 'app/DefaultThemeProvider'
import {LoginMethod} from 'app/enums'
import analytics from 'analytics'
import api from 'data/api'
import cv from 'util/clientVars'
import {getQueryParams} from 'util/url'
import {maybeStringToMaybeInt} from 'util/string'
import {showGoogleLogin} from 'util/user'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {withRouter} from 'react-router'

const LoginComponentFlowState = {
  CHOOSE_LOGIN_METHOD: 1,
  EMAIL_CHOSEN: 2,
  EMAIL_SENT: 3,
}

const TopPaddedP = styled.p`
  padding-top: ${styles.space.m};
`

const CenteredDiv = styled.div`
  text-align: center;
`

const TopPaddedDiv = styled.div`
  padding-top: ${styles.space.m};
`

const GoogleLoginButtonWrapper = styled.div`
  padding-bottom: ${styles.space.s};
`

const NoWrapSpan = styled.span`
  white-space: nowrap;
`

const CardHeader = styled.div`
  padding-bottom: ${styles.space.m};
`

const EmailSentText = styled.div`
  font-size: ${styles.typography.fontSizeL};
  word-wrap: break-word;
`

const ErrorMessageWrapper = styled.div`
  padding-top: ${styles.space.s};
  padding-bottom: ${styles.space.s};
`

/**
 * Universal login modal.
 */
class LoginComponent extends Component {
  state = {
    errorMessage: null,
    loginComponentFlowState: LoginComponentFlowState.CHOOSE_LOGIN_METHOD,
    email: this.props.email,
    getLinkButtonDisabled: false,
  }

  onChange = (evt) => {
    const {name, value} = evt.currentTarget
    this.setState({[name]: value})
  }

  onFormSubmit = (e) => {
    e.preventDefault()
    // Disable the link button until the async call returns,
    // so that the user will not request multiple tokens
    this.setState({getLinkButtonDisabled: true})
    analytics.trackLoginRequested({
      email: this.state.email,
      login_method: LoginMethod.MAGIC_LINK,
    })
    this.requestLoginLink()
  }

  shouldUseLoginCode = () => {
    const queryParams = getQueryParams(this.props.location.search)
    return !!maybeStringToMaybeInt(queryParams.use_login_code)
  }

  extractAhaRedirect = () => {
    const queryParams = getQueryParams(this.props.location.search)
    return queryParams['from'] === 'aha' ? true : false
  }

  loginType = () => {
    const fromAha = this.extractAhaRedirect()
    return fromAha ? 'aha' : 'login'
  }

  requestLoginLink = async () => {
    try {
      const {search, pathname} = this.props.location
      let maybeNext = getQueryParams(search).next
      const maybeEmail = this.state.email || ''
      // If we have a "next" param, pass it to the requested OTT - otherwise, stick with the current pathname.
      if (this.extractAhaRedirect()) {
        maybeNext = 'aha'
      }
      await api.requestLoginLink(
        maybeEmail,
        maybeNext || pathname,
        this.shouldUseLoginCode()
      )
    } catch (e) {
      this.setState({
        errorMessage: (e && e.message) || 'Could not send login link',
        getLinkButtonDisabled: false,
      })
      return
    }
    this.setState({
      loginComponentFlowState: LoginComponentFlowState.EMAIL_SENT,
      errorMessage: null,
      getLinkButtonDisabled: false,
    })
  }

  render() {
    const {onGoogleConnected, onFacebookConnected, headerText} = this.props
    const {
      getLinkButtonDisabled,
      errorMessage,
      loginComponentFlowState,
      email,
    } = this.state

    return (
      <DefaultThemeProvider>
        {loginComponentFlowState ===
          LoginComponentFlowState.CHOOSE_LOGIN_METHOD && (
          <CenteredDiv>
            <CardHeader>
              <Typography variant="h4" center>
                {headerText || `Log in to ${cv.product_name}`}
              </Typography>
            </CardHeader>
            {showGoogleLogin() && (
              <GoogleLoginButtonWrapper>
                <GoogleLoginButton
                  onError={(error) =>
                    this.setState({errorMessage: error.message})
                  }
                  onComplete={onGoogleConnected}
                  fluid
                  type={this.loginType()}
                />
              </GoogleLoginButtonWrapper>
            )}
            <FacebookLoginButton
              type={this.loginType()}
              onComplete={onFacebookConnected}
              onError={(error) => this.setState({errorMessage: error.message})}
              fluid
            />
            <TopPaddedP>or</TopPaddedP>
            <Button
              fluid
              secondary
              onClick={() =>
                this.setState({
                  loginComponentFlowState: LoginComponentFlowState.EMAIL_CHOSEN,
                })
              }
            >
              {this.shouldUseLoginCode()
                ? 'Get a login code'
                : 'Get a magic link'}
            </Button>
          </CenteredDiv>
        )}
        {loginComponentFlowState === LoginComponentFlowState.EMAIL_CHOSEN && (
          <CenteredDiv>
            <CardHeader variant="h4">
              {this.shouldUseLoginCode()
                ? 'Log in via a code sent to your email'
                : 'Log in via a magic link sent to your email'}
            </CardHeader>
            {/* This form is used only to enable HTML5 form validation on the input */}
            <Form onSubmit={this.onFormSubmit}>
              <Input
                type="email"
                name="email"
                onChange={this.onChange}
                value={email || ''}
                label="Email"
                fluid
                required
              />
              <TopPaddedDiv>
                <Button fluid disabled={getLinkButtonDisabled}>
                  <NoWrapSpan>
                    {this.shouldUseLoginCode()
                      ? 'Send login code'
                      : 'Send magic link'}
                  </NoWrapSpan>
                </Button>
              </TopPaddedDiv>
            </Form>
          </CenteredDiv>
        )}
        {loginComponentFlowState === LoginComponentFlowState.EMAIL_SENT && (
          <CenteredDiv>
            <Typography variant="h1">Sent!</Typography>
            <MailboxImage />
            <EmailSentText>
              Check your email: <strong>{email}</strong>
            </EmailSentText>
          </CenteredDiv>
        )}
        {errorMessage && (
          <ErrorMessageWrapper>
            <Message
              type="error"
              header="Login request failed"
              list={[errorMessage]}
            />
          </ErrorMessageWrapper>
        )}
      </DefaultThemeProvider>
    )
  }
}

export default withRouter(LoginComponent)

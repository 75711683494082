import styles, {
  boxShadowLight,
  getBorderRadiusFromProps,
  mediaMinWidthSmall,
} from 'components/styles'

import {AttendanceStatus} from 'app/enums'
import {F} from 'util/i18n'
import {Tooltip} from 'components'
import UpcomingEventsLinks from './UpcomingEventsLinks'
import {isRegistrationOnlyEvent} from 'util/event'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'

const CardWrapper = styled.div`
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.s};
  margin-left: auto;
  margin-right: auto;
  background-color: ${styles.colors.white};
  ${getBorderRadiusFromProps}
  ${boxShadowLight}
`

const StatsContainer = styled.div`
  border-bottom: 1px solid ${styles.colors.transparentGrey};
  display: flex;
  flex-direction: column;
  padding: ${styles.space.l} ${styles.space.m};
  ${mediaMinWidthSmall(`
    flex-direction: row;
    justify-content: space-around;
  `)}
`

const Stat = styled.div`
  .stat-icon {
    margin-right: ${styles.space.s};
  }
  ${mediaMinWidthSmall(`
    border-right: 1px solid ${styles.colors.transparentGrey};
    padding-left: ${styles.space.s};
    padding-right: ${styles.space.m};
    &:last-of-type {
        border-right: unset;
    }
  `)}
`

const MoreActionsContainer = styled.div`
  padding: ${styles.space.l};
  ${mediaMinWidthSmall(`
    display: flex;
    justify-content: center;

    div {
      margin-left: ${styles.space.m}
    }
  `)}
`

const HasHelpText = styled.span`
  border-bottom: 1px dotted;
  margin-right: 0;
  padding-bottom: ${styles.space.xs};
`

const SROnly = styled.div`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export default function ImpactStats({
  organization,
  participations,
  referredUserCount,
}) {
  const countActions = (participations) => {
    // filter the events based on 'completed', 'registered', and 'confirmed'
    const attendedActions = participations.filter((participation) =>
      [
        AttendanceStatus.COMPLETED,
        AttendanceStatus.REGISTERED,
        AttendanceStatus.CONFIRMED,
      ].includes(participation.status_attendance_combined)
    )

    const affiliationIds = []
    let minutesVolunteered = 0.0
    attendedActions.forEach((action) => {
      // count the unique affiliations
      if (!affiliationIds.includes(action.affiliation.id)) {
        affiliationIds.push(action.affiliation.id)
      }
    })

    const completedActions = attendedActions.filter(
      (participation) =>
        participation.status_attendance_combined === AttendanceStatus.COMPLETED
    )

    completedActions.forEach((action) => {
      // only calculate the hours volunteered for participations in shifted events
      if (!isRegistrationOnlyEvent(action.event)) {
        const endTime = moment(
          action.volunteer_check_out
            ? action.volunteer_check_out
            : action.ends_at_tz
        )
        const startTime = moment(
          action.volunteer_check_in
            ? action.volunteer_check_in
            : action.starts_at_tz
        )

        const duration = endTime.diff(startTime, 'minutes')
        minutesVolunteered += duration
      }
    })

    // Return volunteer time in hours, rounded to one decimal place
    const hoursVolunteered = Math.round(minutesVolunteered / 6.0) / 10

    return {
      attendedActions: attendedActions.length,
      affiliatedOrgs: affiliationIds.length,
      hoursVolunteered,
    }
  }

  const data = countActions(participations)

  if (
    referredUserCount === 0 &&
    data.attendedActions === 0 &&
    data.affiliatedOrgs === 0 &&
    data.hoursVolunteered === 0
  ) {
    return null
  }

  return (
    <CardWrapper data-testid="impact-stats">
      <StatsContainer>
        <Stat data-testid="attended-actions">
          <span className="stat-icon" role="img" aria-label="raised fist emoji">
            ✊
          </span>
          <strong>{data.attendedActions}</strong>
          <F
            defaultMessage=" {itemCount, plural, =0 {events or actions} one {event or action} other {events and actions}}"
            values={{
              itemCount: data.attendedActions,
            }}
          />
        </Stat>
        <Stat data-testid="supported-organizations">
          <span
            className="stat-icon"
            role="img"
            aria-label="flexed biceps emoji"
          >
            💪
          </span>
          <strong>{data.affiliatedOrgs}</strong>
          <F
            defaultMessage=" {itemCount, plural, =0 {organizations} one {organization} other {organizations}} supported"
            values={{
              itemCount: data.affiliatedOrgs,
            }}
          />
        </Stat>
        <Stat data-testid="hours-volunteered">
          <span
            className="stat-icon"
            role="img"
            aria-label="clock face three o'clock emoji"
          >
            🕒
          </span>
          <strong>{data.hoursVolunteered}</strong>
          <Tooltip title="Includes shifts from all organizations you’ve supported through Mobilize. Calculated based on shifts you’ve left feedback for, and shifts organizers have marked as “completed.”">
            {' '}
            <HasHelpText
              tabIndex={0}
              role="tooltip"
              aria-describedby="vol-impact-tooltip"
            >
              <F
                defaultMessage=" volunteer {itemCount, plural, =0 {hours} one {hour} other {hours}}"
                values={{
                  itemCount: data.hoursVolunteered,
                }}
              />
            </HasHelpText>
          </Tooltip>
        </Stat>
        <SROnly id="vol-impact-tooltip">
          <F defaultMessage='Includes shifts from all organizations you’ve supported through Mobilize. Calculated based on shifts you’ve left feedback for, and shifts organizers have marked as “completed".' />
        </SROnly>
        <Stat data-testid="friends-recruited">
          <span className="stat-icon" role="img" aria-label="waving hand emoji">
            👋
          </span>
          <strong>{referredUserCount}</strong>
          <F
            defaultMessage=" {itemCount, plural, =0 {friends} one {friend} other {friends}} recruited"
            values={{
              itemCount: referredUserCount,
            }}
          />
        </Stat>
      </StatsContainer>
      <MoreActionsContainer>
        <F defaultMessage="Sign up for more events and actions" />
        <UpcomingEventsLinks
          organization={organization}
          trackingLocation={'impact'}
        />
      </MoreActionsContainer>
    </CardWrapper>
  )
}

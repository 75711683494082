import {
  Autocomplete,
  Button,
  CardModal,
  Form,
  Link,
  Tooltip,
  Typography,
} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {
  getOrgCreationUrl,
  getOrganizationDashboardPromotedEventsUrl,
} from 'util/routing'

import LoginModalButton from 'app/login/LoginModalButton'
import {PromotionContext} from 'app/enums'
import {PromotionSuggestionsModalStepper} from 'dashboard/components'
import analytics from 'analytics'
import api from 'data/api'
import {isGroupEvent} from 'util/event'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useAsync} from 'hooks/async'
import {useSnackbar} from 'notistack'
import {useState} from 'react'

const HELP_CENTER_LINK = 'https://mobilize.us/s/single-event-promotion'

const messages = defineMessages({
  submitError: {
    defaultMessage:
      'There was an error promoting this action. Please try again later.',
  },
  submitSuccess: {
    defaultMessage:
      'Successfully promoted action{orgCount, plural, one {. <a>View all events you are promoting</a>} other { for # organizations.}}',
  },
})

function LogInCopy({wasLoggedIn, isGroupEvent, user}) {
  let toReturn
  if (isGroupEvent) {
    if (wasLoggedIn) {
      toReturn = <F defaultMessage={'Log in again to promote this group'} />
    } else {
      toReturn = <F defaultMessage={'Log in to promote this group'} />
    }
  } else {
    if (wasLoggedIn) {
      toReturn = <F defaultMessage={`Log in again to promote this action`} />
    } else {
      toReturn = <F defaultMessage={`Log in to promote this action`} />
    }
  }
  return toReturn
}

const CreateOrganizationWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

function PromoteEventModal({
  eligibleOrganizations,
  onRequestClose,
  event,
  onSubmit,
  isLoading,
  isConfirmation,
}) {
  const [selectedOrgIds, setSelectedOrgIds] = useState(
    eligibleOrganizations.length === 1 ? [eligibleOrganizations[0].id] : []
  )

  return (
    <CardModal isOpen onRequestClose={onRequestClose}>
      <Form onSubmit={() => onSubmit(selectedOrgIds)}>
        <Typography variant="h2">
          {isConfirmation ? (
            <F
              defaultMessage="Promote {eventName}?"
              values={{eventName: event.name}}
            />
          ) : (
            <F
              defaultMessage="Which organizations should promote “{eventName}”?"
              values={{eventName: event.name}}
            />
          )}
        </Typography>
        <Typography variant="body1">
          {isConfirmation ? (
            <F
              defaultMessage={
                'When you promote an event or action, it shows up in your feed. Mobilize shares signup data ' +
                'with your organization and the owning organization.'
              }
            />
          ) : (
            <F
              defaultMessage={
                'If you are an admin for an organization, you can promote events and actions. If you can’t find your ' +
                'organization here, it may be ineligible, or may already be promoting this action. <a>Learn more</a>'
              }
              values={{
                a: (msg) => (
                  <Link target="_blank" to={HELP_CENTER_LINK}>
                    {msg}
                  </Link>
                ),
              }}
            />
          )}
        </Typography>
        {!isConfirmation && (
          <>
            <Autocomplete
              multiple
              label={<F defaultMessage="Choose one or more organizations" />}
              options={eligibleOrganizations.map((org) => ({
                text: org.name,
                key: org.id,
                value: org.id,
              }))}
              value={selectedOrgIds}
              onChange={(_, {value}) => setSelectedOrgIds(value)}
            />
            <CreateOrganizationWrapper>
              <Link
                to={getOrgCreationUrl({
                  utm_source: 'mobilize',
                  utm_medium: 'referral',
                  utm_campaign: 'single-event-promotion-modal',
                })}
                target="_blank"
              >
                <F defaultMessage="Create a new organization…" />
              </Link>
            </CreateOrganizationWrapper>
          </>
        )}
        <CardModal.ActionButtons>
          <Button type="button" secondary onClick={onRequestClose}>
            <F defaultMessage="Cancel" />
          </Button>
          <Button disabled={selectedOrgIds.length === 0} loading={isLoading}>
            <F defaultMessage="Promote" />
          </Button>
        </CardModal.ActionButtons>
      </Form>
    </CardModal>
  )
}

export default function PromoteEventButton({
  event,
  user,
  icon = 'megaphone',
  compact,
  promotingOrg,
  promotionContext = PromotionContext.SINGLE_EVENT_PROMOTION,
  ...buttonProps
}) {
  const intl = useIntl()
  const snackbar = useSnackbar()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showMoreOrgsModal, setShowMoreOrgsModal] = useState(false)
  const [newlyPromotingOrg, setNewlyPromotingOrg] = useState(null)
  const [isLoadingResult, setIsLoadingResult] = useState(false)

  const shouldRender = event.organization.allow_promotion_by_other_orgs
  const shouldFetchOrgs = shouldRender && !!user?.has_organizer_login

  const {loading: isLoadingEligibleOrgs, error, result} = useAsync(async () => {
    if (!shouldFetchOrgs) {
      return {organizations: []}
    }
    if (promotingOrg) {
      return {organizations: [promotingOrg]}
    }
    return api.getEligibleOrgsForPromotion(event.id)
  }, [shouldFetchOrgs, event.id])

  async function onSubmit(selectedOrgIds) {
    if (isLoadingResult) {
      return
    }
    setIsLoadingResult(true)
    try {
      await api.promoteEventById(event.id, selectedOrgIds, promotionContext)
    } catch (_) {
      snackbar.enqueueSnackbar(intl.formatMessage(messages.submitError), {
        variant: 'error',
      })
      setIsLoadingResult(false)
      setIsModalOpen(false)
      return
    }

    snackbar.enqueueSnackbar(
      intl.formatMessage(messages.submitSuccess, {
        orgCount: selectedOrgIds.length,
        a: (msg) => {
          // Only displayed when there is one org selected, so just pick out the full org object
          const org = eligibleOrganizations.find(({id}) =>
            selectedOrgIds.includes(id)
          )
          // Shouldn't happen, but appease the type checker
          if (!org) {
            return null
          }
          return (
            <Link
              style={{
                color: styles.colors.white,
                textDecoration: 'underline',
                marginLeft: styles.space.xs,
              }}
              to={getOrganizationDashboardPromotedEventsUrl(org)}
            >
              {msg}
            </Link>
          )
        },
      }),
      {variant: 'success'}
    )

    // todo(kayvon): update this to track all promoting orgs, right now just send one
    analytics.trackPromotionCreated({
      promotingOrg: eligibleOrganizations.find(({id}) =>
        selectedOrgIds.includes(id)
      ),
      promotedOrg: event.organization,
      context: promotionContext,
    })

    // for now, only show promotion suggestions if promoting from one org
    if (selectedOrgIds.length === 1) {
      const successfullyPromotingOrg = eligibleOrganizations.find(
        (o) => o.id === selectedOrgIds[0]
      )
      if (successfullyPromotingOrg) {
        setNewlyPromotingOrg(successfullyPromotingOrg)
        setShowMoreOrgsModal(true)
      }
    }
    setPromotedOrgIds(new Set([...promotedOrgIds, ...selectedOrgIds]))

    setIsLoadingResult(false)
    setIsModalOpen(false)
  }

  const [promotedOrgIds, setPromotedOrgIds] = useState(new Set())
  const eligibleOrganizations = (result?.organizations || []).filter(
    (org) => !promotedOrgIds.has(org.id)
  )

  if (!shouldRender || error) {
    return null
  }

  if (!shouldFetchOrgs) {
    return (
      <LoginModalButton icon={icon} {...buttonProps}>
        <LogInCopy
          wasLoggedIn={user?.has_organizer_login === false}
          isGroupEvent={isGroupEvent(event)}
          user={user}
        />
      </LoginModalButton>
    )
  }

  const noEligibleOrgs = !isLoadingEligibleOrgs && !eligibleOrganizations.length
  const successfullyPromoted =
    promotingOrg && promotedOrgIds.has(promotingOrg.id)

  const buttonText = (
    <>
      {successfullyPromoted ? (
        <F defaultMessage={'Promoted'} />
      ) : compact ? (
        <F defaultMessage={'Promote'} />
      ) : isGroupEvent(event) ? (
        <F defaultMessage={'Promote this group'} />
      ) : (
        <F defaultMessage={'Promote this action'} />
      )}
    </>
  )

  return (
    <>
      <Button
        disabled={noEligibleOrgs}
        icon={successfullyPromoted ? 'check' : icon}
        loading={isLoadingEligibleOrgs || isLoadingResult}
        {...buttonProps}
        onClick={() => setIsModalOpen(true)}
        success={successfullyPromoted}
      >
        {noEligibleOrgs ? (
          <Tooltip
            title={
              <F
                defaultMessage={
                  'Your organizations are either already promoting, or are not ' +
                  'eligible to promote “{eventName}”. <a>Learn more</a>'
                }
                values={{
                  a: (msg) => (
                    <Link target="_blank" to={HELP_CENTER_LINK}>
                      {msg}
                    </Link>
                  ),
                  eventName: event.name,
                }}
              />
            }
            data-testid="no-orgs-tooltip"
          >
            <div>{buttonText}</div>
          </Tooltip>
        ) : (
          buttonText
        )}
      </Button>
      {showMoreOrgsModal && newlyPromotingOrg && (
        <PromotionSuggestionsModalStepper
          organization={newlyPromotingOrg}
          justPromotedOrg={event.organization}
          onPromote={() => {}}
          onRequestClose={() => setShowMoreOrgsModal(false)}
          showJustPromotedOrg={!newlyPromotingOrg.promotes_org}
          promotionContext={
            PromotionContext.SINGLE_EVENT_PROMOTION_SUGGESTIONS_MODAL
          }
        />
      )}
      {isModalOpen && (
        <PromoteEventModal
          event={event}
          eligibleOrganizations={eligibleOrganizations}
          onRequestClose={() => setIsModalOpen(false)}
          onSubmit={onSubmit}
          isLoading={isLoadingResult}
          isConfirmation={!!promotingOrg}
        />
      )}
    </>
  )
}

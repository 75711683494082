import {
  Button,
  EventMap,
  HideBelowSmallScreen,
  Icon,
  Link,
  MaxWidthContainer,
  Section,
  ShadowContainer,
  Typography,
} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {getCityStateZip, getOneLineAddressForMap} from 'util/geo'
import {
  getEventImageAltText,
  getEventTypeAffinityChecks,
  isGroupEvent,
} from 'util/event'
import styles, {
  getLinkStyleFromProps,
  mediaMinWidthSmall,
} from 'components/styles'
import {useRef, useState} from 'react'

import AccessibilityDetails from './AccessibilityDetails'
import DetailsImage from './DetailsImage'
import {EMPTY_FILTER_PARAMS} from 'app/constants'
import {GroupLinkedEvents} from 'events/components'
import TagDetails from './TagDetails'
import {css} from '@emotion/react'
import {formatEventTimes} from 'util/timeslot'
import {getOrganizationFeedUrl} from 'util/routing'
import {removeHTMLTags} from 'util/html'
import styled from '@emotion/styled/macro'

const COLLAPSE_EVENT_DESCRIPTION_LENGTH = 1500

const messages = defineMessages({
  detailsSectionHeaderPetition: {
    defaultMessage: 'The petition',
    description: 'The details section header on petitions',
  },
  detailsSectionHeaderCommitmentOrDonation: {
    defaultMessage: 'Details',
    description:
      'The details section header on an event details page for a commitment-gathering event or donation campaign',
  },
  detailsSectionHeaderGroup: {
    defaultMessage: 'About this group',
    description:
      'The details section header on an event details page for a group event',
  },
  detailsSectionHeaderDefault: {
    defaultMessage: 'About this event',
    description: 'The default details section header on an event details page',
  },
  mapSectionTitle: {defaultMessage: 'Map'},
})

const DetailsWrapper = styled.div`
  flex: auto;
`

const LocationAndTime = styled.section`
  display: flex;
  flex-direction: column;
  ${mediaMinWidthSmall(`flex-direction: row`)};

  & > div {
    display: flex;
    flex: 1;
    margin-right: ${styles.space.m};
    margin-bottom: ${styles.space.m};
  }
`

const LocationAndTimeInfo = styled.div`
  margin-top: ${styles.space.xs};
  margin-left: ${styles.space.s};
  ${mediaMinWidthSmall('margin: 0;')};
`

const SectionDivider = styled.hr`
  border-top: 1px solid ${styles.colors.neutral200};
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.l};
`

const EventDescription = styled.div`
  ${(props) =>
    props.collapsed &&
    css`
      display: block;
      text-overflow: ellipsis;
      max-height: 500px;
      overflow: hidden;
    `}

  // These are rendered markdown links and strong tags, i.e., they are rendered directly as HTML
  // instead of as React components, so we can't target them more specifically than this
  strong {
    font-weight: ${styles.typography.fontWeightBold};
  }
  a {
    ${getLinkStyleFromProps};
  }
`

const IconWrapper = styled.span`
  margin-top: 2px;
`

export default function Details({event, organization}) {
  const isDescriptionLong =
    !!event.description_html &&
    removeHTMLTags(event.description_html).length >
      COLLAPSE_EVENT_DESCRIPTION_LENGTH
  const [eventDescriptionCollapsed, setEventDescriptionCollapsed] = useState(
    isDescriptionLong
  )
  const mapRef = useRef(null)
  const intl = useIntl()

  const {
    isPetition,
    isCommitmentGathering,
    isRegistrationOnly,
    isDonationCampaign,
    isGroup,
  } = getEventTypeAffinityChecks(event)

  const showLocation = !isRegistrationOnly
  const showAccessibility = !isRegistrationOnly

  const getDetailsSectionHeader = () => {
    if (isPetition) {
      return intl.formatMessage(messages.detailsSectionHeaderPetition)
    } else if (isCommitmentGathering || isDonationCampaign) {
      return intl.formatMessage(
        messages.detailsSectionHeaderCommitmentOrDonation
      )
    } else if (isGroup) {
      return intl.formatMessage(messages.detailsSectionHeaderGroup)
    } else {
      return intl.formatMessage(messages.detailsSectionHeaderDefault)
    }
  }

  const description = (
    <>
      {event.description_html && (
        <ShadowContainer collapsed={eventDescriptionCollapsed}>
          <EventDescription
            collapsed={eventDescriptionCollapsed}
            id="collapseEventDetail-description"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: event.description_html,
              }}
            />
          </EventDescription>
        </ShadowContainer>
      )}
      {isDescriptionLong && (
        <Button
          link
          padding="none"
          onClick={() =>
            setEventDescriptionCollapsed(!eventDescriptionCollapsed)
          }
          aria-expanded={!eventDescriptionCollapsed}
          aria-controls="collapseEventDetail-description"
        >
          {eventDescriptionCollapsed ? (
            <F defaultMessage="Read more" />
          ) : (
            <F defaultMessage="Read less" />
          )}
        </Button>
      )}
    </>
  )

  const {
    dateDescription,
    timeDescription,
    evenMoreTimeslots,
    firstAvailableISO,
  } = formatEventTimes(event, {isTerse: false}) || {}

  const time = event.times.length > 0 && (
    <div>
      <IconWrapper>
        <Icon name="calendar" secondary />
      </IconWrapper>
      <LocationAndTimeInfo>
        <HideBelowSmallScreen>
          <Typography variant="h2">
            <F defaultMessage="Time" />
          </Typography>
        </HideBelowSmallScreen>
        <Typography variant="body1" component="div">
          <time dateTime={firstAvailableISO}>
            <div>
              <strong>
                {dateDescription || <F defaultMessage="No times found" />}
              </strong>
            </div>
            <div>{timeDescription}</div>
          </time>
          <div>{evenMoreTimeslots}</div>
        </Typography>
      </LocationAndTimeInfo>
    </div>
  )

  function handleMapClick() {
    mapRef.current &&
      mapRef.current.scrollIntoView({
        behavior: 'smooth',
      })
  }

  const address = getOneLineAddressForMap(event)
  const cityStateZip = getCityStateZip(event)
  const feedFilteredByState = getOrganizationFeedUrl(organization, {
    ...EMPTY_FILTER_PARAMS,
    state: event.state,
    country: event.country,
  })

  const feedFilteredByIsVirtual = getOrganizationFeedUrl(organization, {
    ...EMPTY_FILTER_PARAMS,
    isVirtual: true,
  })
  const {lat, lon} = event

  const location = (address ||
    event.location_is_private ||
    event.is_virtual) && (
    <div>
      <IconWrapper>
        <Icon name="map-marker-alt" secondary />
      </IconWrapper>
      <LocationAndTimeInfo>
        <HideBelowSmallScreen>
          <Typography variant="h2">
            <F defaultMessage="Location" />
          </Typography>
        </HideBelowSmallScreen>
        <Typography variant="body1" component="div">
          {event.is_virtual ? (
            <>
              <Link secondary to={feedFilteredByIsVirtual}>
                <F defaultMessage="Virtual event" />
              </Link>
              <div>
                <F defaultMessage="Join from anywhere" />
              </div>
              {cityStateZip && (
                <div>
                  <F
                    defaultMessage="Hosted in <a>{cityStateZip}</a>"
                    values={{
                      cityStateZip,
                      a: (msg) => (
                        <Link secondary to={feedFilteredByState}>
                          {msg}
                        </Link>
                      ),
                    }}
                  />
                </div>
              )}
            </>
          ) : event.location_is_private ? (
            <div>
              <strong>
                <F defaultMessage="This event’s address is private." />
              </strong>
              <br />
              <F defaultMessage="Sign up for more details." />
            </div>
          ) : (
            <>
              <div>
                <strong>{event.location_name}</strong>
              </div>
              <div>{event.address_line1}</div>
              <div>{event.address_line2}</div>
              <Link secondary to={feedFilteredByState}>
                {cityStateZip}
              </Link>
              <div>
                <Button link onClick={handleMapClick} padding="none">
                  <F defaultMessage="Map" />
                </Button>
              </div>
            </>
          )}
        </Typography>
      </LocationAndTimeInfo>
    </div>
  )

  return (
    <DetailsWrapper>
      <HideBelowSmallScreen>
        <DetailsImage
          eventType={event.event_type}
          url={event.image_url}
          imageAltText={getEventImageAltText(event)}
        />
      </HideBelowSmallScreen>
      {showLocation && (
        <LocationAndTime>
          {time}
          {location}
        </LocationAndTime>
      )}
      <Section title={getDetailsSectionHeader()} noDivider={!showAccessibility}>
        {description}
      </Section>
      {showAccessibility && (
        <AccessibilityDetails
          notes={event.accessibility_notes}
          status={event.accessibility_status}
          features={event.accessibility_features || []}
        />
      )}
      <TagDetails tags={event.tags} organization={organization} />
      {!event.is_virtual && lat && lon && (
        <Section
          ref={mapRef}
          title={intl.formatMessage(messages.mapSectionTitle)}
          fullBleedWhenSmall
        >
          <EventMap
            centerLat={lat}
            centerLng={lon}
            zoom={13}
            height="325px"
            width="100%"
          />
        </Section>
      )}
      {isGroupEvent(event) && (
        <>
          <MaxWidthContainer>
            <SectionDivider />
          </MaxWidthContainer>
          <GroupLinkedEvents currentEvent={event} organization={organization} />
        </>
      )}
    </DetailsWrapper>
  )
}

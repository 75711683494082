import {centsForDisplayInWholeDollars, centsToDollars} from 'util/currency'

import {F} from 'util/i18n'
import ProgressToGoalDetails from 'events/details/ProgressToGoalDetails'
import {getDonationGoalToDisplayInCents} from 'util/event'

export default function DonationProgress({event, expanded, isCompactFeedItem}) {
  // TODO(julia): donation goal should only consider fundraising_goal_amount_in_cents,
  // but as we enabled donations first by reusing participant_goal, we have some donation
  // events that use the latter. update customDonationGoal to only consider fundraising_goal_amount_in_cents
  // once we have moved all events to use it.
  const customDonationGoal =
    event.fundraising_goal_amount_in_cents || event.participant_goal

  const goalIsEnabled = !event.disable_participant_goal
  const totalDonations = event.fundraiser_total_raised_in_cents || 0
  const totalDonationsInDollars = centsToDollars(totalDonations)
  const totalDonationsString = centsForDisplayInWholeDollars(totalDonations)

  // This will return custom goal if one is set or determine an autoincremented goal
  const goalInCents = getDonationGoalToDisplayInCents(event)
  const goalString = centsForDisplayInWholeDollars(goalInCents)

  const goalMetText = (
    <F
      defaultMessage="We’ve reached our goal of {goal}, but still
                need your support. Donate now to add to our {total} total."
      values={{
        goal: goalString,
        total: totalDonationsString,
      }}
    />
  )

  const currentProgressText = (
    <F defaultMessage="{total} raised" values={{total: totalDonationsString}} />
  )

  const currentGoalText = (
    <F defaultMessage="Help us get to {goal}" values={{goal: goalString}} />
  )

  const helpUsText = (
    <F
      defaultMessage="Help us get to {goal} raised!"
      values={{goal: goalString}}
    />
  )

  return (
    <ProgressToGoalDetails
      currentGoalText={currentGoalText}
      currentProgress={totalDonationsInDollars}
      currentProgressText={currentProgressText}
      expanded={!!expanded}
      goal={centsToDollars(goalInCents)}
      goalIsEnabled={goalIsEnabled}
      goalMetText={goalMetText}
      hasCustomGoal={!!customDonationGoal}
      helpUsText={helpUsText}
      isCompactFeedItem={isCompactFeedItem}
      progressIconName="hand-holding-heart"
    />
  )
}

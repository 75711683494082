import {getEventTypeAffinityChecks, getSupporterGoalToDisplay} from 'util/event'

import {F} from 'util/i18n'
import Icon from 'components/Icon'
import ProgressBar from 'components/ProgressBar'
import {Typography} from 'components'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const SupporterCountWrapper = styled.div`
  font-weight: ${styles.typography.fontWeightBold};
  color: ${({primary}) =>
    primary ? styles.colors.primary200 : styles.colors.black};
  padding-top: ${styles.space.xs};
`

const PromptMessageWrapper = styled.div`
  font-weight: ${({bold}) =>
    bold
      ? styles.typography.fontWeightBold
      : styles.typography.fontWeightNormal};
  color: ${({primary}) =>
    primary ? styles.colors.primary200 : styles.colors.black};
  display: inline-block;
`

const CountWrapper = styled.div`
  padding-bottom: ${styles.space.s};
`

const ProgressWrapper = styled.div`
  padding-bottom: ${({noPadding}) => (noPadding ? '0' : styles.space.m)};
  margin-bottom: ${styles.space.s};
`

export default function SupporterProgress({
  event,
  expanded,
  isCompactFeedItem,
}) {
  const customSupporterGoal = event.participant_goal
  const totalSupporters = event.total_participant_count || 0
  // Show supporter goal if enabled or there are under 100 supporters
  const enableSupporterGoal =
    !event.disable_participant_goal || totalSupporters < 100
  const supporterGoalMet =
    enableSupporterGoal &&
    !!customSupporterGoal &&
    totalSupporters >= customSupporterGoal
  // Show supporter count if there are 100 supporters or a custom goal is set
  const showSupporterCount = totalSupporters >= 100 || !!customSupporterGoal
  const displayGoal = getSupporterGoalToDisplay(event)
  const showExpandedGoalMetMessage =
    expanded && supporterGoalMet && !!customSupporterGoal
  const showGoalWithNoCount = enableSupporterGoal && !showSupporterCount
  const textShouldBeBlue = !expanded
  const showProgressBar =
    !supporterGoalMet && enableSupporterGoal && showSupporterCount
  // Don't show icon if expanded with progress bar to save space and avoid line break
  const showSupportersIcon = !(expanded && showProgressBar)
  const {isGroup} = getEventTypeAffinityChecks(event)

  const ActionPromptMessage = () => {
    if (enableSupporterGoal && supporterGoalMet) {
      return <F defaultMessage="Add your name" />
    } else if (showGoalWithNoCount) {
      if (isGroup) {
        return (
          <F
            defaultMessage="Help us get to {displayGoal, plural, one {# member} other {# members}}!"
            values={{displayGoal}}
          />
        )
      } else {
        return (
          <F
            defaultMessage="Help us get to {displayGoal, plural, one {# supporter} other {# supporters}}!"
            values={{displayGoal}}
          />
        )
      }
    } else if (enableSupporterGoal) {
      // Supporter count will be shown next to this message
      return (
        <F
          defaultMessage="Help us get to {displayGoal, number}!"
          values={{displayGoal}}
        />
      )
    } else {
      return null
    }
  }

  const ExpandedGoalMetMessage = () => (
    <Typography variant="body1" component="span">
      <span role="img" aria-label="chart pointing up">
        📈
      </span>{' '}
      <strong>
        <F defaultMessage="We have lots of momentum!" />
      </strong>
      <div>
        {isGroup ? (
          <F
            defaultMessage="We’ve reached our goal of {customSupporterGoal, number} members, but still
                need your support. Add your name to join {totalSupporters, number} others."
            values={{customSupporterGoal, totalSupporters}}
          />
        ) : (
          <F
            defaultMessage="We’ve reached our goal of {customSupporterGoal, number} signups, but still
                need your support. Add your name to join {totalSupporters, number} others."
            values={{customSupporterGoal, totalSupporters}}
          />
        )}
      </div>
    </Typography>
  )

  const SupporterCountMessage = () => (
    <Typography
      variant={isCompactFeedItem ? 'subtitle1' : 'body2'}
      component="span"
    >
      <SupporterCountWrapper primary={textShouldBeBlue}>
        {showSupportersIcon && (
          <>
            <Icon name="users" noMargin={!expanded} />{' '}
          </>
        )}
        {showSupporterCount && (
          <F
            defaultMessage="{totalSupporters, plural, one {# supporter} other {# supporters}}"
            values={{totalSupporters}}
          />
        )}
        {showSupporterCount && enableSupporterGoal && ' · '}
        <PromptMessageWrapper
          bold={showGoalWithNoCount}
          primary={textShouldBeBlue && showGoalWithNoCount}
        >
          <ActionPromptMessage />
        </PromptMessageWrapper>
      </SupporterCountWrapper>
    </Typography>
  )

  return (
    <>
      {expanded ? (
        <ProgressWrapper noPadding={!showProgressBar}>
          <CountWrapper>
            {showExpandedGoalMetMessage ? (
              <ExpandedGoalMetMessage />
            ) : (
              <SupporterCountMessage />
            )}
          </CountWrapper>
          {showProgressBar && (
            <ProgressBar value={totalSupporters} max={displayGoal} />
          )}
        </ProgressWrapper>
      ) : (
        <SupporterCountMessage />
      )}
    </>
  )
}

import {Button, Message, MessageType, Typography} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {
  formatSingleStartDate,
  getDayForTimeslot,
  mapShiftToTimeslot,
} from 'util/timeslot'
import {
  getActNowButtonProps,
  getPrimaryButtonProps,
  getVirtualActionText,
  shouldShowActLaterButton,
  shouldShowActNowButton,
  useSignupButtonLabel,
} from './util'
import styles, {fontSizeSmall} from 'components/styles'

import {InView} from 'react-intersection-observer'
import VirtualEventTimeslotPicker from './VirtualEventTimeslotPicker'
import {getAvailableTimeslots} from 'events/details/util'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import {useIsAllocatedToExperimentVariant} from 'hooks/experiments'

const ActNowOr = styled.div`
  margin-top: ${styles.space.s};
  margin-bottom: ${styles.space.s};
  color: ${styles.colors.neutral400};
  ${fontSizeSmall};
  text-align: center;
`

const AddAnotherButtonWrapper = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.m};
  text-align: center;
`

// TODO(mime): Can't use 'subtitle1' in Typography yet until we sort out the sizing there.
const SignupAs = styled.div`
  ${fontSizeSmall}
  text-align: center;
  margin-top: ${styles.space.m};
`

const messages = defineMessages({
  signupSlotsText: {
    defaultMessage:
      'Sign up for {shiftsCount, plural, one {# timeslot} other {# timeslots}}',
  },
  signupSlotsTextSingular: {
    defaultMessage: 'Sign up for {time}',
  },
  handPointing: {
    defaultMessage: 'hand pointing up',
  },
})

export default function ActionButtons({
  event,
  expandActLater,
  identityFields,
  isShiftPickerStage,
  onActLaterClick,
  onSignupProceed,
  onShiftsChange,
  onSignupButtonsInViewChange,
  shifts,
  signupCreatedWithinLastFiveSeconds,
  signupInFlightOrCreated,
  isGroupAndAlreadyJoined,
  ...props
}) {
  const intl = useIntl()

  const isNewShiftPickerOn = useIsAllocatedToExperimentVariant(
    'shift_picker_v3',
    'redesign'
  )

  const primaryButtonProps = getPrimaryButtonProps(
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined
  )
  const actNowButtonProps = getActNowButtonProps(
    event,
    signupCreatedWithinLastFiveSeconds,
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined,
    onShiftsChange
  )
  const virtualActionText = getVirtualActionText(
    event,
    signupCreatedWithinLastFiveSeconds,
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined
  )
  const showActNowButton = shouldShowActNowButton(event, expandActLater)

  const signupButtonText = useSignupButtonLabel({
    event,
    isFeed: false,
    signedUp: signupInFlightOrCreated,
    signupIdentityFields: identityFields,
  })

  function renderNonVirtualFlexibleEvents() {
    const availableTimeslots = getAvailableTimeslots(event)
    const isPrefilled = Object.values(identityFields).every((v) => !!v)

    let signupSlotsText = intl.formatMessage(messages.signupSlotsText, {
      shiftsCount: shifts.length,
    })
    if (shifts.length === 1 && shifts[0]) {
      // NB: sometimes the server is sending us down strings instead of numbers :-/
      const timeslot = mapShiftToTimeslot(
        availableTimeslots,
        shifts[0].timeslot_id.toString()
      )
      if (timeslot) {
        const start = formatSingleStartDate(
          moment(timeslot.start).tz(event.timezone),
          event.timezone,
          {isTerse: true}
        )

        signupSlotsText = intl.formatMessage(messages.signupSlotsTextSingular, {
          time: start,
        })
      }
    }

    const hasOnlyOneAvailableTimeslot = availableTimeslots.length === 1

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // Shift picker experiment - not enabled

    // SignupStage 1: We're in the SHIFT_PICKER stage.
    if (isNewShiftPickerOn && isShiftPickerStage) {
      // If there are no shifts, have a disabled button until they select some.
      if (!shifts.length) {
        return (
          <Button fluid disabled>
            <span
              role="img"
              aria-label={intl.formatMessage(messages.handPointing)}
            >
              👆
            </span>{' '}
            <F defaultMessage="Pick timeslots to sign up" />
          </Button>
        )
      }

      // If they have selected shifts, let them proceed.
      if (!isPrefilled) {
        return (
          <Button fluid type="button" onClick={onSignupProceed}>
            {signupSlotsText}…
          </Button>
        )
      }
    }

    // SignupStages 1 + 2: We get here either if:
    //  - we're in the IDENTITY_FIELDS stage (stage 2)
    //  - or, we're in the SHIFT_PICKER stage (stage 1), but only if prefilled.
    if (isNewShiftPickerOn) {
      const signupText = hasOnlyOneAvailableTimeslot
        ? 'Sign up'
        : signupSlotsText
      return (
        <>
          <Button {...primaryButtonProps}>
            {signupInFlightOrCreated ? 'Thanks for signing up!' : signupText}
          </Button>
          {!signupInFlightOrCreated && isPrefilled && isShiftPickerStage && (
            <Typography variant="subtitle1">
              <SignupAs>
                <F
                  defaultMessage="Signing up as {firstName} {lastName}"
                  values={{
                    firstName: identityFields.firstName,
                    lastName: identityFields.lastName,
                  }}
                />
                &nbsp;·&nbsp;
                <Button
                  link
                  padding="none"
                  onClick={onSignupProceed}
                  linkTextColor={styles.colors.neutral400}
                  style={{textDecoration: 'underline'}}
                >
                  Not you?
                </Button>
              </SignupAs>
            </Typography>
          )}
        </>
      )
    }
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    // Live for event details page
    return (
      <Button data-testid="actionbutton-submit" {...primaryButtonProps}>
        {signupButtonText}
      </Button>
    )
  }

  function renderVirtualFlexibleEvents() {
    return (
      <>
        {showActNowButton && (
          <Button {...actNowButtonProps}>{virtualActionText}</Button>
        )}
        {shouldShowActLaterButton(event, expandActLater) && (
          <>
            {showActNowButton && <ActNowOr>OR</ActNowOr>}
            <Button
              secondary={showActNowButton}
              fluid
              onClick={onActLaterClick}
            >
              Pick a time
            </Button>
          </>
        )}
        {expandActLater && (
          <ActLater
            event={event}
            onShiftsChange={onShiftsChange}
            primaryButtonProps={primaryButtonProps}
            shiftErrors={props.shiftErrors}
            shifts={shifts}
            signupInFlightOrCreated={signupInFlightOrCreated}
          />
        )}
      </>
    )
  }

  return (
    <InView onChange={onSignupButtonsInViewChange}>
      {!event.is_virtual_flexible && renderNonVirtualFlexibleEvents()}
      {event.is_virtual_flexible && renderVirtualFlexibleEvents()}
    </InView>
  )
}

// exported just for testing
export function ActLater({
  event,
  onShiftsChange,
  primaryButtonProps,
  shiftErrors,
  shifts,
  signupInFlightOrCreated,
}) {
  const availableTimeslots = getAvailableTimeslots(event)

  return (
    <>
      {availableTimeslots &&
        shifts.map((shift, index) => (
          <VirtualEventTimeslotPicker
            timeslots={availableTimeslots}
            shift={shift}
            key={`${index}-${shift ? shift.timeslot_id : ''}`}
            onChange={(changedShift) => {
              onShiftsChange(
                // This is a rare case where the index is actually the right thing to look
                // at, since the user might be switching the timeslot the picker refers to
                // by switching the date
                shifts.map((s, i) => {
                  if (i === index) {
                    return changedShift
                  }
                  return s
                })
              )
            }}
          />
        ))}
      <AddAnotherButtonWrapper>
        <Button
          link
          padding="none"
          type="button"
          onClick={(e) => {
            e.preventDefault()
            onShiftsChange([...shifts, null])
          }}
        >
          + Add another
        </Button>
      </AddAnotherButtonWrapper>
      {Object.keys(shiftErrors).length > 0 && (
        <Message
          type={MessageType.ERROR}
          header="Signup failed."
          list={Object.keys(shiftErrors).map((timeslotId) => {
            const timeslot = mapShiftToTimeslot(availableTimeslots, timeslotId)
            let day = 'Unknown day'
            if (timeslot) {
              day = getDayForTimeslot(timeslot).format('ll')
            }
            return `${day}: ${shiftErrors[timeslotId]}`
          })}
        />
      )}
      <Button {...primaryButtonProps}>
        {signupInFlightOrCreated ? `Thanks for signing up!` : `Sign up`}
      </Button>
    </>
  )
}

import {MaxWidthContainer, Typography} from 'components'

import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const FilterWrapperOuter = styled.div`
  border-top: 1px solid ${styles.colors.neutral300};
  padding-bottom: ${styles.space.l};
  margin-bottom: ${styles.space.l};
`

export default function FeedHeaderFilterWrapper({
  header,
  description,
  children,
}) {
  if (!header) {
    return children
  }
  return (
    <FilterWrapperOuter>
      {children}
      <MaxWidthContainer marginTop={styles.space.l}>
        <Typography variant="h1">{header}</Typography>
        {description && <Typography variant="body1">{description}</Typography>}
      </MaxWidthContainer>
    </FilterWrapperOuter>
  )
}

import {Button, ScrollContainer, Typography} from 'components'
import {filterFalsy, groupBy} from 'util/common'
import {filterOutAtCapacityTimeslots, isAtCapacity} from 'util/timeslot'
import styles, {fontSizeSmall} from 'components/styles'

import {F} from 'util/i18n'
import {TimezoneToString} from 'app/enums'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'

const Wrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

const TimeslotsByDayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Button} {
    flex: 0 0 calc(50% - ${styles.space.s});
    min-width: calc(50% - ${styles.space.s});
    margin-bottom: ${styles.space.m};
  }

  ${Button}:only-child {
    flex: 1;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

// TODO(mime): Can't use 'subtitle1' in Typography yet until we sort out the sizing there.
const Subtitle = styled.div`
  ${fontSizeSmall}
`

export default function ShiftPicker({
  event,
  onChange,
  shiftErrors,
  shifts,
  showSelectAll,
  selectAllOn,
  setSelectAllOn,
}) {
  // Shift picking for virtual flexible events is handled ActionButtons -> ActLater.
  // We guard for this on the parent component level, but this is just an extra check.
  if (event.is_virtual_flexible) {
    return null
  }

  const selectedTimeslotIds = filterFalsy(
    shifts.map((shift) => shift && shift.timeslot_id)
  )

  function handleClick(timeslot, checked) {
    let timeslotIds = checked
      ? [...selectedTimeslotIds, timeslot.id]
      : selectedTimeslotIds.filter((selectedId) => selectedId !== timeslot.id)
    onChange(timeslotIds.map((timeslot_id) => ({timeslot_id})))
  }

  const availableTimeslots = filterOutAtCapacityTimeslots(event.times)
  const timeslotsByDay = groupBy(availableTimeslots, (timeslot) => {
    return moment(timeslot.start)
      .tz(event.timezone)
      .format(moment.HTML5_FMT.DATE)
  })
  const days = Object.keys(timeslotsByDay).sort()
  const momentToday = moment().tz(event.timezone)
  const today = momentToday.format(moment.HTML5_FMT.DATE)
  const tomorrow = momentToday.add(1, 'd').format(moment.HTML5_FMT.DATE)

  const selectAllText = selectAllOn ? (
    <F
      defaultMessage="Unselect all ({numShifts} times)"
      values={{numShifts: shifts.length}}
    />
  ) : (
    <F
      defaultMessage="Select all ({numAvailable} times)"
      values={{numAvailable: availableTimeslots.length}}
    />
  )

  const handleSelectAllClick = (e) => {
    e && e.preventDefault()
    if (selectAllOn) {
      setSelectAllOn(false)
      onChange([])
    } else {
      const availableTimeslotIds = availableTimeslots.map((timeslot) => ({
        timeslot_id: timeslot.id,
      }))
      if (availableTimeslots) {
        setSelectAllOn(true)
        onChange(availableTimeslotIds)
      }
    }
  }

  function renderTimeslot(timeslot) {
    const timeslotError =
      shiftErrors[String(timeslot.id)] ||
      (isAtCapacity(timeslot) &&
        'This time is at max capacity. Choose another below.') ||
      ''
    return (
      <TimeslotButton
        key={timeslot.id}
        checked={selectedTimeslotIds.includes(timeslot.id)}
        onClick={handleClick}
        timeslot={timeslot}
        timezone={event.timezone}
        timeslotError={timeslotError}
      />
    )
  }

  function renderDay(day) {
    const momentDay = moment(day, moment.HTML5_FMT.DATE)
    const heading =
      day === today
        ? `Today, ${momentDay.format('MMM D')}`
        : day === tomorrow
        ? `Tomorrow, ${momentDay.format('MMM D')}`
        : momentDay.format('dddd, MMM D')
    const maybeHeadingYear =
      momentToday.year() !== momentDay.year() ? `, ${momentDay.year()}` : ''

    return (
      <div key={day}>
        <Typography variant="h3">
          {heading}
          {maybeHeadingYear}
        </Typography>
        <TimeslotsByDayWrapper>
          {timeslotsByDay[day].map((timeslot) => renderTimeslot(timeslot))}
        </TimeslotsByDayWrapper>
      </div>
    )
  }

  return (
    <Wrapper>
      <Flex>
        <Typography variant="h2">Pick timeslots</Typography>
        <Typography variant="subtitle1">
          <Subtitle>{TimezoneToString[event.timezone]}</Subtitle>
        </Typography>
      </Flex>
      <Typography variant="body1">
        <Button link padding="none" onClick={handleSelectAllClick}>
          {selectAllText}
        </Button>
      </Typography>
      <ScrollContainer maxHeight="325px">
        {days.map((day) => renderDay(day))}
      </ScrollContainer>
    </Wrapper>
  )
}

function TimeslotButton({checked, onClick, timeslot, timeslotError, timezone}) {
  function handleClick() {
    onClick(timeslot, !checked)
  }

  const start = moment(timeslot.start).tz(timezone).format('h:mma')
  const end = moment(timeslot.end).tz(timezone).format('h:mma')

  return (
    <Button
      key={timeslot.id}
      type="button"
      iconPosition="left"
      onClick={handleClick}
      checked={checked}
      disabled={!!timeslotError}
      data-track="Timeslot button"
    >
      <span>
        {start}–{end}
      </span>
      {timeslotError && (
        <div>
          <em>{` (${timeslotError})`}</em>
        </div>
      )}
    </Button>
  )
}

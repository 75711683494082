import {Badge, EventTag, Link, Tooltip, Typography} from 'components'
import {Fragment, useContext} from 'react'
import ReferrerBanner, {
  getMaybePrioritizedTimeslot,
  getReferrerBannerInfo,
} from './ReferrerBanner'
import {
  eventTypeDisplayNameForCurrentOrg,
  permissionTierIsAdminOrOrganizer,
} from 'util/event'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {EMPTY_FILTER_PARAMS} from 'app/constants'
import {EventTag as EventTagEnum} from 'app/enums'
import {F} from 'util/i18n'
import {filterOutAtCapacityTimeslots} from 'util/timeslot'
import {getOrganizationDashboardEventEditUrl} from 'util/routing'
import {getOrganizationFeedUrl} from 'util/routing'
import {maybeGetEventSubheading} from 'util/event'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const SubHeader = styled.div`
  margin-bottom: ${styles.space.m};
`

const EventTagWrapper = styled.span`
  margin-right: ${styles.space.s};
`

// This is some hackery to work around the H1 margin-top.
const BadgeWrapper = styled.div`
  margin-bottom: -1.5rem;
  width: fit-content;
`

const DottedUnderline = styled.span`
  border-bottom: 1px dotted;
`

const EditLinkWrapper = styled.span`
  display: inline-block;
  margin-left: ${styles.space.s};
  vertical-align: text-top;
`

function DetailsHeader({event, initialQueryParams}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const {
    referrerName,
    variant,
    useDonationCopy,
    useGroupCopy,
    isGroupInvite,
    groupIsFull,
  } = getReferrerBannerInfo(initialQueryParams, event)
  const maybePrioritizedTimeslot = getMaybePrioritizedTimeslot(
    initialQueryParams,
    event
  )

  const enablePerEventCoOwnership = orgFlagIsActive(
    event.organization,
    'enable_per_event_co_ownership'
  )
  const maybeEventSubheading = maybeGetEventSubheading(
    currentOrganization?.slug,
    event,
    enablePerEventCoOwnership
  )

  const manuallyAddedCoOwners = event.manually_added_co_owning_orgs
  const showTooltip =
    enablePerEventCoOwnership &&
    manuallyAddedCoOwners &&
    manuallyAddedCoOwners.length >= 2

  const textBeforeTooltip = () => {
    const organizedByVolunteer =
      event.created_by_distributed_organizing &&
      !permissionTierIsAdminOrOrganizer(event.creator_permission_tier)
    const verb = organizedByVolunteer ? 'Volunteer hosted for' : 'Hosted by'
    return `${verb} ${event.organization.name}`
  }
  const title = (
    <Typography variant="h1">
      {event.name}
      {event.permissions.can_edit_event && (
        <EditLinkWrapper>
          <Typography variant="subtitle1">
            [
            <Link
              to={getOrganizationDashboardEventEditUrl(
                event.organization,
                event
              )}
            >
              Edit event
            </Link>
            ]
          </Typography>
        </EditLinkWrapper>
      )}
    </Typography>
  )

  const referrerBanner = variant && (
    <ReferrerBanner
      event={event}
      maybeName={referrerName}
      maybeTimeslot={maybePrioritizedTimeslot}
      timezone={event.timezone}
      variant={variant}
      useDonationCopy={useDonationCopy}
      useGroupCopy={useGroupCopy}
      isGroupInvite={isGroupInvite}
      groupIsFull={groupIsFull}
    />
  )

  const featuredTag = event.is_featured && (
    <EventTagWrapper>
      <EventTag tag={EventTagEnum.HIGH_PRIORITY} />
    </EventTagWrapper>
  )
  const isPast = !(event.times && event.times.length)
  const allFull =
    event.times && !filterOutAtCapacityTimeslots(event.times).length
  const eventBadge = isPast ? (
    <BadgeWrapper>
      <Badge>
        <F defaultMessage="Event ended" />
      </Badge>
    </BadgeWrapper>
  ) : allFull ? (
    <BadgeWrapper>
      <Badge error>
        <F defaultMessage="Event at capacity" />
      </Badge>
    </BadgeWrapper>
  ) : null

  const feedFilteredByEventType = getOrganizationFeedUrl(
    currentOrganization || event.organization,
    {
      ...EMPTY_FILTER_PARAMS,
      eventTypes: [event.event_type],
    }
  )

  return (
    <div>
      {referrerBanner}
      {eventBadge}
      {title}
      <SubHeader>
        {featuredTag}
        <Typography variant="body2" component="span">
          <Link secondary to={feedFilteredByEventType}>
            {eventTypeDisplayNameForCurrentOrg(event, currentOrganization)}
          </Link>
          {maybeEventSubheading && !showTooltip && (
            <> · {maybeEventSubheading}</>
          )}
          {showTooltip && manuallyAddedCoOwners && (
            <>
              <span> · {textBeforeTooltip()} and </span>
              <Tooltip
                interactive
                title={
                  <span>
                    {manuallyAddedCoOwners.map((o) => (
                      <Fragment key={o.id}>
                        {o.name}
                        <br />
                      </Fragment>
                    ))}
                  </span>
                }
              >
                <DottedUnderline>
                  {manuallyAddedCoOwners.length} others
                </DottedUnderline>
              </Tooltip>
            </>
          )}
        </Typography>
      </SubHeader>
    </div>
  )
}

export default DetailsHeader
